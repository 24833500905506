/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead
 */
export interface FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead {
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead
     */
    connector?: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadConnectorEnum;
}


/**
 * @export
 */
export const FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadConnectorEnum = {
    Aida: 'Aida',
    Aidin: 'Aidin',
    Ensocare: 'Ensocare',
    EnsocareVa: 'Ensocare VA',
    EpicCareLink: 'EpicCare Link',
    EpicCareLinkAllinaHealth: 'EpicCare Link Allina Health',
    EpicCareLinkBsaHospital: 'EpicCare Link BSA Hospital',
    EpicCareLinkDenverHealth: 'EpicCare Link Denver Health',
    EpicCareLinkHartfordHealthCare: 'EpicCare Link Hartford HealthCare',
    EpicCareLinkHealthPartners: 'EpicCare Link HealthPartners',
    EpicCareLinkHennepinCountyMedicalCenter: 'EpicCare Link Hennepin County Medical Center',
    EpicCareLinkLegacyHealthyPlanet: 'EpicCare Link Legacy Healthy Planet',
    EpicCareLinkMayoClinic: 'EpicCare Link Mayo Clinic',
    EpicCareLinkMercy: 'EpicCare Link Mercy',
    EpicCareLinkPiedmont: 'EpicCare Link Piedmont',
    EpicCareLinkSsmHealth: 'EpicCare Link SSM Health',
    EpicCareLinkUcHealth: 'EpicCare Link UCHealth',
    EpicCareLinkUnityPointHealth: 'EpicCare Link UnityPoint Health',
    EpicCareLinkUtHealthEastTexas: 'EpicCare Link UT Health East Texas',
    EpicCareLinkWellstar: 'EpicCare Link Wellstar',
    Fax: 'Fax',
    ManualUpload: 'Manual Upload',
    WellSkyIntake: 'WellSky Intake',
    WellSkyReferralManagement: 'WellSky Referral Management',
    WellSkyReferralManagementWebReferral: 'WellSky Referral Management Web Referral'
} as const;
export type FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadConnectorEnum = typeof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadConnectorEnum[keyof typeof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadConnectorEnum];


/**
 * Check if a given object implements the FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead interface.
 */
export function instanceOfFindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadFromJSON(json: any): FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead {
    return FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadFromJSONTyped(json, false);
}

export function FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'connector': !exists(json, 'connector') ? undefined : json['connector'],
    };
}

export function FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadToJSON(value?: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'connector': value.connector,
    };
}

