/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InsurancePayor } from './InsurancePayor';
import {
    InsurancePayorFromJSON,
    InsurancePayorFromJSONTyped,
    InsurancePayorToJSON,
} from './InsurancePayor';
import type { ScreenerInsuranceInovalonPayer } from './ScreenerInsuranceInovalonPayer';
import {
    ScreenerInsuranceInovalonPayerFromJSON,
    ScreenerInsuranceInovalonPayerFromJSONTyped,
    ScreenerInsuranceInovalonPayerToJSON,
} from './ScreenerInsuranceInovalonPayer';

/**
 * 
 * @export
 * @interface ScreenerInsurance
 */
export interface ScreenerInsurance {
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    lead_id: string;
    /**
     * 
     * @type {InsurancePayor}
     * @memberof ScreenerInsurance
     */
    payor: InsurancePayor;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    type?: ScreenerInsuranceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    coverage_priority: ScreenerInsuranceCoveragePriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    medicare_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    medicaid_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    plan_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsurance
     */
    group_number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScreenerInsurance
     */
    is_overridden_by_user: boolean;
    /**
     * 
     * @type {ScreenerInsuranceInovalonPayer}
     * @memberof ScreenerInsurance
     */
    inovalon_payer?: ScreenerInsuranceInovalonPayer;
}


/**
 * @export
 */
export const ScreenerInsuranceTypeEnum = {
    Commercial: 'Commercial',
    ManagedMedicare: 'Managed Medicare',
    ManagedMedicaid: 'Managed Medicaid',
    Medicare: 'Medicare',
    Medicaid: 'Medicaid',
    Other: 'Other'
} as const;
export type ScreenerInsuranceTypeEnum = typeof ScreenerInsuranceTypeEnum[keyof typeof ScreenerInsuranceTypeEnum];

/**
 * @export
 */
export const ScreenerInsuranceCoveragePriorityEnum = {
    Primary: 'Primary',
    Secondary: 'Secondary',
    Tertiary: 'Tertiary'
} as const;
export type ScreenerInsuranceCoveragePriorityEnum = typeof ScreenerInsuranceCoveragePriorityEnum[keyof typeof ScreenerInsuranceCoveragePriorityEnum];


/**
 * Check if a given object implements the ScreenerInsurance interface.
 */
export function instanceOfScreenerInsurance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lead_id" in value;
    isInstance = isInstance && "payor" in value;
    isInstance = isInstance && "coverage_priority" in value;
    isInstance = isInstance && "is_overridden_by_user" in value;

    return isInstance;
}

export function ScreenerInsuranceFromJSON(json: any): ScreenerInsurance {
    return ScreenerInsuranceFromJSONTyped(json, false);
}

export function ScreenerInsuranceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScreenerInsurance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead_id': json['lead_id'],
        'payor': InsurancePayorFromJSON(json['payor']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'coverage_priority': json['coverage_priority'],
        'plan_name': !exists(json, 'plan_name') ? undefined : json['plan_name'],
        'medicare_number': !exists(json, 'medicare_number') ? undefined : json['medicare_number'],
        'medicaid_number': !exists(json, 'medicaid_number') ? undefined : json['medicaid_number'],
        'plan_number': !exists(json, 'plan_number') ? undefined : json['plan_number'],
        'group_number': !exists(json, 'group_number') ? undefined : json['group_number'],
        'is_overridden_by_user': json['is_overridden_by_user'],
        'inovalon_payer': !exists(json, 'inovalon_payer') ? undefined : ScreenerInsuranceInovalonPayerFromJSON(json['inovalon_payer']),
    };
}

export function ScreenerInsuranceToJSON(value?: ScreenerInsurance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_id': value.lead_id,
        'payor': InsurancePayorToJSON(value.payor),
        'type': value.type,
        'state': value.state,
        'coverage_priority': value.coverage_priority,
        'plan_name': value.plan_name,
        'medicare_number': value.medicare_number,
        'medicaid_number': value.medicaid_number,
        'plan_number': value.plan_number,
        'group_number': value.group_number,
        'is_overridden_by_user': value.is_overridden_by_user,
        'inovalon_payer': ScreenerInsuranceInovalonPayerToJSON(value.inovalon_payer),
    };
}

