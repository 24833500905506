/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetEligibilityChecks200Response,
  GetExaCarePayerList200Response,
  GetInovalonPayerList200Response,
  GetLeadAssessmentForm200Response,
  GetMedicareAssessmentFormOptions200Response,
  GetMedicareEstimates200Response,
  GetMedicarePtOtMappings200Response,
  MedicareAssessmentForm,
  PerformEligibilityCheck200Response,
  PerformEligibilityCheckRequest,
  ScreenerInsurance,
  UpdateLeadInsurances200Response,
} from '../models/index';
import {
    GetEligibilityChecks200ResponseFromJSON,
    GetEligibilityChecks200ResponseToJSON,
    GetExaCarePayerList200ResponseFromJSON,
    GetExaCarePayerList200ResponseToJSON,
    GetInovalonPayerList200ResponseFromJSON,
    GetInovalonPayerList200ResponseToJSON,
    GetLeadAssessmentForm200ResponseFromJSON,
    GetLeadAssessmentForm200ResponseToJSON,
    GetMedicareAssessmentFormOptions200ResponseFromJSON,
    GetMedicareAssessmentFormOptions200ResponseToJSON,
    GetMedicareEstimates200ResponseFromJSON,
    GetMedicareEstimates200ResponseToJSON,
    GetMedicarePtOtMappings200ResponseFromJSON,
    GetMedicarePtOtMappings200ResponseToJSON,
    MedicareAssessmentFormFromJSON,
    MedicareAssessmentFormToJSON,
    PerformEligibilityCheck200ResponseFromJSON,
    PerformEligibilityCheck200ResponseToJSON,
    PerformEligibilityCheckRequestFromJSON,
    PerformEligibilityCheckRequestToJSON,
    ScreenerInsuranceFromJSON,
    ScreenerInsuranceToJSON,
    UpdateLeadInsurances200ResponseFromJSON,
    UpdateLeadInsurances200ResponseToJSON,
} from '../models/index';

export interface DeleteEligibilityCheckByIdRequest {
    leadId: string;
    checkId: string;
}

export interface GetEligibilityCheckByIdRequest {
    leadId: string;
    checkId: string;
}

export interface GetEligibilityChecksRequest {
    leadId: string;
}

export interface GetLeadAssessmentFormRequest {
    leadId: string;
}

export interface GetMedicareEstimatesRequest {
    leadId: string;
}

export interface PerformEligibilityCheckOperationRequest {
    leadId: string;
    performEligibilityCheckRequest: PerformEligibilityCheckRequest;
}

export interface UpdateLeadAssessmentFormRequest {
    leadId: string;
    medicareAssessmentForm: MedicareAssessmentForm;
}

export interface UpdateLeadInsurancesRequest {
    leadId: string;
    screenerInsurance: Array<ScreenerInsurance>;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Delete eligibility check by ID
     */
    async deleteEligibilityCheckByIdRaw(requestParameters: DeleteEligibilityCheckByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling deleteEligibilityCheckById.');
        }

        if (requestParameters.checkId === null || requestParameters.checkId === undefined) {
            throw new runtime.RequiredError('checkId','Required parameter requestParameters.checkId was null or undefined when calling deleteEligibilityCheckById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/eligibility-checks/{check_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))).replace(`{${"check_id"}}`, encodeURIComponent(String(requestParameters.checkId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete eligibility check by ID
     */
    async deleteEligibilityCheckById(requestParameters: DeleteEligibilityCheckByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEligibilityCheckByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get eligibility check by ID
     */
    async getEligibilityCheckByIdRaw(requestParameters: GetEligibilityCheckByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PerformEligibilityCheck200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getEligibilityCheckById.');
        }

        if (requestParameters.checkId === null || requestParameters.checkId === undefined) {
            throw new runtime.RequiredError('checkId','Required parameter requestParameters.checkId was null or undefined when calling getEligibilityCheckById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/eligibility-checks/{check_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))).replace(`{${"check_id"}}`, encodeURIComponent(String(requestParameters.checkId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformEligibilityCheck200ResponseFromJSON(jsonValue));
    }

    /**
     * Get eligibility check by ID
     */
    async getEligibilityCheckById(requestParameters: GetEligibilityCheckByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PerformEligibilityCheck200Response> {
        const response = await this.getEligibilityCheckByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get eligibility checks for a lead
     */
    async getEligibilityChecksRaw(requestParameters: GetEligibilityChecksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEligibilityChecks200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getEligibilityChecks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/eligibility-checks`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEligibilityChecks200ResponseFromJSON(jsonValue));
    }

    /**
     * Get eligibility checks for a lead
     */
    async getEligibilityChecks(requestParameters: GetEligibilityChecksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEligibilityChecks200Response> {
        const response = await this.getEligibilityChecksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get ExaCare payer list
     */
    async getExaCarePayerListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExaCarePayerList200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payers/exacare`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExaCarePayerList200ResponseFromJSON(jsonValue));
    }

    /**
     * Get ExaCare payer list
     */
    async getExaCarePayerList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExaCarePayerList200Response> {
        const response = await this.getExaCarePayerListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all inovalon payers
     */
    async getInovalonPayerListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInovalonPayerList200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payers/inovalon`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInovalonPayerList200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all inovalon payers
     */
    async getInovalonPayerList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInovalonPayerList200Response> {
        const response = await this.getInovalonPayerListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare Assessment Form for a lead
     */
    async getLeadAssessmentFormRaw(requestParameters: GetLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeadAssessmentForm200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getLeadAssessmentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates/assessment`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeadAssessmentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Assessment Form for a lead
     */
    async getLeadAssessmentForm(requestParameters: GetLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeadAssessmentForm200Response> {
        const response = await this.getLeadAssessmentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare Assessment Form Options
     */
    async getMedicareAssessmentFormOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicareAssessmentFormOptions200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicare/assessment-form-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicareAssessmentFormOptions200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Assessment Form Options
     */
    async getMedicareAssessmentFormOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicareAssessmentFormOptions200Response> {
        const response = await this.getMedicareAssessmentFormOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare Reimbursment estimates for a lead
     */
    async getMedicareEstimatesRaw(requestParameters: GetMedicareEstimatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicareEstimates200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getMedicareEstimates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicareEstimates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Reimbursment estimates for a lead
     */
    async getMedicareEstimates(requestParameters: GetMedicareEstimatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicareEstimates200Response> {
        const response = await this.getMedicareEstimatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare PT/OT diagnoses/case-mix group mappings
     */
    async getMedicarePtOtMappingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicarePtOtMappings200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicare/pt-ot-mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicarePtOtMappings200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare PT/OT diagnoses/case-mix group mappings
     */
    async getMedicarePtOtMappings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicarePtOtMappings200Response> {
        const response = await this.getMedicarePtOtMappingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Performs eligibility check for a lead
     */
    async performEligibilityCheckRaw(requestParameters: PerformEligibilityCheckOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PerformEligibilityCheck200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling performEligibilityCheck.');
        }

        if (requestParameters.performEligibilityCheckRequest === null || requestParameters.performEligibilityCheckRequest === undefined) {
            throw new runtime.RequiredError('performEligibilityCheckRequest','Required parameter requestParameters.performEligibilityCheckRequest was null or undefined when calling performEligibilityCheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-leads/{lead_id}/eligibility-checks`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PerformEligibilityCheckRequestToJSON(requestParameters.performEligibilityCheckRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformEligibilityCheck200ResponseFromJSON(jsonValue));
    }

    /**
     * Performs eligibility check for a lead
     */
    async performEligibilityCheck(requestParameters: PerformEligibilityCheckOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PerformEligibilityCheck200Response> {
        const response = await this.performEligibilityCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Medicare Assessment Form for a lead
     */
    async updateLeadAssessmentFormRaw(requestParameters: UpdateLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeadAssessmentForm200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updateLeadAssessmentForm.');
        }

        if (requestParameters.medicareAssessmentForm === null || requestParameters.medicareAssessmentForm === undefined) {
            throw new runtime.RequiredError('medicareAssessmentForm','Required parameter requestParameters.medicareAssessmentForm was null or undefined when calling updateLeadAssessmentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates/assessment`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicareAssessmentFormToJSON(requestParameters.medicareAssessmentForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeadAssessmentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Update Medicare Assessment Form for a lead
     */
    async updateLeadAssessmentForm(requestParameters: UpdateLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeadAssessmentForm200Response> {
        const response = await this.updateLeadAssessmentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update insurances for a lead
     */
    async updateLeadInsurancesRaw(requestParameters: UpdateLeadInsurancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeadInsurances200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updateLeadInsurances.');
        }

        if (requestParameters.screenerInsurance === null || requestParameters.screenerInsurance === undefined) {
            throw new runtime.RequiredError('screenerInsurance','Required parameter requestParameters.screenerInsurance was null or undefined when calling updateLeadInsurances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-leads/{lead_id}/insurances`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.screenerInsurance.map(ScreenerInsuranceToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeadInsurances200ResponseFromJSON(jsonValue));
    }

    /**
     * Update insurances for a lead
     */
    async updateLeadInsurances(requestParameters: UpdateLeadInsurancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeadInsurances200Response> {
        const response = await this.updateLeadInsurancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
