/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllSourcesResponse } from './FindAllSourcesResponse';
import {
    FindAllSourcesResponseFromJSON,
    FindAllSourcesResponseFromJSONTyped,
    FindAllSourcesResponseToJSON,
} from './FindAllSourcesResponse';

/**
 * 
 * @export
 * @interface FindAllSources200Response
 */
export interface FindAllSources200Response {
    /**
     * 
     * @type {FindAllSourcesResponse}
     * @memberof FindAllSources200Response
     */
    data?: FindAllSourcesResponse;
}

/**
 * Check if a given object implements the FindAllSources200Response interface.
 */
export function instanceOfFindAllSources200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllSources200ResponseFromJSON(json: any): FindAllSources200Response {
    return FindAllSources200ResponseFromJSONTyped(json, false);
}

export function FindAllSources200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllSources200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FindAllSourcesResponseFromJSON(json['data']),
    };
}

export function FindAllSources200ResponseToJSON(value?: FindAllSources200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FindAllSourcesResponseToJSON(value.data),
    };
}

