/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EligibilityRequestServiceTypeCodes } from './EligibilityRequestServiceTypeCodes';
import {
    EligibilityRequestServiceTypeCodesFromJSON,
    EligibilityRequestServiceTypeCodesFromJSONTyped,
    EligibilityRequestServiceTypeCodesToJSON,
} from './EligibilityRequestServiceTypeCodes';
import type { ProviderInfo } from './ProviderInfo';
import {
    ProviderInfoFromJSON,
    ProviderInfoFromJSONTyped,
    ProviderInfoToJSON,
} from './ProviderInfo';
import type { ServiceDates } from './ServiceDates';
import {
    ServiceDatesFromJSON,
    ServiceDatesFromJSONTyped,
    ServiceDatesToJSON,
} from './ServiceDates';
import type { SubscriberInfo } from './SubscriberInfo';
import {
    SubscriberInfoFromJSON,
    SubscriberInfoFromJSONTyped,
    SubscriberInfoToJSON,
} from './SubscriberInfo';

/**
 * 
 * @export
 * @interface EligibilityRequest
 */
export interface EligibilityRequest {
    /**
     * 
     * @type {ProviderInfo}
     * @memberof EligibilityRequest
     */
    provider: ProviderInfo;
    /**
     * 
     * @type {SubscriberInfo}
     * @memberof EligibilityRequest
     */
    subscriber: SubscriberInfo;
    /**
     * 
     * @type {ServiceDates}
     * @memberof EligibilityRequest
     */
    serviceDates: ServiceDates;
    /**
     * 
     * @type {string}
     * @memberof EligibilityRequest
     */
    payerIdentifier: string;
    /**
     * 
     * @type {EligibilityRequestServiceTypeCodes}
     * @memberof EligibilityRequest
     */
    serviceTypeCodes?: EligibilityRequestServiceTypeCodes;
}

/**
 * Check if a given object implements the EligibilityRequest interface.
 */
export function instanceOfEligibilityRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "subscriber" in value;
    isInstance = isInstance && "serviceDates" in value;
    isInstance = isInstance && "payerIdentifier" in value;

    return isInstance;
}

export function EligibilityRequestFromJSON(json: any): EligibilityRequest {
    return EligibilityRequestFromJSONTyped(json, false);
}

export function EligibilityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibilityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': ProviderInfoFromJSON(json['provider']),
        'subscriber': SubscriberInfoFromJSON(json['subscriber']),
        'serviceDates': ServiceDatesFromJSON(json['serviceDates']),
        'payerIdentifier': json['payerIdentifier'],
        'serviceTypeCodes': !exists(json, 'serviceTypeCodes') ? undefined : EligibilityRequestServiceTypeCodesFromJSON(json['serviceTypeCodes']),
    };
}

export function EligibilityRequestToJSON(value?: EligibilityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': ProviderInfoToJSON(value.provider),
        'subscriber': SubscriberInfoToJSON(value.subscriber),
        'serviceDates': ServiceDatesToJSON(value.serviceDates),
        'payerIdentifier': value.payerIdentifier,
        'serviceTypeCodes': EligibilityRequestServiceTypeCodesToJSON(value.serviceTypeCodes),
    };
}

