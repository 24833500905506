/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InsuranceSection } from './InsuranceSection';
import {
    InsuranceSectionFromJSON,
    InsuranceSectionFromJSONTyped,
    InsuranceSectionToJSON,
} from './InsuranceSection';

/**
 * 
 * @export
 * @interface ServiceTypeDetailItem
 */
export interface ServiceTypeDetailItem {
    /**
     * 
     * @type {Array<InsuranceSection>}
     * @memberof ServiceTypeDetailItem
     */
    insurance_sections?: Array<InsuranceSection>;
}

/**
 * Check if a given object implements the ServiceTypeDetailItem interface.
 */
export function instanceOfServiceTypeDetailItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceTypeDetailItemFromJSON(json: any): ServiceTypeDetailItem {
    return ServiceTypeDetailItemFromJSONTyped(json, false);
}

export function ServiceTypeDetailItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceTypeDetailItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insurance_sections': !exists(json, 'insurance_sections') ? undefined : ((json['insurance_sections'] as Array<any>).map(InsuranceSectionFromJSON)),
    };
}

export function ServiceTypeDetailItemToJSON(value?: ServiceTypeDetailItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insurance_sections': value.insurance_sections === undefined ? undefined : ((value.insurance_sections as Array<any>).map(InsuranceSectionToJSON)),
    };
}

