/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BedType } from './BedType';
import {
    BedTypeFromJSON,
    BedTypeFromJSONTyped,
    BedTypeToJSON,
} from './BedType';
import type { Occupancy } from './Occupancy';
import {
    OccupancyFromJSON,
    OccupancyFromJSONTyped,
    OccupancyToJSON,
} from './Occupancy';
import type { ScreenerBedRoom } from './ScreenerBedRoom';
import {
    ScreenerBedRoomFromJSON,
    ScreenerBedRoomFromJSONTyped,
    ScreenerBedRoomToJSON,
} from './ScreenerBedRoom';

/**
 * 
 * @export
 * @interface Bed
 */
export interface Bed {
    /**
     * 
     * @type {string}
     * @memberof Bed
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Bed
     */
    facility_id: string;
    /**
     * 
     * @type {Array<BedType>}
     * @memberof Bed
     */
    bed_types: Array<BedType>;
    /**
     * 
     * @type {string}
     * @memberof Bed
     */
    name: string;
    /**
     * 
     * @type {Array<Occupancy>}
     * @memberof Bed
     */
    active_occupancy_list: Array<Occupancy>;
    /**
     * 
     * @type {ScreenerBedRoom}
     * @memberof Bed
     */
    room: ScreenerBedRoom;
}

/**
 * Check if a given object implements the Bed interface.
 */
export function instanceOfBed(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "bed_types" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "active_occupancy_list" in value;
    isInstance = isInstance && "room" in value;

    return isInstance;
}

export function BedFromJSON(json: any): Bed {
    return BedFromJSONTyped(json, false);
}

export function BedFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'bed_types': ((json['bed_types'] as Array<any>).map(BedTypeFromJSON)),
        'name': json['name'],
        'active_occupancy_list': ((json['active_occupancy_list'] as Array<any>).map(OccupancyFromJSON)),
        'room': ScreenerBedRoomFromJSON(json['room']),
    };
}

export function BedToJSON(value?: Bed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'bed_types': ((value.bed_types as Array<any>).map(BedTypeToJSON)),
        'name': value.name,
        'active_occupancy_list': ((value.active_occupancy_list as Array<any>).map(OccupancyToJSON)),
        'room': ScreenerBedRoomToJSON(value.room),
    };
}

