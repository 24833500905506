/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InovalonPayer
 */
export interface InovalonPayer {
    /**
     * 
     * @type {string}
     * @memberof InovalonPayer
     */
    PayerIdentifierNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InovalonPayer
     */
    PayerName: string;
}

/**
 * Check if a given object implements the InovalonPayer interface.
 */
export function instanceOfInovalonPayer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PayerIdentifierNumber" in value;
    isInstance = isInstance && "PayerName" in value;

    return isInstance;
}

export function InovalonPayerFromJSON(json: any): InovalonPayer {
    return InovalonPayerFromJSONTyped(json, false);
}

export function InovalonPayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): InovalonPayer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PayerIdentifierNumber': json['PayerIdentifierNumber'],
        'PayerName': json['PayerName'],
    };
}

export function InovalonPayerToJSON(value?: InovalonPayer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PayerIdentifierNumber': value.PayerIdentifierNumber,
        'PayerName': value.PayerName,
    };
}

