import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import { GridApi } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { OutOfCommunityChip } from '@/components/OutOfCommunity/OutOfCommunityChip';
import { AvatarS3PhotoKey } from '@/components/ui/Avatar/AvatarS3PhotoKey';
import { ResidentPayload } from '@/hooks/useResidentFacesheetsQuery';
import ResidentCustomTags from '@/pages/ResidentPage/components/ResidentCustomTags';
import { Room } from '@/stores/residentFacesheetsAtom';
import theme from '@/theme';

const StyledStatusCount = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: 'warning' | 'info' | 'success' }>(({ theme, color }) => ({
  width: 24,
  height: 24,
  fontSize: '0.75rem',
  backgroundColor: theme.palette[color].dark
}));

const StyledChip = styled(Chip)({
  '&:hover': {
    cursor: 'pointer'
  }
});

const StyledSpecialStatusChip = styled(StyledChip)(({ theme }) => ({
  backgroundColor: '#E0EFF5',
  '& .MuiChip-label': {
    color: theme.palette.primary.main
  }
}));

const UpcomingMoveinChip = styled(StyledChip)({
  color: '#F38D2F',
  backgroundColor: '#FFE9D4'
});

export enum TaskSummaryValueEnum {
  Upcoming = 'upcoming',
  Complete = 'complete',
  Overdue = 'overdue',
  Due = 'due',
  None = 'none'
}

interface TaskSummary {
  upcoming: number;
  completed: number;
  overdue: number;
  due: number;
}

interface AllTaskSummaryItem {
  residentId: string;
  carePlanItems: TaskSummary;
  medicationTasks: TaskSummary;
  hasNonCommunityMedicationTasks: boolean;
}

export type AllTaskSummaryPayload = AllTaskSummaryItem[];

export const getCohabitantText = (resident: ResidentModel) => {
  const cohabitant = resident.cohabitant;
  let status = '';

  switch (resident.crm_status) {
    case 'PROSPECT':
      status =
        cohabitant?.crm_status === 'PROSPECT'
          ? 'prospectProspect'
          : 'prospectCurrent';
      break;
    default:
      status =
        cohabitant?.crm_status === 'PROSPECT'
          ? 'currentProspect'
          : 'currentCurrent';
      break;
  }
  switch (status) {
    case 'prospectProspect':
      return 'This prospect is cohabiting spouse/companion of prospective resident';
    case 'prospectCurrent':
      return 'This prospect is cohabiting spouse/companion of current resident';
    case 'currentProspect':
      return 'This resident is cohabiting spouse/companion of prospective resident';
    case 'currentCurrent':
      return 'This resident is cohabiting spouse/companion of current resident';
    default:
      return 'This Prospect is Spouse/Companion to';
  }
};

// TODO: Separate the task summary from the resident model.
// @/pages/ResidentOverviewPage should be responsible for composing a new model
// that composes the resident model and a new task summary model.
export interface ResidentModel extends ResidentPayload {}

export class ResidentModel {
  gridApiRef?: React.MutableRefObject<GridApi>;
  room?: Room;
  taskSummary?: AllTaskSummaryItem;
  taskSummaryError?: Error;
  fullName?: string;
  departure_date: string | undefined;
  resident_departure_id: string | undefined;
  absence_reason: string | undefined;
  deletedAt?: string | null;
  date_archived?: string | null;
  isResidentArchived: boolean;

  constructor(resident: ResidentPayload) {
    Object.assign(this, resident);
    this.fullName = this.getFullName();
    this.departure_date = resident?.resident_departures?.[0]?.departure_date;
    this.resident_departure_id = resident?.resident_departures?.[0]?.id;
    this.absence_reason = resident?.resident_departures?.[0]?.reason;
    this.isResidentArchived = resident?.date_archived != null;
    this.id = resident.id ?? `${uuidv4()}`;
  }

  public getServiceStartDate = (): Date | null => {
    if (this.service_start_date) {
      return dayjs(this.service_start_date).toDate();
    }
    return null;
  };

  public isUpcomingMoveIn = (): boolean => {
    if (!this.service_start_date) {
      return false;
    }
    return dayjs(this.service_start_date).isAfter(dayjs());
  };

  public getFormattedServiceStartDate = (): string | null => {
    if (this.service_start_date) {
      return dayjs(this.service_start_date).format('DD MMM YY');
    }
    return null;
  };

  public getTzid = (): string => {
    if (this?.timezone?.name) {
      return this.timezone?.name;
    }
    console.warn(
      "Falling back to browser timezone since ResidentModel doesn't have timezone on it"
    );
    return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  public getFullName = (): string => {
    const { nickname, first_name, last_name } = this;
    if (!first_name) {
      return 'Unknown';
    }
    if (nickname) {
      return `${first_name} (${nickname}) ${last_name}`;
    }
    return `${first_name} ${last_name}`;
  };

  public getInitials = (): string =>
    `${this.first_name?.charAt(0) || ''}${this.last_name?.charAt(0) || ''}`;

  public getRoomName = (): string => this.room?.number ?? '';

  public getResidentAge = (): number | null => {
    if (!this.dob) {
      return null;
    }
    return dayjs().diff(this.dob, 'year');
  };

  public renderResidentCell = (
    showStatus = true,
    includeLastName = false,
    includeRoom = false
  ): JSX.Element => {
    return (
      <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
        <AvatarS3PhotoKey s3PhotoKey={this.s3_photo_key}>
          {this.getInitials()}
        </AvatarS3PhotoKey>
        <Stack>
          {this.first_name} {includeLastName && this.last_name}
          {includeRoom && (
            <Typography variant="body2" color="textSecondary">
              Room {this.getRoomName()}
            </Typography>
          )}
        </Stack>
        {this.cohabitant && !this.cohabitant.crm_resident_info?.lost_deal && (
          <Tooltip
            placement="top"
            title={
              <Box>
                {getCohabitantText(this)}{' '}
                {`${this.cohabitant.first_name} ${this.cohabitant.last_name}`}
              </Box>
            }>
            <IconButton size="small">
              <PeopleIcon sx={{ color: '#9AAEBB' }} />
            </IconButton>
          </Tooltip>
        )}
        {showStatus && (
          <Box
            sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}
            component="span">
            {this.is_dnr && (
              <StyledSpecialStatusChip size="small" label="DNR" />
            )}
            {this.is_hospice && (
              <StyledSpecialStatusChip size="small" label="Hospice" />
            )}
            {this.out_of_community && (
              <OutOfCommunityChip label="Out" size="small" />
            )}
            {this.isUpcomingMoveIn() && (
              <UpcomingMoveinChip
                size="small"
                label={`Move-In: ${this.getFormattedServiceStartDate()}`}
              />
            )}
            {this.resident_tags && (
              <Stack
                direction="row"
                gap="8px"
                onClick={(e) => e.stopPropagation()}>
                <ResidentCustomTags
                  displayedTags={1}
                  residentTags={this.resident_tags}
                />
              </Stack>
            )}
          </Box>
        )}
      </Box>
    );
  };

  public renderTasksCell = (
    type: keyof Pick<AllTaskSummaryItem, 'carePlanItems' | 'medicationTasks'>
  ): JSX.Element => {
    const renderOutOfCommunityStatusOveride = this.out_of_community
      ? {
          backgroundColor: '#f2f2f2',
          color: '#9AAEBB',
          '& .MuiAvatar-circular': {
            backgroundColor: '#C6CACC !important',
            color: '#FCFEFF !important'
          }
        }
      : {};

    if (this.taskSummaryError instanceof Error) {
      return <span>Error loading</span>;
    }

    if (!this.taskSummary) {
      return <CircularProgress size="1.25rem" />;
    }

    const { completed, overdue, upcoming, due } = this.getTaskSummary(type);

    if (overdue > 0) {
      return (
        <StyledChip
          id={`resident-${this.id}-task-cell-${type}-overdue`}
          icon={
            <StyledStatusCount color="warning">{overdue}</StyledStatusCount>
          }
          sx={renderOutOfCommunityStatusOveride}
          color="warning"
          label={
            type === 'carePlanItems'
              ? 'Tasks not Completed'
              : 'Medications not Provided'
          }
        />
      );
    }

    if (upcoming + due > 0) {
      return (
        <StyledChip
          id={`resident-${this.id}-task-cell-${type}-upcoming`}
          color="info"
          icon={
            <StyledStatusCount color="info">{upcoming + due}</StyledStatusCount>
          }
          sx={renderOutOfCommunityStatusOveride}
          label={
            type === 'carePlanItems' ? 'Upcoming Tasks' : 'Medications to Give'
          }
        />
      );
    }

    if (completed > 0) {
      return (
        <StyledChip
          id={`resident-${this.id}-task-cell-${type}-success`}
          color="success"
          sx={renderOutOfCommunityStatusOveride}
          label={
            type === 'carePlanItems'
              ? 'All Tasks Completed'
              : 'All Medications Given'
          }
        />
      );
    }

    return this.taskSummary.hasNonCommunityMedicationTasks ? (
      <StyledChip
        id={`resident-${this.id}-task-cell-${type}-nothing-to-do`}
        label="Self/ External"
        sx={{
          color: '#667A86',
          backgroundColor: '#F6F2EF',
          ...renderOutOfCommunityStatusOveride
        }}
      />
    ) : (
      <StyledChip
        id={`resident-${this.id}-task-cell-${type}-nothing-to-do`}
        label={type === 'carePlanItems' ? 'No Tasks' : 'No Medications'}
        sx={{
          color: '#667A86',
          background:
            'linear-gradient(0deg, #FBFCFF, #FBFCFF) linear-gradient(0deg, rgba(0, 102, 138, 0.05), rgba(0, 102, 138, 0.05))',
          ...renderOutOfCommunityStatusOveride
        }}
      />
    );
  };

  public getTasksValue = (
    type: keyof Pick<AllTaskSummaryItem, 'carePlanItems' | 'medicationTasks'>
  ): string => {
    const { completed, overdue, upcoming, due } = this.getTaskSummary(type);

    if (overdue > 0) {
      return TaskSummaryValueEnum.Overdue;
    }

    if (upcoming > 0) {
      return TaskSummaryValueEnum.Upcoming;
    }

    if (completed > 0) {
      return TaskSummaryValueEnum.Complete;
    }

    if (due > 0) {
      return TaskSummaryValueEnum.Due;
    }

    return TaskSummaryValueEnum.None;
  };

  private getTaskSummary = (
    type: keyof Pick<AllTaskSummaryItem, 'carePlanItems' | 'medicationTasks'>
  ) => {
    return this.taskSummary
      ? this.taskSummary[type]
      : { completed: 0, overdue: 0, upcoming: 0, due: 0 };
  };

  public renderDetailsCell = (showNoEmailError = false): JSX.Element => (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      key={`resident-${this.id}`}>
      <Box>
        <AvatarS3PhotoKey
          sx={{ height: 32, width: 32, fontSize: '1rem' }}
          s3PhotoKey={this.s3_photo_key}>
          {this.getInitials()}
        </AvatarS3PhotoKey>
      </Box>
      <Box display="column">
        <Box display="flex" alignItems="center" gap="8px">
          {this.fullName}
          <Typography variant="body2">Resident</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color={
              showNoEmailError && !this.email
                ? theme.palette.error.main
                : theme.palette.primary.main
            }>
            {showNoEmailError && !this.email ? 'No Email' : this.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
