/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeadInfoRequest
 */
export interface CreateLeadInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    s3_photo_key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    marital_status?: CreateLeadInfoRequestMaritalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    primary_language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    contact_method_preference?: CreateLeadInfoRequestContactMethodPreferenceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadInfoRequest
     */
    current_address?: string;
}


/**
 * @export
 */
export const CreateLeadInfoRequestMaritalStatusEnum = {
    Married: 'married',
    Single: 'single',
    Widowed: 'widowed',
    Divorced: 'divorced',
    SignificantOther: 'significant_other',
    Companion: 'companion'
} as const;
export type CreateLeadInfoRequestMaritalStatusEnum = typeof CreateLeadInfoRequestMaritalStatusEnum[keyof typeof CreateLeadInfoRequestMaritalStatusEnum];

/**
 * @export
 */
export const CreateLeadInfoRequestContactMethodPreferenceEnum = {
    Email: 'Email',
    Text: 'Text',
    Call: 'Call',
    Other: 'Other'
} as const;
export type CreateLeadInfoRequestContactMethodPreferenceEnum = typeof CreateLeadInfoRequestContactMethodPreferenceEnum[keyof typeof CreateLeadInfoRequestContactMethodPreferenceEnum];


/**
 * Check if a given object implements the CreateLeadInfoRequest interface.
 */
export function instanceOfCreateLeadInfoRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateLeadInfoRequestFromJSON(json: any): CreateLeadInfoRequest {
    return CreateLeadInfoRequestFromJSONTyped(json, false);
}

export function CreateLeadInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeadInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        's3_photo_key': !exists(json, 's3_photo_key') ? undefined : json['s3_photo_key'],
        'photo_url': !exists(json, 'photo_url') ? undefined : json['photo_url'],
        'marital_status': !exists(json, 'marital_status') ? undefined : json['marital_status'],
        'primary_language': !exists(json, 'primary_language') ? undefined : json['primary_language'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'contact_method_preference': !exists(json, 'contact_method_preference') ? undefined : json['contact_method_preference'],
        'current_address': !exists(json, 'current_address') ? undefined : json['current_address'],
    };
}

export function CreateLeadInfoRequestToJSON(value?: CreateLeadInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        's3_photo_key': value.s3_photo_key,
        'photo_url': value.photo_url,
        'marital_status': value.marital_status,
        'primary_language': value.primary_language,
        'phone_number': value.phone_number,
        'email': value.email,
        'contact_method_preference': value.contact_method_preference,
        'current_address': value.current_address,
    };
}

