import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Grid,
  Stack,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import dayjs from 'dayjs';

import { ControlledDatePicker } from '@/components/ControlledDatePicker';
import { ControlledSelectPrimitive } from '@/components/ControlledSelectPrimitive';
import { ControlledSingleSelect } from '@/components/ControlledSingleSelect';
import { ControlledTextField } from '@/components/ControlledTextField';
import StepFormLayout from '@/components/Incidents/StepFormLayout';
import RequiredFormFieldLabel from '@/components/RequiredFormFieldLabel';
import { AddIncident } from '@/hooks/useCreateIncident';
import { useFacilitiesQuery } from '@/hooks/useFacilitiesQuery';
import { useLookup } from '@/hooks/useLookup';
import {
  useFindAllResidentsQuery,
  useFindOneResidentQuery
} from '@/hooks/useResidentQuery';

import { locationOptions } from './helpers/Options';

const StyledTime = styled(TextField)({
  'label[data-shrink="false"]': {
    backgroundColor: 'white',
    width: '200px',
    zIndex: 9
  }
});

export const SectionSeparator = styled(Typography)({
  color: '#667A86',
  fontSize: '16px',
  marginBottom: '10px',
  span: {
    marginLeft: '5px',
    color: '#0A1E28'
  }
});

interface AddIncidenntDetailsStepProps {
  formMethods: UseFormReturn<AddIncident, any>;
  facilityId: string | null;
  setNextButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SectionHeader = styled(Typography)({
  color: '#0A1E28',
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '0px !important',
  display: 'flex',
  alignItems: 'center',
  svg: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginRight: '10px'
  }
});

const AddIncidentDetailsStep: React.FC<AddIncidenntDetailsStepProps> = ({
  facilityId,
  formMethods,
  setNextButton
}) => {
  const { control, watch, setValue } = formMethods;
  const { data: residents } = useFindAllResidentsQuery(facilityId);
  const { data: incidentTypes, isLoading: isLoadingIncidentTypes } =
    useLookup('INCIDENT_TYPE');
  const incidentTypeOtherId = incidentTypes?.find(
    ({ name }: { name: string }) => name.toLowerCase() === 'other'
  )?.value;

  const incidentLocationOtherId = locationOptions?.find(
    ({ key }: { key: string }) => key.toLowerCase() === 'other'
  )?.value;

  const [time, setTime] = useState(
    dayjs()
      .hour(new Date().getHours())
      .minute(new Date().getMinutes())
      .format('HH:mm')
  );
  const residentId = useParams().resident_id!;

  const { data: resident, isFetching: isFetchingResident } =
    useFindOneResidentQuery(
      { residentId },
      {
        enabled: !!residentId
      }
    );

  const facility = useFacilitiesQuery().findOne(resident?.facility_id, {
    enabled: !!resident
  }).data;

  const validatedFields: Array<number | null | string | undefined> = watch([
    'typeId',
    'residentId',
    'location'
  ]);

  useEffect(() => {
    const dateWithoutTime = formMethods.getValues('occurenceDate') as string;
    const minutes = parseInt(time.slice(-2));
    const dateWithTime = dayjs(dateWithoutTime, time).minute(minutes);
    formMethods.setValue('occurenceDate', dateWithTime.format());
  }, [time]);

  const incidentTypesMap = useMemo(() => {
    return incidentTypes?.map((type: { name: string; value: string }) => ({
      name: type.name,
      value: type.value
    }));
  }, [incidentTypes]);

  const isMediaQueryDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  const isIncidentOther = watch('typeId') == incidentTypeOtherId;
  const isOtherIncidentTypeMissingValue =
    watch('incidentTypeOther') == null ||
    watch('incidentTypeOther')?.length === 0;

  const isLocationOther = watch('location') == incidentLocationOtherId;
  const isOtherIncidentLocationMissingValue =
    watch('locationOther') == null || watch('locationOther')?.length === 0;

  const occurenceDate = watch('occurenceDate');

  const validateFields = () => {
    const isFieldsMissingValidation =
      validatedFields.some((field) => {
        return field === null || field === '';
      }) || !dayjs(occurenceDate).isValid();
    if (isFieldsMissingValidation) {
      return false;
    } else if (isIncidentOther && isOtherIncidentTypeMissingValue) {
      return false;
    } else if (isLocationOther && isOtherIncidentLocationMissingValue) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isIncidentOther) {
      setValue('incidentTypeOther', '');
    }

    if (!isLocationOther) {
      setValue('locationOther', '');
    }

    const isNextButtonDisabled =
      !validateFields() || !dayjs(occurenceDate).isValid();

    setNextButton(isNextButtonDisabled);
  }, [
    JSON.stringify(validatedFields),
    isOtherIncidentTypeMissingValue,
    isOtherIncidentLocationMissingValue,
    occurenceDate
  ]);

  useEffect(() => {
    if (residentId === undefined) {
      return;
    }
    setValue('residentId', parseInt(residentId));
  }, [residentId]);

  return (
    <StepFormLayout title="Details">
      <Stack mb={2}>
        <SectionHeader>
          <LanguageIcon /> Resident Information
        </SectionHeader>
        <Stack>
          {!resident && !isFetchingResident ? (
            <ControlledSingleSelect
              control={control}
              name="residentId"
              label="Resident's name"
              options={residents ?? []}
              optionLabelKey="fullName"
              optionIdKey="id"
            />
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <SectionSeparator>
                  Resident: <span>{resident?.getFullName()}</span>
                </SectionSeparator>
                <SectionSeparator>
                  Birth Date:{' '}
                  <span className="no-upscope">
                    {dayjs(resident?.dob).format('DD/MM/YYYY')}
                  </span>
                </SectionSeparator>
              </Grid>
              <Grid item xs={6}>
                <SectionSeparator>
                  Community Name: <span>{facility?.name}</span>
                </SectionSeparator>
                <SectionSeparator>
                  Room Number: <span>{resident?.room_id}</span>
                </SectionSeparator>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12}>
          <SectionHeader>
            <DescriptionIcon /> Incident Details
          </SectionHeader>
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledSelectPrimitive
            isLoading={isLoadingIncidentTypes}
            name="typeId"
            control={control}
            hideNA
            optionLabelKey="name"
            optionIdKey="value"
            label={<RequiredFormFieldLabel label="Incident Type" />}
            options={incidentTypesMap ?? []}
          />
        </Grid>
        <Grid
          item
          mb={2}
          xs={12}
          sx={{ display: !isIncidentOther ? 'none' : 'block' }}>
          <ControlledTextField
            disabled={!isIncidentOther}
            error={isIncidentOther && isOtherIncidentTypeMissingValue}
            helperText="If other, please specify the incident type"
            control={formMethods.control}
            label="If other, please detail"
            fullWidth
            name="incidentTypeOther"
          />
        </Grid>
        <Grid item mb={2} xs={12} md={5.9} mr={!isMediaQueryDownMd ? 2 : 0}>
          <ControlledDatePicker
            control={control}
            name="occurenceDate"
            label="Occurrence Date"
          />
        </Grid>
        <Grid item mb={2} xs={12} md={5.9}>
          <StyledTime
            fullWidth
            id="time"
            label="Occurrence Time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            type="time"
            inputProps={{
              step: 60
            }}
          />
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledSelectPrimitive
            options={locationOptions}
            optionLabelKey="key"
            optionIdKey="value"
            hideNA
            name="location"
            label={<RequiredFormFieldLabel label="Location" />}
            control={control}
          />
        </Grid>
        <Grid
          item
          mb={2}
          xs={12}
          sx={{ display: !isLocationOther ? 'none' : 'block' }}>
          <ControlledTextField
            name="locationOther"
            control={control}
            helperText="If other, please specify the location where the incident happened"
            label="If other, please detail"
            disabled={!isLocationOther}
            error={isLocationOther && isOtherIncidentLocationMissingValue}
          />
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledTextField
            name="residentDescription"
            multiline
            rows={4}
            control={control}
            label="Resident's description of the incident"
          />
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledTextField
            name="witnessDescription"
            multiline
            rows={4}
            control={control}
            label="Witness' description of the incident"
          />
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledTextField
            name="nursesDescription"
            multiline
            rows={4}
            control={control}
            label="Nurse's description of the incident"
          />
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledTextField
            name="notesOnPhysicalEnvironment"
            multiline
            rows={4}
            control={control}
            label="Notes on physical environment leading to incident"
          />
        </Grid>
        <Grid item mb={2} xs={12}>
          <ControlledTextField
            name="notesOnPhysiologicalCircunstances"
            multiline
            rows={4}
            control={control}
            label="Notes on physiological circumstances leading to incident"
          />
        </Grid>
      </Grid>
    </StepFormLayout>
  );
};

export default AddIncidentDetailsStep;
