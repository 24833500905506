import { useLocation } from 'react-router-dom';

import { RoutePath } from '@/routes/RoutePath';

const useIsAllFacilitiesLeadsSelected = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isMatch =
    location.pathname === RoutePath.CRMScreenerAllLeads &&
    searchParams.get('all_facilities') === 'true';

  return isMatch;
};

export default useIsAllFacilitiesLeadsSelected;
