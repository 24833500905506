/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllScreenerTags200ResponseDataInner } from './FindAllScreenerTags200ResponseDataInner';
import {
    FindAllScreenerTags200ResponseDataInnerFromJSON,
    FindAllScreenerTags200ResponseDataInnerFromJSONTyped,
    FindAllScreenerTags200ResponseDataInnerToJSON,
} from './FindAllScreenerTags200ResponseDataInner';

/**
 * 
 * @export
 * @interface BulkUpdateScreenerTagsRequest
 */
export interface BulkUpdateScreenerTagsRequest {
    /**
     * 
     * @type {Array<FindAllScreenerTags200ResponseDataInner>}
     * @memberof BulkUpdateScreenerTagsRequest
     */
    tags: Array<FindAllScreenerTags200ResponseDataInner>;
}

/**
 * Check if a given object implements the BulkUpdateScreenerTagsRequest interface.
 */
export function instanceOfBulkUpdateScreenerTagsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function BulkUpdateScreenerTagsRequestFromJSON(json: any): BulkUpdateScreenerTagsRequest {
    return BulkUpdateScreenerTagsRequestFromJSONTyped(json, false);
}

export function BulkUpdateScreenerTagsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateScreenerTagsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(FindAllScreenerTags200ResponseDataInnerFromJSON)),
    };
}

export function BulkUpdateScreenerTagsRequestToJSON(value?: BulkUpdateScreenerTagsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(FindAllScreenerTags200ResponseDataInnerToJSON)),
    };
}

