/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScreenerInsuranceInovalonPayer
 */
export interface ScreenerInsuranceInovalonPayer {
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsuranceInovalonPayer
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerInsuranceInovalonPayer
     */
    payer_identification_number: string;
}

/**
 * Check if a given object implements the ScreenerInsuranceInovalonPayer interface.
 */
export function instanceOfScreenerInsuranceInovalonPayer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payer_name" in value;
    isInstance = isInstance && "payer_identification_number" in value;

    return isInstance;
}

export function ScreenerInsuranceInovalonPayerFromJSON(json: any): ScreenerInsuranceInovalonPayer {
    return ScreenerInsuranceInovalonPayerFromJSONTyped(json, false);
}

export function ScreenerInsuranceInovalonPayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScreenerInsuranceInovalonPayer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payer_name': json['payer_name'],
        'payer_identification_number': json['payer_identification_number'],
    };
}

export function ScreenerInsuranceInovalonPayerToJSON(value?: ScreenerInsuranceInovalonPayer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payer_name': value.payer_name,
        'payer_identification_number': value.payer_identification_number,
    };
}

