/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BedOccupancyStatsResponseGenderDistribution } from './BedOccupancyStatsResponseGenderDistribution';
import {
    BedOccupancyStatsResponseGenderDistributionFromJSON,
    BedOccupancyStatsResponseGenderDistributionFromJSONTyped,
    BedOccupancyStatsResponseGenderDistributionToJSON,
} from './BedOccupancyStatsResponseGenderDistribution';
import type { BedOccupancyStatsResponseLeadsCountByBedTypeInner } from './BedOccupancyStatsResponseLeadsCountByBedTypeInner';
import {
    BedOccupancyStatsResponseLeadsCountByBedTypeInnerFromJSON,
    BedOccupancyStatsResponseLeadsCountByBedTypeInnerFromJSONTyped,
    BedOccupancyStatsResponseLeadsCountByBedTypeInnerToJSON,
} from './BedOccupancyStatsResponseLeadsCountByBedTypeInner';

/**
 * 
 * @export
 * @interface BedOccupancyStatsResponse
 */
export interface BedOccupancyStatsResponse {
    /**
     * 
     * @type {string}
     * @memberof BedOccupancyStatsResponse
     */
    facility_id: string;
    /**
     * Total number of moved-in screener leads
     * @type {number}
     * @memberof BedOccupancyStatsResponse
     */
    moved_in_leads_count: number;
    /**
     * 
     * @type {Array<BedOccupancyStatsResponseLeadsCountByBedTypeInner>}
     * @memberof BedOccupancyStatsResponse
     */
    leads_count_by_bed_type: Array<BedOccupancyStatsResponseLeadsCountByBedTypeInner>;
    /**
     * 
     * @type {BedOccupancyStatsResponseGenderDistribution}
     * @memberof BedOccupancyStatsResponse
     */
    gender_distribution: BedOccupancyStatsResponseGenderDistribution;
}

/**
 * Check if a given object implements the BedOccupancyStatsResponse interface.
 */
export function instanceOfBedOccupancyStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "moved_in_leads_count" in value;
    isInstance = isInstance && "leads_count_by_bed_type" in value;
    isInstance = isInstance && "gender_distribution" in value;

    return isInstance;
}

export function BedOccupancyStatsResponseFromJSON(json: any): BedOccupancyStatsResponse {
    return BedOccupancyStatsResponseFromJSONTyped(json, false);
}

export function BedOccupancyStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BedOccupancyStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'moved_in_leads_count': json['moved_in_leads_count'],
        'leads_count_by_bed_type': ((json['leads_count_by_bed_type'] as Array<any>).map(BedOccupancyStatsResponseLeadsCountByBedTypeInnerFromJSON)),
        'gender_distribution': BedOccupancyStatsResponseGenderDistributionFromJSON(json['gender_distribution']),
    };
}

export function BedOccupancyStatsResponseToJSON(value?: BedOccupancyStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'moved_in_leads_count': value.moved_in_leads_count,
        'leads_count_by_bed_type': ((value.leads_count_by_bed_type as Array<any>).map(BedOccupancyStatsResponseLeadsCountByBedTypeInnerToJSON)),
        'gender_distribution': BedOccupancyStatsResponseGenderDistributionToJSON(value.gender_distribution),
    };
}

