import React from 'react';
import { Check, Comment, Medication } from '@mui/icons-material';
import MedicationIcon from '@mui/icons-material/Medication';
import {
  Avatar,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { GridApi } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { RRule } from 'rrule';

import {
  FrequencyTimeFormData,
  generateScheduleFormDataDefaultValues
} from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  customFrequencyOption,
  fiveTimesADayFrequencyOption,
  fourTimesADayFrequencyOption,
  medicationDurationOptions,
  threeTimesADayFrequencyOption,
  twiceADayFrequencyOption
} from '@/components/FrequencyTimeForm/options';
import { prescriptionTypeOptions } from '@/components/MedicationDrawer/MedicationDrawer';
import { MedicationIndicators } from '@/components/MedicationIndicators/MedicationIndicators';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { BLOOD_PRESSURE_VITAL_ID, DEA_SCHEDULE_CODES } from '@/constants';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { MedicationTaskInstancePayload } from '@/hooks/useMedicationTaskInstancesQuery';
import {
  MedicationTaskPayload,
  MedicationTaskSchedulePayload,
  MedicationTaskVitalRequirementsPayload
} from '@/hooks/useResidentMedications';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import {
  medicationVitalsDefaultFormValues,
  ResidentMedicationForm
} from '@/stores/residentMedicationsAtom';
import { calculateFdbTotalDosage } from '@/utils/calculateTotalDosage';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';
import { getExecutionWindowTimeValue } from '@/utils/getExecutionWindowTimeValue';
import { transformPatternedRecurrenceToHumanText } from '@/utils/patternedRecurrenceFormatters';
import { transformRegimensFormToJsonBody } from '@/utils/transformRegimensFormToJsonBody';
import { transformRRuleStringToHumanText } from '@/utils/transformRRuleStringToHumanText';
import { transformScheduleFormToJsonBody } from '@/utils/transformScheduleFormToJsonBody';
import { transformSchedulePayloadToFrequencyTimeFormData } from '@/utils/transformSchedulePayloadToFrequencyTimeFormData';

import { MedicationRegimenModel } from './MedicationRegimenModel';
import { PrnLimitationsModel } from './PrnLimitationsModel';
import { ResidentModel } from './ResidentModel';
import { TaskExtensionModel } from './TaskExtensionModel';

dayjs.extend(isToday);

export class MedicationTaskModel extends MedicationTaskPayload {
  public readonly gridApiRef?: React.MutableRefObject<GridApi>;
  public readonly lastCompletedMedTaskInstance?: MedicationTaskInstancePayload;
  public readonly isActive: boolean;
  public readonly isPrn: boolean;
  public readonly image?: string;
  public readonly regimens: MedicationRegimenModel[] = [];
  public readonly prnLimitationsModel?: PrnLimitationsModel;
  public readonly resident?: ResidentModel;

  constructor(
    medication: MedicationTaskPayload,
    isActive: boolean,
    gridApiRef?: React.MutableRefObject<GridApi>,
    lastCompletedMedTaskInstance?: MedicationTaskInstancePayload,
    image?: string,
    resident?: ResidentModel
  ) {
    super(medication);
    this.gridApiRef = gridApiRef;
    this.lastCompletedMedTaskInstance = lastCompletedMedTaskInstance;
    this.isActive = isActive;
    this.isPrn = this.constructIsPrn();
    this.image = image;
    this.regimens = MedicationRegimenModel.constructFromSchedules({
      nonCommunityDose: this.non_community_dose,
      fdbDispensableDrug: this.fdb_dispensable_drug,
      schedules: this.medication_task_schedules ?? []
    });
    this.prnLimitationsModel = this.isPrn
      ? new PrnLimitationsModel(medication)
      : undefined;
    this.resident = resident;
  }

  public renderMedicationCell = (): React.ReactNode => {
    return (
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <div {...bindHover(popupState)}>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                <Typography
                  component="span"
                  color="secondary"
                  fontSize="0.9375rem"
                  fontWeight="500">
                  {this.fdb_dispensable_drug?.DrugNameDesc}
                </Typography>
                <MedicationIndicators model={this} abbreviated showTooltip />
              </Stack>
              <Typography color="#364955" fontSize="0.875rem">
                {this.fdb_dispensable_drug?.GenericDispensableDrugDesc ||
                  this.fdb_dispensable_drug?.DispensableDrugDesc ||
                  ''}
              </Typography>
            </div>
            <HoverPopover
              {...bindPopover(popupState)}
              elevation={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <ListItem>
                {this.image && (
                  <ListItemAvatar>
                    <Avatar variant="rounded" src={this.image}>
                      <Medication fontSize="large" />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText secondary={this.getMedicationDrugDescription()} />
              </ListItem>
            </HoverPopover>
          </>
        )}
      </PopupState>
    );
  };

  public hasPrescriptionLinked = () =>
    this.pharm_prescriptions && this.pharm_prescriptions?.length > 0;

  public getMedicationName = (): string =>
    this.fdb_dispensable_drug?.DrugNameDesc;

  public getMedicationDrugDescription = (): string =>
    this.fdb_dispensable_drug?.GenericDispensableDrugDesc ||
    this.fdb_dispensable_drug?.DispensableDrugDesc ||
    '';

  public renderStatusCell = (): React.ReactNode => {
    const isMobileViewport = useIsMobileViewport();
    const dataTestAttribute = { 'data-test': `medication-${this.id}-status` };
    if (this.isActive) {
      return (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Chip
            color="success"
            label="Active"
            {...dataTestAttribute}
            size={isMobileViewport ? 'small' : 'medium'}
          />
          {this.hasPrescriptionLinked() ? (
            <IconButton>
              <MedicationIcon />
            </IconButton>
          ) : null}
        </Stack>
      );
    }

    // Handle deactivated medications
    if (this.cancellation_reason) {
      return (
        <Tooltip
          title={
            <span>
              Medication deactivated on{' '}
              {dayjs(this.tooltipDateUTC).format('YYYY-MM-DD')}
              <br />
              Cancellation Reason: {this.cancellation_reason}
            </span>
          }
          arrow>
          <Chip icon={<Comment />} label="Inactive" {...dataTestAttribute} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={`Medication completed as of ${dayjs(
            this.tooltipDateUTC
          ).format('YYYY-MM-DD')}`}
          arrow>
          <Chip icon={<Check />} label="Inactive" {...dataTestAttribute} />
        </Tooltip>
      );
    }
  };

  public getFrequencyValue = (shouldAddEndTime = false): string => {
    if (
      this.is_informational ||
      this.responsible_party_instance?.is_informational
    ) {
      return 'Informational';
    }

    const howManyTimesADay =
      MedicationRegimenModel.areSchedulesMultipleTimesADay(
        this.medication_task_schedules
      );

    if (howManyTimesADay === 2) {
      return 'Twice a day';
    }

    if (howManyTimesADay === 3) {
      return 'Three times a day';
    }

    if (howManyTimesADay === 4) {
      return 'Four times a day';
    }

    if (howManyTimesADay === 5) {
      return 'Five times a day';
    }

    if (this.medication_task_schedules?.length > 1) {
      return 'Custom';
    }

    const endTime =
      shouldAddEndTime &&
      this.medication_task_schedules[0]?.execution_window_end_time
        ? this.medication_task_schedules[0]?.execution_window_end_time
        : '';

    if (this.medication_task_schedules[0]?.rrule) {
      return transformRRuleStringToHumanText(
        this.medication_task_schedules[0]?.rrule,
        endTime
      );
    } else if (this.medication_task_schedules[0]?.patterned_recurrence) {
      return transformPatternedRecurrenceToHumanText(
        this.medication_task_schedules[0]?.patterned_recurrence
      );
    }
    return '';
  };

  public getTotalDosage = (): string => {
    if (
      this.non_community_dose &&
      (this.is_informational ||
        this.responsible_party_instance?.is_informational)
    ) {
      return calculateFdbTotalDosage(
        this.fdb_dispensable_drug,
        this.non_community_dose
      ) as string;
    }
    if (this.medication_task_schedules?.length > 1) {
      return 'Custom';
    }
    return calculateFdbTotalDosage(
      this.fdb_dispensable_drug,
      this.medication_task_schedules[0]?.number_of_dose_units
    ) as string;
  };

  public getTimeValue = () => this.getTime().value;

  public renderTimeCell = () => this.getTime().display;

  public getCompletedTime = () => {
    if (!this.lastCompletedMedTaskInstance?.date_completed) {
      return null;
    }
    return dayjs(this.lastCompletedMedTaskInstance?.date_completed).format(
      'MMM D, YYYY'
    );
  };

  public renderTimeToShow = () => {
    if (!this.isPrn && this.getFrequencyValue() !== 'Informational') {
      return this.renderCompletedTimeCell();
    }

    return this.renderTimeCell();
  };

  public renderCompletedTimeCell = () => {
    if (!this.lastCompletedMedTaskInstance?.date_completed) {
      return this.getTimeWithoutCompletedTime();
    }
    return (
      <Tooltip
        title={`Last administered ${this.getCompletedTimeWithHours()}`}
        arrow
        placement="top">
        <div>{this.getTimeWithoutCompletedTime()}</div>
      </Tooltip>
    );
  };

  private getTimeWithoutCompletedTime = () => {
    return getExecutionWindowTimeValue({
      format: 'LLLL',
      date_completed: null,
      execution_window_start_time:
        this.medication_task_schedules[0]?.execution_window_start_time,
      execution_window_end_time:
        this.medication_task_schedules[0]?.execution_window_end_time
    }).display;
  };

  public getCompletedTimeWithHours = () => {
    return dayjs(this.lastCompletedMedTaskInstance?.date_completed).format(
      'MMMM D, YYYY h:mm A'
    );
  };

  private getTime = (): { value: number; display: string } => {
    if (
      this.is_informational ||
      this.responsible_party_instance?.is_informational
    ) {
      return {
        value: 0,
        display: 'N/A'
      };
    }
    if (this.medication_task_schedules?.length > 1) {
      return {
        value: 0,
        display: 'Custom'
      };
    }
    return getExecutionWindowTimeValue({
      format: 'LLLL',
      date_completed: this.lastCompletedMedTaskInstance?.date_completed ?? null,
      execution_window_start_time:
        this.medication_task_schedules[0]?.execution_window_start_time,
      execution_window_end_time:
        this.medication_task_schedules[0]?.execution_window_end_time
    });
  };

  public getStartEndTime = () => {
    // For informational tasks, return empty string
    if (
      this.is_informational ||
      this.responsible_party_instance?.is_informational
    ) {
      return '';
    }
    const startDate = this.medication_task_schedules?.[0]
      ?.instance_generation_start_date
      ? dayjs(
          this.medication_task_schedules?.[0]?.instance_generation_start_date
        ).format('MMM D, YYYY')
      : null;

    const endDate = this.medication_task_schedules?.[0]
      ?.instance_generation_end_date
      ? dayjs(
          this.medication_task_schedules?.[0]?.instance_generation_end_date
        ).format('MMM D, YYYY')
      : null;
    return `${startDate} ${endDate ? `- ${endDate}` : ''}`;
  };

  // This is static because it needs to be available for new medications
  public static toMedicationPayload = (
    form: ResidentMedicationForm,
    tzid?: string,
    responsibleParties?: ResponsibleParty[]
  ): Partial<MedicationTaskPayload> => {
    if (!tzid) {
      throw new Error('tzid is required');
    }

    if (!Array.isArray(responsibleParties)) {
      throw new Error('responsibleParties is required');
    }

    return {
      id: form.id,
      fdb_dispensable_drug_id: form.DispensableDrugID!,
      drug_name_desc: form.DispensableDrug?.DispensableDrugDesc || '',
      ...(form.doctor_id ? { doctor_id: form.doctor_id } : {}),
      diagnosis: form.diagnosis || null,
      instructions: form.directions || null,
      s3_prescription_key: form.s3_prescription?.key || null,
      prescription_types: form.prescription_type || null,
      length_mins: form.duration?.value || null,
      equivalent_to: form.equivalent_to || null,
      responsible_party: MedicationTaskModel.getDeprecatedResponsiblePartyValue(
        form.responsible_party
      ),
      medication_task_schedules: (FeatureFlagService.isEnabled(
        ExacareFeature.MEDICATION_TASK_REGIMENS
      )
        ? transformRegimensFormToJsonBody(form, tzid, responsibleParties)
        : transformScheduleFormToJsonBody(
            form,
            tzid,
            responsibleParties
          )) as MedicationTaskSchedulePayload[],
      vital_reqs: MedicationTaskModel.transformVitalsFormToPayload(form),
      task_extension:
        form.extensionEnabled &&
        form.regimens?.[0]?.frequency?.freq !== 'prn' &&
        isStaffResponsibilityV2(form.responsible_party, responsibleParties)
          ? TaskExtensionModel.toTaskExtensionPayload(form.task_extension!)
          : null,
      amount_in_stock: form.amount_in_stock || null,
      ...(!isStaffResponsibilityV2(
        form.responsible_party,
        responsibleParties
      ) &&
      form.non_community_dose &&
      form.non_community_dose.toString().trim().length !== 0
        ? { non_community_dose: form.non_community_dose }
        : {}),
      medication_id: form.medication_id || null,
      responsible_party_id: MedicationTaskModel.getResponsiblePartyId(
        form.responsible_party
      )
    };
  };

  private static getDeprecatedResponsiblePartyValue = (
    responsibleParty: string | null
  ): string | null => {
    return responsibleParty && isNaN(parseInt(responsibleParty))
      ? responsibleParty
      : null;
  };

  private static getResponsiblePartyId = (
    responsibleParty: string | null
  ): string | null => {
    return responsibleParty && isNaN(parseInt(responsibleParty))
      ? null
      : responsibleParty;
  };

  public areSchedulesCustom = (): boolean =>
    this.medication_task_schedules.every((schedule) => {
      if (!schedule.rrule) {
        return false;
      }
      const rruleOptions = RRule.parseString(schedule.rrule);
      return rruleOptions.byweekday;
    }) && this.medication_task_schedules?.length > 1;

  public areSchedulesMultipleTimesADay = () =>
    this.medication_task_schedules.every((schedule) => {
      if (!schedule.rrule) {
        return false;
      }
      const rruleOptions = RRule.parseString(schedule.rrule);
      // Custom schedules can only have a byweekday rrule option
      return !rruleOptions.byweekday && rruleOptions.interval === 1;
    }) && this.medication_task_schedules?.length > 1;

  public toResidentMedicationForm = () => {
    let frequency, scheduleForm, regimens;
    if (FeatureFlagService.isEnabled(ExacareFeature.MEDICATION_TASK_REGIMENS)) {
      if (this.regimens.length > 0) {
        regimens = this.regimens.map((regimen) => regimen.toForm());
      } else {
        // Make sure regimens is populated with at least one item so that the
        // user must fill out the form before continuing the med form
        regimens = [
          {
            frequency: {
              dtstart: new Date()
            },
            schedule: generateScheduleFormDataDefaultValues()
          }
        ];
      }
    } else {
      const { frequency: freq, schedule } = this.toDeprecatedTimeForm();
      frequency = freq;
      scheduleForm = schedule;
    }

    const s3Prescription: ResidentMedicationForm['s3_prescription'] = this
      .s3_prescription_key
      ? {
          name: 'View uploaded prescription',
          key: this.s3_prescription_key,
          mime_type:
            (this.s3_prescription_key
              ?.split('.')
              ?.slice?.(-1)?.[0] as string) ?? '',
          size: 0
        }
      : null;

    return {
      id: this.id,
      frequency,
      schedule: scheduleForm,
      regimens,
      medication: {
        id: this.fdb_dispensable_drug?.DrugNameID,
        drug_name: this.fdb_dispensable_drug?.DrugNameDesc
      },
      medicationGeneric: {
        id: this.fdb_dispensable_drug?.NameTypeCode,
        name: this.fdb_dispensable_drug?.NameTypeCodeDesc
      },
      medicationRoute: {
        id: this.fdb_dispensable_drug?.RouteID,
        name: this.fdb_dispensable_drug?.RouteDesc
      },
      medicationForm: {
        id: this.fdb_dispensable_drug?.DoseFormID,
        name: this.fdb_dispensable_drug?.DoseFormDesc
      },
      dose: {
        id: this.fdb_dispensable_drug?.MedStrength,
        strength: `${this.fdb_dispensable_drug?.MedStrength} ${this.fdb_dispensable_drug?.MedStrengthUnit}`
      },
      DispensableDrug: this.fdb_dispensable_drug,
      DispensableDrugID: this.fdb_dispensable_drug?.DispensableDrugID,
      directions: this.instructions,
      doctor_id: this.doctor_id ?? null,
      diagnosis: this.diagnosis,
      prescription_type:
        prescriptionTypeOptions.find(
          (type) => type === this.prescription_types
        ) ?? null,
      duration:
        medicationDurationOptions.find(
          (duration) => duration.value === this?.length_mins
        ) ?? medicationDurationOptions[0],
      s3_prescription: s3Prescription,
      equivalent_to: this.equivalent_to,
      responsible_party: this.responsible_party_id || this.responsible_party,
      followupInstructions:
        this.medication_task_schedules?.[0]?.additional_information
          ?.followUpTaskDefinition?.instructions,
      followupIntervalInMinutes:
        this.medication_task_schedules?.[0]?.additional_information
          ?.followUpTaskDefinition?.timeIntervalInMinutes,
      vitals: MedicationTaskModel.transformVitalsPayloadToForm(this.vital_reqs),
      vitalsEnabled: this.vital_reqs?.length > 0,
      extensionEnabled: this.task_extension != null,
      task_extension: this.task_extension,
      amount_in_stock: this.amount_in_stock,
      is_editable: this.is_editable,
      non_community_dose: this.non_community_dose,
      medication_id: this.fdb_dispensable_drug?.medication_id,
      responsible_party_id: this.responsible_party_id,
      prn_min_interval_minutes:
        this.medication_task_schedules?.[0]?.prn_min_interval_minutes,
      prn_max_daily_number_of_dose_units:
        this.medication_task_schedules?.[0]?.prn_max_daily_number_of_dose_units,
      prescription_interval:
        this.medication_task_schedules?.[0]?.prescription_interval,
      may_have_max_daily_dosage: this.may_have_max_daily_dosage
    } as ResidentMedicationForm;
  };

  private toDeprecatedTimeForm = (): Pick<
    ResidentMedicationForm,
    'frequency' | 'schedule'
  > => {
    let frequency = {} as FrequencyTimeFormData;
    const scheduleForm = generateScheduleFormDataDefaultValues();

    if (this.medication_task_schedules?.length > 1) {
      const howManyTimesADay =
        MedicationRegimenModel.areSchedulesMultipleTimesADay(
          this.medication_task_schedules
        );
      frequency!.freq =
        howManyTimesADay === 2
          ? twiceADayFrequencyOption.value
          : howManyTimesADay === 3
          ? threeTimesADayFrequencyOption.value
          : howManyTimesADay === 4
          ? fourTimesADayFrequencyOption.value
          : howManyTimesADay == 5
          ? fiveTimesADayFrequencyOption.value
          : customFrequencyOption.value;

      this.medication_task_schedules.forEach((schedule) => {
        const { dtstart, until } =
          transformSchedulePayloadToFrequencyTimeFormData(
            schedule,
            scheduleForm
          );
        // HACK: Set the top level frequency's dtstart and until to one of the schedule rrule's dtstart and until.
        // Each schedule should have the same dtstart and until, so it doesn't matter which one we pick.
        frequency.dtstart = dtstart;
        frequency.until = until;
      });
    } else if (this.medication_task_schedules?.length === 1) {
      // For single schedules, the frequency property should be fully set
      frequency = transformSchedulePayloadToFrequencyTimeFormData(
        this.medication_task_schedules[0]
      );
    }

    return {
      frequency,
      schedule: scheduleForm
    };
  };

  private constructIsPrn = () => {
    if (
      this.is_informational ||
      this.responsible_party_instance?.is_informational
    ) {
      return false;
    }
    if (this.medication_task_schedules?.length !== 1) {
      return false;
    }
    if (!this.medication_task_schedules[0].rrule) {
      return false;
    }
    const { count, interval } = RRule.parseString(
      this.medication_task_schedules[0].rrule
    );
    return count === 0 && interval === 0;
  };

  public isControlledSubstance = () =>
    this.fdb_dispensable_drug.FederalDEAClassCode &&
    DEA_SCHEDULE_CODES.includes(this.fdb_dispensable_drug?.FederalDEAClassCode);

  public getNewTooltipTitle = () => {
    const today = dayjs();
    let startDate: dayjs.Dayjs;
    if (FeatureFlagService.isEnabled(ExacareFeature.MEDICATION_TASK_REGIMENS)) {
      startDate = dayjs(this.regimens[0]?.dateStart);
    } else {
      startDate = dayjs(
        this.medication_task_schedules[0]?.instance_generation_start_date
      );
    }
    const formattedStartDate = startDate.format('MM/DD/YYYY');
    if (startDate.isBefore(today) || startDate.isToday()) {
      return `Started ${formattedStartDate}`;
    }
    return `Starts ${formattedStartDate}`;
  };

  public getCellClassName = (identifier: string): string =>
    `medication-datagrid-row-id-${this.id}-${identifier}-cell`;

  public static transformVitalsFormToPayload = (
    form: ResidentMedicationForm
  ): MedicationTaskVitalRequirementsPayload[] => {
    return form.vitalsEnabled
      ? Object.keys(form.vitals).reduce((accum, vitalId: string) => {
          if (form.vitals[vitalId].enabled) {
            if (vitalId === BLOOD_PRESSURE_VITAL_ID) {
              // Empty values are casted to number 0
              const minSystolic = Number(
                form.vitals[vitalId].minSystolicBloodPressure
              );
              const minDiastolic = Number(
                form.vitals[vitalId].minDiastolicBloodPressure
              );
              const maxSystolic = Number(
                form.vitals[vitalId].maxSystolicBloodPressure
              );
              const maxDiastolic = Number(
                form.vitals[vitalId].maxDiastolicBloodPressure
              );

              const minValue =
                minSystolic > 0 || minDiastolic > 0
                  ? `${minSystolic} ${minDiastolic}`
                  : null;

              const maxValue =
                maxSystolic > 0 || maxDiastolic > 0
                  ? `${maxSystolic} ${maxDiastolic}`
                  : null;

              accum.push({
                vital_type_id: BLOOD_PRESSURE_VITAL_ID,
                min_value: minValue,
                max_value: maxValue
              } as MedicationTaskVitalRequirementsPayload);
            } else {
              accum.push({
                vital_type_id: vitalId,
                min_value: form.vitals[vitalId].min || null,
                max_value: form.vitals[vitalId].max || null
              } as MedicationTaskVitalRequirementsPayload);
            }
          }
          return accum;
        }, [] as MedicationTaskVitalRequirementsPayload[])
      : [];
  };

  public static transformVitalsPayloadToForm = (
    vitals: MedicationTaskVitalRequirementsPayload[]
  ): ResidentMedicationForm['vitals'] => {
    const initializedVitalsForm = Object.assign(
      {},
      medicationVitalsDefaultFormValues
    );
    vitals?.forEach((vital) => {
      if (vital.vital_type_id === BLOOD_PRESSURE_VITAL_ID) {
        const [minSystolic, minDiastolic] = vital.min_value?.split(' ') ?? [
          '',
          ''
        ];
        const [maxSystolic, maxDiastolic] = vital.max_value?.split(' ') ?? [
          '',
          ''
        ];
        initializedVitalsForm[BLOOD_PRESSURE_VITAL_ID] = {
          enabled: true,
          min: '',
          max: '',
          minSystolicBloodPressure: minSystolic,
          minDiastolicBloodPressure: minDiastolic,
          maxSystolicBloodPressure: maxSystolic,
          maxDiastolicBloodPressure: maxDiastolic
        };
      } else {
        initializedVitalsForm[vital.vital_type_id] = {
          enabled: true,
          min: vital.min_value ?? '',
          max: vital.max_value ?? '',
          minSystolicBloodPressure: '',
          minDiastolicBloodPressure: '',
          maxSystolicBloodPressure: '',
          maxDiastolicBloodPressure: ''
        };
      }
    });
    return initializedVitalsForm;
  };

  public getMedicationWarning = (): {
    color: 'error' | 'warning';
    text: string;
  } | null => {
    if (this.hasDiscontinuedPrescription()) {
      return {
        color: 'error',
        text: this.discountedPrescriptionTooltipMessage
      };
    }

    if (this.prnLimitationsModel?.canOnlyAdministerPartialDose) {
      return {
        color: 'warning',
        text: this.prnLimitationsModel.partialDoseMessage ?? ''
      };
    }

    if (this.prnLimitationsModel?.hasNoMoreAvailableDoses) {
      return {
        color: 'error',
        text: this.prnLimitationsModel.noMoreAvailableDosesMessage?.body ?? ''
      };
    }

    return null;
  };

  public hasDiscontinuedPrescription = (): boolean => {
    return (
      this.pharm_prescriptions?.some(
        (prescription) => prescription.has_dc_message
      ) ?? false
    );
  };

  private get discountedPrescriptionTooltipMessage(): string {
    const residentName = this.resident?.fullName ?? 'Resident';
    return `${residentName}'s pharmacy discontinued (“DCed”) this medication; please check with a supervisor before administering. If this is correct, please navigate to the Pharmacy module in ExaCare and “DC” this medication.`;
  }
}
