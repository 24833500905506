/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalIdentification } from './AdditionalIdentification';
import {
    AdditionalIdentificationFromJSON,
    AdditionalIdentificationFromJSONTyped,
    AdditionalIdentificationToJSON,
} from './AdditionalIdentification';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';
import type { RelatedEntity } from './RelatedEntity';
import {
    RelatedEntityFromJSON,
    RelatedEntityFromJSONTyped,
    RelatedEntityToJSON,
} from './RelatedEntity';

/**
 * 
 * @export
 * @interface PharmacySection
 */
export interface PharmacySection {
    /**
     * 
     * @type {DateRange}
     * @memberof PharmacySection
     */
    date_range?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof PharmacySection
     */
    organization_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacySection
     */
    contract_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacySection
     */
    plan_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacySection
     */
    plan_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacySection
     */
    plan_description?: string;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof PharmacySection
     */
    contacts?: Array<Contact>;
    /**
     * 
     * @type {Address}
     * @memberof PharmacySection
     */
    address?: Address;
    /**
     * 
     * @type {Array<AdditionalIdentification>}
     * @memberof PharmacySection
     */
    additional_identifications?: Array<AdditionalIdentification>;
    /**
     * 
     * @type {Array<RelatedEntity>}
     * @memberof PharmacySection
     */
    related_entities?: Array<RelatedEntity>;
}

/**
 * Check if a given object implements the PharmacySection interface.
 */
export function instanceOfPharmacySection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PharmacySectionFromJSON(json: any): PharmacySection {
    return PharmacySectionFromJSONTyped(json, false);
}

export function PharmacySectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacySection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_range': !exists(json, 'date_range') ? undefined : DateRangeFromJSON(json['date_range']),
        'organization_name': !exists(json, 'organization_name') ? undefined : json['organization_name'],
        'contract_number': !exists(json, 'contract_number') ? undefined : json['contract_number'],
        'plan_number': !exists(json, 'plan_number') ? undefined : json['plan_number'],
        'plan_name': !exists(json, 'plan_name') ? undefined : json['plan_name'],
        'plan_description': !exists(json, 'plan_description') ? undefined : json['plan_description'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(ContactFromJSON)),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'additional_identifications': !exists(json, 'additional_identifications') ? undefined : ((json['additional_identifications'] as Array<any>).map(AdditionalIdentificationFromJSON)),
        'related_entities': !exists(json, 'related_entities') ? undefined : ((json['related_entities'] as Array<any>).map(RelatedEntityFromJSON)),
    };
}

export function PharmacySectionToJSON(value?: PharmacySection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_range': DateRangeToJSON(value.date_range),
        'organization_name': value.organization_name,
        'contract_number': value.contract_number,
        'plan_number': value.plan_number,
        'plan_name': value.plan_name,
        'plan_description': value.plan_description,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(ContactToJSON)),
        'address': AddressToJSON(value.address),
        'additional_identifications': value.additional_identifications === undefined ? undefined : ((value.additional_identifications as Array<any>).map(AdditionalIdentificationToJSON)),
        'related_entities': value.related_entities === undefined ? undefined : ((value.related_entities as Array<any>).map(RelatedEntityToJSON)),
    };
}

