/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalIdentification } from './AdditionalIdentification';
import {
    AdditionalIdentificationFromJSON,
    AdditionalIdentificationFromJSONTyped,
    AdditionalIdentificationToJSON,
} from './AdditionalIdentification';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';
import type { RelatedEntity } from './RelatedEntity';
import {
    RelatedEntityFromJSON,
    RelatedEntityFromJSONTyped,
    RelatedEntityToJSON,
} from './RelatedEntity';
import type { TextMessage } from './TextMessage';
import {
    TextMessageFromJSON,
    TextMessageFromJSONTyped,
    TextMessageToJSON,
} from './TextMessage';

/**
 * 
 * @export
 * @interface BenefitInformation
 */
export interface BenefitInformation {
    /**
     * 
     * @type {DateRange}
     * @memberof BenefitInformation
     */
    date_range?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof BenefitInformation
     */
    benefit_info_code?: string;
    /**
     * 
     * @type {string}
     * @memberof BenefitInformation
     */
    benefit_info_code_definition?: string;
    /**
     * 
     * @type {Array<TextMessage>}
     * @memberof BenefitInformation
     */
    messages?: Array<TextMessage>;
    /**
     * 
     * @type {Array<RelatedEntity>}
     * @memberof BenefitInformation
     */
    related_entities?: Array<RelatedEntity>;
    /**
     * 
     * @type {Array<AdditionalIdentification>}
     * @memberof BenefitInformation
     */
    additional_identifications?: Array<AdditionalIdentification>;
}

/**
 * Check if a given object implements the BenefitInformation interface.
 */
export function instanceOfBenefitInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BenefitInformationFromJSON(json: any): BenefitInformation {
    return BenefitInformationFromJSONTyped(json, false);
}

export function BenefitInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BenefitInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_range': !exists(json, 'date_range') ? undefined : DateRangeFromJSON(json['date_range']),
        'benefit_info_code': !exists(json, 'benefit_info_code') ? undefined : json['benefit_info_code'],
        'benefit_info_code_definition': !exists(json, 'benefit_info_code_definition') ? undefined : json['benefit_info_code_definition'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(TextMessageFromJSON)),
        'related_entities': !exists(json, 'related_entities') ? undefined : ((json['related_entities'] as Array<any>).map(RelatedEntityFromJSON)),
        'additional_identifications': !exists(json, 'additional_identifications') ? undefined : ((json['additional_identifications'] as Array<any>).map(AdditionalIdentificationFromJSON)),
    };
}

export function BenefitInformationToJSON(value?: BenefitInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_range': DateRangeToJSON(value.date_range),
        'benefit_info_code': value.benefit_info_code,
        'benefit_info_code_definition': value.benefit_info_code_definition,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(TextMessageToJSON)),
        'related_entities': value.related_entities === undefined ? undefined : ((value.related_entities as Array<any>).map(RelatedEntityToJSON)),
        'additional_identifications': value.additional_identifications === undefined ? undefined : ((value.additional_identifications as Array<any>).map(AdditionalIdentificationToJSON)),
    };
}

