/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkUpdateScreenerTagsRequest,
  CombineLeads200Response,
  CombineLeadsRequest,
  CreateDocumentExclusionTerm201Response,
  CreateDocumentExclusionTermRequest,
  CreateLeadInfo201Response,
  CreateLeadInfoRequest,
  CreateScreenerLeadTag201Response,
  CreateSourceTag201Response,
  FindAllBedTypes200Response,
  FindAllBeds200Response,
  FindAllIcd10Codes200Response,
  FindAllScreenerResidents200Response,
  FindAllScreenerTags200Response,
  FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner,
  FindAllSourceTags200Response,
  FindAllSourceTags200ResponseDataInner,
  FindOneBed200Response,
  GetDocumentExclusionTerms200Response,
  GetFacilityBedOccupancyStats200Response,
  GetFacilitySettings200Response,
  RelocateScreenerResident200Response,
  RelocateScreenerResidentRequest,
  ScheduledOccupancyType,
  UpdateDocumentExclusionTermRequest,
} from '../models/index';
import {
    BulkUpdateScreenerTagsRequestFromJSON,
    BulkUpdateScreenerTagsRequestToJSON,
    CombineLeads200ResponseFromJSON,
    CombineLeads200ResponseToJSON,
    CombineLeadsRequestFromJSON,
    CombineLeadsRequestToJSON,
    CreateDocumentExclusionTerm201ResponseFromJSON,
    CreateDocumentExclusionTerm201ResponseToJSON,
    CreateDocumentExclusionTermRequestFromJSON,
    CreateDocumentExclusionTermRequestToJSON,
    CreateLeadInfo201ResponseFromJSON,
    CreateLeadInfo201ResponseToJSON,
    CreateLeadInfoRequestFromJSON,
    CreateLeadInfoRequestToJSON,
    CreateScreenerLeadTag201ResponseFromJSON,
    CreateScreenerLeadTag201ResponseToJSON,
    CreateSourceTag201ResponseFromJSON,
    CreateSourceTag201ResponseToJSON,
    FindAllBedTypes200ResponseFromJSON,
    FindAllBedTypes200ResponseToJSON,
    FindAllBeds200ResponseFromJSON,
    FindAllBeds200ResponseToJSON,
    FindAllIcd10Codes200ResponseFromJSON,
    FindAllIcd10Codes200ResponseToJSON,
    FindAllScreenerResidents200ResponseFromJSON,
    FindAllScreenerResidents200ResponseToJSON,
    FindAllScreenerTags200ResponseFromJSON,
    FindAllScreenerTags200ResponseToJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON,
    FindAllSourceTags200ResponseFromJSON,
    FindAllSourceTags200ResponseToJSON,
    FindAllSourceTags200ResponseDataInnerFromJSON,
    FindAllSourceTags200ResponseDataInnerToJSON,
    FindOneBed200ResponseFromJSON,
    FindOneBed200ResponseToJSON,
    GetDocumentExclusionTerms200ResponseFromJSON,
    GetDocumentExclusionTerms200ResponseToJSON,
    GetFacilityBedOccupancyStats200ResponseFromJSON,
    GetFacilityBedOccupancyStats200ResponseToJSON,
    GetFacilitySettings200ResponseFromJSON,
    GetFacilitySettings200ResponseToJSON,
    RelocateScreenerResident200ResponseFromJSON,
    RelocateScreenerResident200ResponseToJSON,
    RelocateScreenerResidentRequestFromJSON,
    RelocateScreenerResidentRequestToJSON,
    ScheduledOccupancyTypeFromJSON,
    ScheduledOccupancyTypeToJSON,
    UpdateDocumentExclusionTermRequestFromJSON,
    UpdateDocumentExclusionTermRequestToJSON,
} from '../models/index';

export interface BulkUpdateScreenerTagsOperationRequest {
    bulkUpdateScreenerTagsRequest: BulkUpdateScreenerTagsRequest;
}

export interface CombineLeadsOperationRequest {
    combineLeadsRequest: CombineLeadsRequest;
}

export interface CreateDocumentExclusionTermOperationRequest {
    createDocumentExclusionTermRequest: CreateDocumentExclusionTermRequest;
}

export interface CreateLeadInfoOperationRequest {
    createLeadInfoRequest: CreateLeadInfoRequest;
}

export interface CreateScreenerLeadTagRequest {
    findAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner;
}

export interface CreateSourceTagRequest {
    findAllSourceTags200ResponseDataInner: FindAllSourceTags200ResponseDataInner;
}

export interface DeleteDocumentExclusionTermRequest {
    termId: string;
}

export interface DeleteScreenerLeadTagRequest {
    id: string;
}

export interface DeleteScreenerTagByIdRequest {
    id: string;
}

export interface FindAllBedTypesRequest {
    facilityId: string;
}

export interface FindAllBedsRequest {
    facilityId: string;
    bedTypeIds?: string;
    includeOccupied?: boolean;
    availabilityFrom?: Date;
    availabilityTo?: Date;
}

export interface FindAllIcd10CodesRequest {
    search?: string;
    limit?: number;
    page?: number;
}

export interface FindAllScheduledScreenerResidentsRequest {
    facilityId: string;
    scheduledDate: Date;
    scheduledOccupancyType: ScheduledOccupancyType;
    limit?: number;
    page?: number;
    search?: string;
    sourceId?: string;
    connector?: string;
    bedTypeId?: string;
}

export interface FindAllScreenerResidentsRequest {
    facilityId: string;
    limit?: number;
    page?: number;
    search?: string;
    sourceId?: string;
    bedTypeId?: string;
    onlyTodayActivity?: boolean;
    stayType?: string;
    admissionStatus?: string;
}

export interface FindAllScreenerTagsRequest {
    facilityId: string;
}

export interface FindAllSourceTagsRequest {
    facilityId: string;
}

export interface FindOneBedRequest {
    bedId: string;
}

export interface GetDocumentExclusionTermsRequest {
    facilityId: string;
}

export interface GetFacilityBedOccupancyStatsRequest {
    facilityId: string;
}

export interface GetFacilitySettingsRequest {
    facilityId: string;
}

export interface RelocateScreenerResidentOperationRequest {
    residentId: string;
    relocateScreenerResidentRequest: RelocateScreenerResidentRequest;
}

export interface TriggerPdpmEstimatorRequest {
    leadId: string;
}

export interface UpdateDocumentExclusionTermOperationRequest {
    termId: string;
    updateDocumentExclusionTermRequest: UpdateDocumentExclusionTermRequest;
}

export interface UpdateLeadInfoRequest {
    leadId: string;
    createLeadInfoRequest: CreateLeadInfoRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Bulk update screener tags
     */
    async bulkUpdateScreenerTagsRaw(requestParameters: BulkUpdateScreenerTagsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllScreenerTags200Response>> {
        if (requestParameters.bulkUpdateScreenerTagsRequest === null || requestParameters.bulkUpdateScreenerTagsRequest === undefined) {
            throw new runtime.RequiredError('bulkUpdateScreenerTagsRequest','Required parameter requestParameters.bulkUpdateScreenerTagsRequest was null or undefined when calling bulkUpdateScreenerTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-tags/bulk`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateScreenerTagsRequestToJSON(requestParameters.bulkUpdateScreenerTagsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllScreenerTags200ResponseFromJSON(jsonValue));
    }

    /**
     * Bulk update screener tags
     */
    async bulkUpdateScreenerTags(requestParameters: BulkUpdateScreenerTagsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllScreenerTags200Response> {
        const response = await this.bulkUpdateScreenerTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Combine leads
     * Combine leads
     */
    async combineLeadsRaw(requestParameters: CombineLeadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CombineLeads200Response>> {
        if (requestParameters.combineLeadsRequest === null || requestParameters.combineLeadsRequest === undefined) {
            throw new runtime.RequiredError('combineLeadsRequest','Required parameter requestParameters.combineLeadsRequest was null or undefined when calling combineLeads.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/combine`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CombineLeadsRequestToJSON(requestParameters.combineLeadsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CombineLeads200ResponseFromJSON(jsonValue));
    }

    /**
     * Combine leads
     * Combine leads
     */
    async combineLeads(requestParameters: CombineLeadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CombineLeads200Response> {
        const response = await this.combineLeadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new document exclusion term for a specific organization and facility
     * Create document exclusion term
     */
    async createDocumentExclusionTermRaw(requestParameters: CreateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentExclusionTerm201Response>> {
        if (requestParameters.createDocumentExclusionTermRequest === null || requestParameters.createDocumentExclusionTermRequest === undefined) {
            throw new runtime.RequiredError('createDocumentExclusionTermRequest','Required parameter requestParameters.createDocumentExclusionTermRequest was null or undefined when calling createDocumentExclusionTerm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document-exclusion-terms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentExclusionTermRequestToJSON(requestParameters.createDocumentExclusionTermRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentExclusionTerm201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new document exclusion term for a specific organization and facility
     * Create document exclusion term
     */
    async createDocumentExclusionTerm(requestParameters: CreateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentExclusionTerm201Response> {
        const response = await this.createDocumentExclusionTermRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener lead info
     */
    async createLeadInfoRaw(requestParameters: CreateLeadInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeadInfo201Response>> {
        if (requestParameters.createLeadInfoRequest === null || requestParameters.createLeadInfoRequest === undefined) {
            throw new runtime.RequiredError('createLeadInfoRequest','Required parameter requestParameters.createLeadInfoRequest was null or undefined when calling createLeadInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/lead-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeadInfoRequestToJSON(requestParameters.createLeadInfoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeadInfo201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener lead info
     */
    async createLeadInfo(requestParameters: CreateLeadInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeadInfo201Response> {
        const response = await this.createLeadInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener lead tag
     * Create a screener lead tag
     */
    async createScreenerLeadTagRaw(requestParameters: CreateScreenerLeadTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateScreenerLeadTag201Response>> {
        if (requestParameters.findAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner === null || requestParameters.findAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner === undefined) {
            throw new runtime.RequiredError('findAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner','Required parameter requestParameters.findAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner was null or undefined when calling createScreenerLeadTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-lead-tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON(requestParameters.findAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateScreenerLeadTag201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener lead tag
     * Create a screener lead tag
     */
    async createScreenerLeadTag(requestParameters: CreateScreenerLeadTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateScreenerLeadTag201Response> {
        const response = await this.createScreenerLeadTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener source tag
     */
    async createSourceTagRaw(requestParameters: CreateSourceTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSourceTag201Response>> {
        if (requestParameters.findAllSourceTags200ResponseDataInner === null || requestParameters.findAllSourceTags200ResponseDataInner === undefined) {
            throw new runtime.RequiredError('findAllSourceTags200ResponseDataInner','Required parameter requestParameters.findAllSourceTags200ResponseDataInner was null or undefined when calling createSourceTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/source-tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FindAllSourceTags200ResponseDataInnerToJSON(requestParameters.findAllSourceTags200ResponseDataInner),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSourceTag201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener source tag
     */
    async createSourceTag(requestParameters: CreateSourceTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSourceTag201Response> {
        const response = await this.createSourceTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing document exclusion term
     * Delete document exclusion term
     */
    async deleteDocumentExclusionTermRaw(requestParameters: DeleteDocumentExclusionTermRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.termId === null || requestParameters.termId === undefined) {
            throw new runtime.RequiredError('termId','Required parameter requestParameters.termId was null or undefined when calling deleteDocumentExclusionTerm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-exclusion-terms/{term_id}`.replace(`{${"term_id"}}`, encodeURIComponent(String(requestParameters.termId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing document exclusion term
     * Delete document exclusion term
     */
    async deleteDocumentExclusionTerm(requestParameters: DeleteDocumentExclusionTermRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentExclusionTermRaw(requestParameters, initOverrides);
    }

    /**
     * Delete screener lead tag
     * Delete screener lead tag
     */
    async deleteScreenerLeadTagRaw(requestParameters: DeleteScreenerLeadTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteScreenerLeadTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-lead-tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete screener lead tag
     * Delete screener lead tag
     */
    async deleteScreenerLeadTag(requestParameters: DeleteScreenerLeadTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteScreenerLeadTagRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener tag by ID
     */
    async deleteScreenerTagByIdRaw(requestParameters: DeleteScreenerTagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteScreenerTagById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener tag by ID
     */
    async deleteScreenerTagById(requestParameters: DeleteScreenerTagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteScreenerTagByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve bed types
     * Fine all bed types
     */
    async findAllBedTypesRaw(requestParameters: FindAllBedTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllBedTypes200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllBedTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bed-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllBedTypes200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve bed types
     * Fine all bed types
     */
    async findAllBedTypes(requestParameters: FindAllBedTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllBedTypes200Response> {
        const response = await this.findAllBedTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve beds for a facility
     * Find all beds
     */
    async findAllBedsRaw(requestParameters: FindAllBedsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllBeds200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllBeds.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.bedTypeIds !== undefined) {
            queryParameters['bed_type_ids'] = requestParameters.bedTypeIds;
        }

        if (requestParameters.includeOccupied !== undefined) {
            queryParameters['include_occupied'] = requestParameters.includeOccupied;
        }

        if (requestParameters.availabilityFrom !== undefined) {
            queryParameters['availability_from'] = (requestParameters.availabilityFrom as any).toISOString().substring(0,10);
        }

        if (requestParameters.availabilityTo !== undefined) {
            queryParameters['availability_to'] = (requestParameters.availabilityTo as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/beds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllBeds200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve beds for a facility
     * Find all beds
     */
    async findAllBeds(requestParameters: FindAllBedsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllBeds200Response> {
        const response = await this.findAllBedsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all ICD 10 codes
     */
    async findAllIcd10CodesRaw(requestParameters: FindAllIcd10CodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIcd10Codes200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/icd10-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIcd10Codes200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all ICD 10 codes
     */
    async findAllIcd10Codes(requestParameters: FindAllIcd10CodesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIcd10Codes200Response> {
        const response = await this.findAllIcd10CodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all scheduled screener residents
     * Find all scheduled screener residents
     */
    async findAllScheduledScreenerResidentsRaw(requestParameters: FindAllScheduledScreenerResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllScreenerResidents200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllScheduledScreenerResidents.');
        }

        if (requestParameters.scheduledDate === null || requestParameters.scheduledDate === undefined) {
            throw new runtime.RequiredError('scheduledDate','Required parameter requestParameters.scheduledDate was null or undefined when calling findAllScheduledScreenerResidents.');
        }

        if (requestParameters.scheduledOccupancyType === null || requestParameters.scheduledOccupancyType === undefined) {
            throw new runtime.RequiredError('scheduledOccupancyType','Required parameter requestParameters.scheduledOccupancyType was null or undefined when calling findAllScheduledScreenerResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.scheduledDate !== undefined) {
            queryParameters['scheduled_date'] = (requestParameters.scheduledDate as any).toISOString().substring(0,10);
        }

        if (requestParameters.scheduledOccupancyType !== undefined) {
            queryParameters['scheduled_occupancy_type'] = requestParameters.scheduledOccupancyType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sourceId !== undefined) {
            queryParameters['source_id'] = requestParameters.sourceId;
        }

        if (requestParameters.connector !== undefined) {
            queryParameters['connector'] = requestParameters.connector;
        }

        if (requestParameters.bedTypeId !== undefined) {
            queryParameters['bed_type_id'] = requestParameters.bedTypeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-residents/scheduled-occupancies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllScreenerResidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve all scheduled screener residents
     * Find all scheduled screener residents
     */
    async findAllScheduledScreenerResidents(requestParameters: FindAllScheduledScreenerResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllScreenerResidents200Response> {
        const response = await this.findAllScheduledScreenerResidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve screener residents for a facility
     * Find all screener residents
     */
    async findAllScreenerResidentsRaw(requestParameters: FindAllScreenerResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllScreenerResidents200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllScreenerResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sourceId !== undefined) {
            queryParameters['source_id'] = requestParameters.sourceId;
        }

        if (requestParameters.bedTypeId !== undefined) {
            queryParameters['bed_type_id'] = requestParameters.bedTypeId;
        }

        if (requestParameters.onlyTodayActivity !== undefined) {
            queryParameters['only_today_activity'] = requestParameters.onlyTodayActivity;
        }

        if (requestParameters.stayType !== undefined) {
            queryParameters['stay_type'] = requestParameters.stayType;
        }

        if (requestParameters.admissionStatus !== undefined) {
            queryParameters['admission_status'] = requestParameters.admissionStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-residents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllScreenerResidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve screener residents for a facility
     * Find all screener residents
     */
    async findAllScreenerResidents(requestParameters: FindAllScreenerResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllScreenerResidents200Response> {
        const response = await this.findAllScreenerResidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all screener tags
     */
    async findAllScreenerTagsRaw(requestParameters: FindAllScreenerTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllScreenerTags200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllScreenerTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllScreenerTags200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener tags
     */
    async findAllScreenerTags(requestParameters: FindAllScreenerTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllScreenerTags200Response> {
        const response = await this.findAllScreenerTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all screener source tags
     */
    async findAllSourceTagsRaw(requestParameters: FindAllSourceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllSourceTags200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllSourceTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/source-tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllSourceTags200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener source tags
     */
    async findAllSourceTags(requestParameters: FindAllSourceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllSourceTags200Response> {
        const response = await this.findAllSourceTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a bed by ID
     */
    async findOneBedRaw(requestParameters: FindOneBedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBed200Response>> {
        if (requestParameters.bedId === null || requestParameters.bedId === undefined) {
            throw new runtime.RequiredError('bedId','Required parameter requestParameters.bedId was null or undefined when calling findOneBed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/beds/{bed_id}`.replace(`{${"bed_id"}}`, encodeURIComponent(String(requestParameters.bedId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBed200ResponseFromJSON(jsonValue));
    }

    /**
     * Find a bed by ID
     */
    async findOneBed(requestParameters: FindOneBedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBed200Response> {
        const response = await this.findOneBedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve document exclusion terms for a facility
     * Get document exclusion terms
     */
    async getDocumentExclusionTermsRaw(requestParameters: GetDocumentExclusionTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDocumentExclusionTerms200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getDocumentExclusionTerms.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-exclusion-terms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentExclusionTerms200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve document exclusion terms for a facility
     * Get document exclusion terms
     */
    async getDocumentExclusionTerms(requestParameters: GetDocumentExclusionTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDocumentExclusionTerms200Response> {
        const response = await this.getDocumentExclusionTermsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve facility bed occupancy stats
     * Get facility bed occupancy stats
     */
    async getFacilityBedOccupancyStatsRaw(requestParameters: GetFacilityBedOccupancyStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFacilityBedOccupancyStats200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getFacilityBedOccupancyStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-residents/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFacilityBedOccupancyStats200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve facility bed occupancy stats
     * Get facility bed occupancy stats
     */
    async getFacilityBedOccupancyStats(requestParameters: GetFacilityBedOccupancyStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFacilityBedOccupancyStats200Response> {
        const response = await this.getFacilityBedOccupancyStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve facility settings
     * Get facility settings
     */
    async getFacilitySettingsRaw(requestParameters: GetFacilitySettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFacilitySettings200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getFacilitySettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facility_id}/settings`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFacilitySettings200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve facility settings
     * Get facility settings
     */
    async getFacilitySettings(requestParameters: GetFacilitySettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFacilitySettings200Response> {
        const response = await this.getFacilitySettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Relocate a screener resident to a new bed
     * Relocate screener resident
     */
    async relocateScreenerResidentRaw(requestParameters: RelocateScreenerResidentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RelocateScreenerResident200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling relocateScreenerResident.');
        }

        if (requestParameters.relocateScreenerResidentRequest === null || requestParameters.relocateScreenerResidentRequest === undefined) {
            throw new runtime.RequiredError('relocateScreenerResidentRequest','Required parameter requestParameters.relocateScreenerResidentRequest was null or undefined when calling relocateScreenerResident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-residents/{resident_id}/relocate`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RelocateScreenerResidentRequestToJSON(requestParameters.relocateScreenerResidentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RelocateScreenerResident200ResponseFromJSON(jsonValue));
    }

    /**
     * Relocate a screener resident to a new bed
     * Relocate screener resident
     */
    async relocateScreenerResident(requestParameters: RelocateScreenerResidentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RelocateScreenerResident200Response> {
        const response = await this.relocateScreenerResidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger the Pdpm Estimator for a lead
     * Trigger Pdpm Estimator
     */
    async triggerPdpmEstimatorRaw(requestParameters: TriggerPdpmEstimatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling triggerPdpmEstimator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/trigger-pdpm-estimator`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Trigger the Pdpm Estimator for a lead
     * Trigger Pdpm Estimator
     */
    async triggerPdpmEstimator(requestParameters: TriggerPdpmEstimatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerPdpmEstimatorRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing document exclusion term
     * Update document exclusion term
     */
    async updateDocumentExclusionTermRaw(requestParameters: UpdateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentExclusionTerm201Response>> {
        if (requestParameters.termId === null || requestParameters.termId === undefined) {
            throw new runtime.RequiredError('termId','Required parameter requestParameters.termId was null or undefined when calling updateDocumentExclusionTerm.');
        }

        if (requestParameters.updateDocumentExclusionTermRequest === null || requestParameters.updateDocumentExclusionTermRequest === undefined) {
            throw new runtime.RequiredError('updateDocumentExclusionTermRequest','Required parameter requestParameters.updateDocumentExclusionTermRequest was null or undefined when calling updateDocumentExclusionTerm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document-exclusion-terms/{term_id}`.replace(`{${"term_id"}}`, encodeURIComponent(String(requestParameters.termId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentExclusionTermRequestToJSON(requestParameters.updateDocumentExclusionTermRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentExclusionTerm201ResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing document exclusion term
     * Update document exclusion term
     */
    async updateDocumentExclusionTerm(requestParameters: UpdateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentExclusionTerm201Response> {
        const response = await this.updateDocumentExclusionTermRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener lead info
     * Update a screener lead info
     */
    async updateLeadInfoRaw(requestParameters: UpdateLeadInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeadInfo201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updateLeadInfo.');
        }

        if (requestParameters.createLeadInfoRequest === null || requestParameters.createLeadInfoRequest === undefined) {
            throw new runtime.RequiredError('createLeadInfoRequest','Required parameter requestParameters.createLeadInfoRequest was null or undefined when calling updateLeadInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/lead-info/{lead_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeadInfoRequestToJSON(requestParameters.createLeadInfoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeadInfo201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener lead info
     * Update a screener lead info
     */
    async updateLeadInfo(requestParameters: UpdateLeadInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeadInfo201Response> {
        const response = await this.updateLeadInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
