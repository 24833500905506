/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilitySeats } from './FacilitySeats';
import {
    FacilitySeatsFromJSON,
    FacilitySeatsFromJSONTyped,
    FacilitySeatsToJSON,
} from './FacilitySeats';
import type { FacilityTimezone } from './FacilityTimezone';
import {
    FacilityTimezoneFromJSON,
    FacilityTimezoneFromJSONTyped,
    FacilityTimezoneToJSON,
} from './FacilityTimezone';

/**
 * 
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    facility_type?: FacilityFacilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    primary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    state_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    time_zone_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    fax_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    pcc_fac_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    pcc_org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    tax_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    medicare_location_type?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    wage_index_cbsa_or_state_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    deletedAt?: string;
    /**
     * 
     * @type {FacilityTimezone}
     * @memberof Facility
     */
    timezone?: FacilityTimezone;
    /**
     * 
     * @type {FacilitySeats}
     * @memberof Facility
     */
    seats?: FacilitySeats;
    /**
     * 
     * @type {Array<object>}
     * @memberof Facility
     */
    resident_groups?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    screener_no_analysis_facility?: boolean;
}


/**
 * @export
 */
export const FacilityFacilityTypeEnum = {
    SkilledNursing: 'SKILLED_NURSING',
    CriticalCare: 'CRITICAL_CARE',
    HomeHealth: 'HOME_HEALTH',
    AssistedLiving: 'ASSISTED_LIVING',
    LongTermCareHospital: 'LONG_TERM_CARE_HOSPITAL'
} as const;
export type FacilityFacilityTypeEnum = typeof FacilityFacilityTypeEnum[keyof typeof FacilityFacilityTypeEnum];


/**
 * Check if a given object implements the Facility interface.
 */
export function instanceOfFacility(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function FacilityFromJSON(json: any): Facility {
    return FacilityFromJSONTyped(json, false);
}

export function FacilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Facility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'facility_type': !exists(json, 'facility_type') ? undefined : json['facility_type'],
        'primary_phone': !exists(json, 'primary_phone') ? undefined : json['primary_phone'],
        'state_id': !exists(json, 'state_id') ? undefined : json['state_id'],
        'time_zone_id': !exists(json, 'time_zone_id') ? undefined : json['time_zone_id'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'fax_number': !exists(json, 'fax_number') ? undefined : json['fax_number'],
        'pcc_fac_id': !exists(json, 'pcc_fac_id') ? undefined : json['pcc_fac_id'],
        'pcc_org_id': !exists(json, 'pcc_org_id') ? undefined : json['pcc_org_id'],
        'tax_id': !exists(json, 'tax_id') ? undefined : json['tax_id'],
        'medicare_location_type': !exists(json, 'medicare_location_type') ? undefined : json['medicare_location_type'],
        'wage_index_cbsa_or_state_code': !exists(json, 'wage_index_cbsa_or_state_code') ? undefined : json['wage_index_cbsa_or_state_code'],
        'npi': !exists(json, 'npi') ? undefined : json['npi'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
        'timezone': !exists(json, 'timezone') ? undefined : FacilityTimezoneFromJSON(json['timezone']),
        'seats': !exists(json, 'seats') ? undefined : FacilitySeatsFromJSON(json['seats']),
        'resident_groups': !exists(json, 'resident_groups') ? undefined : json['resident_groups'],
        'screener_no_analysis_facility': !exists(json, 'screener_no_analysis_facility') ? undefined : json['screener_no_analysis_facility'],
    };
}

export function FacilityToJSON(value?: Facility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'facility_type': value.facility_type,
        'primary_phone': value.primary_phone,
        'state_id': value.state_id,
        'time_zone_id': value.time_zone_id,
        'address': value.address,
        'fax_number': value.fax_number,
        'pcc_fac_id': value.pcc_fac_id,
        'pcc_org_id': value.pcc_org_id,
        'tax_id': value.tax_id,
        'medicare_location_type': value.medicare_location_type,
        'wage_index_cbsa_or_state_code': value.wage_index_cbsa_or_state_code,
        'npi': value.npi,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'deletedAt': value.deletedAt,
        'timezone': FacilityTimezoneToJSON(value.timezone),
        'seats': FacilitySeatsToJSON(value.seats),
        'resident_groups': value.resident_groups,
        'screener_no_analysis_facility': value.screener_no_analysis_facility,
    };
}

