import React from 'react';
import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemTextProps,
  Stack,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';

import { AvatarS3PhotoKey } from '@/components/ui/Avatar/AvatarS3PhotoKey';
import { useAccessUrlQuery } from '@/hooks/useAccessUrlQuery';
import {
  ReferralSourceCompanyModel,
  ReferralSourceCompanyPayload
} from '@/pages/CRM/models/ReferralSourceCompanyModel';
import theme from '@/theme';

export type LifecycleStep =
  | 'Opportunity'
  | 'Qualified'
  | 'Nurture'
  | 'Relationship';

type ContactInitiator = 'Referral' | 'Community Staff';

export interface ReferralSourceType {
  id: string;
  facility_id: string;
  name: string;
  createdAt: string;
  updatedAt: string | null;
}

export class ReferralPayload {
  id: string;
  facility_id: string;
  first_name: string;
  last_name: string;
  company: string;
  dob: string;
  gender: string;
  cell_phone: string | null;
  office_phone: string | null;
  email: string;
  address: string;
  contact_initiator: ContactInitiator;
  referral_source_company_id: string | null;
  referral_source_type_id: string | null;
  paid: boolean;
  lifecycle_step: LifecycleStep;
  createdAt: string;
  updatedAt: string;
  s3_photo_key: string;
  crm_referral_source_company?: ReferralSourceCompanyPayload;
  crm_referral_source_type?: ReferralSourceType | null;
  next_referral_id?: string | null;
  previous_referral_id?: string | null;
  job_title: string | null;
  contract_details: string | null;
  prospects_total?: number;
  prospects_won?: number;

  constructor(payload: ReferralPayload) {
    Object.assign(this, payload);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get initials(): string {
    return `${this.first_name?.charAt(0)}${this.last_name?.charAt(0)}`;
  }
}

interface RenderListItemParams {
  ListItemTextProps?: ListItemTextProps;
}

export class ReferralModel extends ReferralPayload {
  public declare crm_referral_source_company?: ReferralSourceCompanyModel;

  constructor(payload: ReferralPayload) {
    super(payload);
    if (this.crm_referral_source_company) {
      this.crm_referral_source_company = new ReferralSourceCompanyModel(
        this.crm_referral_source_company
      );
    }
  }

  public getPhoneNumber = (type: 'office' | 'cell'): string => {
    const phoneNumber = type === 'office' ? this.office_phone : this.cell_phone;
    if (phoneNumber === null) {
      return '';
    }
    if (phoneNumber.replace(/[^\d.]/g, '').length > 10) {
      return phoneNumber
        .replace(/[^\d.]/g, '')
        .replace(/^(\d{1})(\d{3})(\d{3})(\d{4}).*/, '+$1 ($2) $3-$4');
    } else {
      return phoneNumber
        .replace(/[^\d.]/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
    }
  };
  public renderLifecycleStepCell = () => {
    return <Chip color="info" label={this.lifecycle_step} />;
  };
  public formatDob = () => {
    return this.dob && dayjs(this.dob).format('ll');
  };
  public renderCompanyCell = (isFacesheet = false) => (
    <Stack
      direction="row"
      gap={0.5}
      sx={{
        ...(isFacesheet && {
          color: 'secondary',
          fontSize: '0.875rem',
          fontWeight: '500'
        })
      }}>
      <span>{this.crm_referral_source_company?.name}</span>
      {this.crm_referral_source_company?.paid && (
        <span>{this.crm_referral_source_company?.renderClass()}</span>
      )}
    </Stack>
  );

  public renderFullNameCell = (): JSX.Element => (
    <Box display="flex" alignItems="center" gap="8px">
      <AvatarS3PhotoKey
        sx={{ height: 32, width: 32, fontSize: '1rem' }}
        s3PhotoKey={this.s3_photo_key}>
        {this.initials}
      </AvatarS3PhotoKey>
      {this.fullName}
    </Box>
  );

  public renderListItem = ({
    ListItemTextProps = {}
  }: RenderListItemParams = {}): JSX.Element => {
    const { data: [{ url }] = [{}] } = useAccessUrlQuery([this.s3_photo_key]);
    return (
      <ListItem disableGutters disablePadding dense>
        <ListItemAvatar>
          <Avatar sx={{ width: '32px', height: '32px' }} src={url}>
            {this.initials}
          </Avatar>
        </ListItemAvatar>
        <ListItemText {...ListItemTextProps} />
      </ListItem>
    );
  };

  public renderDetailsCell = (showNoEmailError = false): JSX.Element => (
    <Box display="flex" alignItems="center" gap="8px">
      <Box>
        <AvatarS3PhotoKey
          sx={{ height: 32, width: 32, fontSize: '1rem' }}
          s3PhotoKey={this.s3_photo_key}>
          {this.initials}
        </AvatarS3PhotoKey>
      </Box>
      <Box display="column">
        <Box display="flex" alignItems="center" gap="8px">
          {this.fullName}
          <Typography
            variant="body2"
            fontStyle={
              this.crm_referral_source_company?.name ? 'normal' : 'italic'
            }>
            {this.crm_referral_source_company?.name
              ? this.crm_referral_source_company.name
              : 'No Company'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color={
              showNoEmailError && !this.email
                ? theme.palette.error.main
                : theme.palette.primary.main
            }>
            {showNoEmailError && !this.email ? 'No Email' : this.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
