/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScreenerBedUnit } from './ScreenerBedUnit';
import {
    ScreenerBedUnitFromJSON,
    ScreenerBedUnitFromJSONTyped,
    ScreenerBedUnitToJSON,
} from './ScreenerBedUnit';

/**
 * 
 * @export
 * @interface ScreenerBedRoom
 */
export interface ScreenerBedRoom {
    /**
     * 
     * @type {string}
     * @memberof ScreenerBedRoom
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerBedRoom
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerBedRoom
     */
    name: string;
    /**
     * 
     * @type {ScreenerBedUnit}
     * @memberof ScreenerBedRoom
     */
    bed_unit: ScreenerBedUnit;
}

/**
 * Check if a given object implements the ScreenerBedRoom interface.
 */
export function instanceOfScreenerBedRoom(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "bed_unit" in value;

    return isInstance;
}

export function ScreenerBedRoomFromJSON(json: any): ScreenerBedRoom {
    return ScreenerBedRoomFromJSONTyped(json, false);
}

export function ScreenerBedRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScreenerBedRoom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'name': json['name'],
        'bed_unit': ScreenerBedUnitFromJSON(json['bed_unit']),
    };
}

export function ScreenerBedRoomToJSON(value?: ScreenerBedRoom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'bed_unit': ScreenerBedUnitToJSON(value.bed_unit),
    };
}

