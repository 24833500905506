/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RelocateScreenerResident200ResponseData } from './RelocateScreenerResident200ResponseData';
import {
    RelocateScreenerResident200ResponseDataFromJSON,
    RelocateScreenerResident200ResponseDataFromJSONTyped,
    RelocateScreenerResident200ResponseDataToJSON,
} from './RelocateScreenerResident200ResponseData';

/**
 * 
 * @export
 * @interface RelocateScreenerResident200Response
 */
export interface RelocateScreenerResident200Response {
    /**
     * 
     * @type {RelocateScreenerResident200ResponseData}
     * @memberof RelocateScreenerResident200Response
     */
    data?: RelocateScreenerResident200ResponseData;
}

/**
 * Check if a given object implements the RelocateScreenerResident200Response interface.
 */
export function instanceOfRelocateScreenerResident200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RelocateScreenerResident200ResponseFromJSON(json: any): RelocateScreenerResident200Response {
    return RelocateScreenerResident200ResponseFromJSONTyped(json, false);
}

export function RelocateScreenerResident200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelocateScreenerResident200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : RelocateScreenerResident200ResponseDataFromJSON(json['data']),
    };
}

export function RelocateScreenerResident200ResponseToJSON(value?: RelocateScreenerResident200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': RelocateScreenerResident200ResponseDataToJSON(value.data),
    };
}

