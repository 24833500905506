import React, { CSSProperties } from 'react';
import { Avatar, Stack, styled, SxProps, Theme } from '@mui/material';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

interface PlaceholderProps extends React.HTMLAttributes<HTMLDivElement> {
  Icon?: React.ElementType;
  header: string;
  text?: string;
  button?: React.ReactNode;
  sx?: SxProps<Theme>;
  avatarSxProps?: SxProps<Theme>;
  headerStyle?: CSSProperties;
  iconSxProps?: SxProps<Theme>;
}

export const Placeholder: React.FC<PlaceholderProps> = ({
  Icon,
  header,
  text,
  sx = {},
  button,
  avatarSxProps,
  headerStyle,
  iconSxProps,
  ...rest
}) => {
  const isMobileViewport = useIsMobileViewport();

  const mergedAvatarSxProps = {
    width: isMobileViewport ? 56 : 76,
    height: isMobileViewport ? 56 : 76,
    background: '#B5C9D6',
    ...avatarSxProps
  };

  return (
    <StyledPlaceholder sx={sx} {...rest} isMobileViewport={isMobileViewport}>
      {Icon && (
        <Avatar sx={mergedAvatarSxProps}>
          <Icon sx={iconSxProps} />
        </Avatar>
      )}
      <Stack className="header-text">
        <span className="header" style={headerStyle}>
          {header}
        </span>
        {text && <span className="text">{text}</span>}
      </Stack>
      {button}
    </StyledPlaceholder>
  );
};

interface StyledPlaceholderProps {
  isMobileViewport?: boolean;
}

export const StyledPlaceholder = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobileViewport'
})<StyledPlaceholderProps>(({ isMobileViewport }) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  color: '#667A86',
  '& .header-text': {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  '& .header': {
    fontSize: isMobileViewport ? '18px' : '22px',
    fontWeight: 500
  },
  '& .text': {
    fontSize: '16px',
    maxWidth: '500px',
    textAlign: 'center'
  },
  '& .MuiAvatar-root': {
    '& .MuiSvgIcon-root': {
      fontSize: isMobileViewport ? '2rem' : '3rem'
    }
  }
}));
