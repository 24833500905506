/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EligibilityRequest } from './EligibilityRequest';
import {
    EligibilityRequestFromJSON,
    EligibilityRequestFromJSONTyped,
    EligibilityRequestToJSON,
} from './EligibilityRequest';
import type { ExacareEligibilityResponse } from './ExacareEligibilityResponse';
import {
    ExacareEligibilityResponseFromJSON,
    ExacareEligibilityResponseFromJSONTyped,
    ExacareEligibilityResponseToJSON,
} from './ExacareEligibilityResponse';

/**
 * 
 * @export
 * @interface EligibilityCheck
 */
export interface EligibilityCheck {
    /**
     * 
     * @type {string}
     * @memberof EligibilityCheck
     */
    check_id: string;
    /**
     * 
     * @type {Date}
     * @memberof EligibilityCheck
     */
    date_executed: Date;
    /**
     * 
     * @type {string}
     * @memberof EligibilityCheck
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilityCheck
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilityCheck
     */
    lead_id: string;
    /**
     * 
     * @type {EligibilityRequest}
     * @memberof EligibilityCheck
     */
    request_object: EligibilityRequest;
    /**
     * 
     * @type {ExacareEligibilityResponse}
     * @memberof EligibilityCheck
     */
    eligibility_view_model?: ExacareEligibilityResponse;
    /**
     * 
     * @type {string}
     * @memberof EligibilityCheck
     */
    coverage_priority: string;
}

/**
 * Check if a given object implements the EligibilityCheck interface.
 */
export function instanceOfEligibilityCheck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "check_id" in value;
    isInstance = isInstance && "date_executed" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "user_name" in value;
    isInstance = isInstance && "lead_id" in value;
    isInstance = isInstance && "request_object" in value;
    isInstance = isInstance && "coverage_priority" in value;

    return isInstance;
}

export function EligibilityCheckFromJSON(json: any): EligibilityCheck {
    return EligibilityCheckFromJSONTyped(json, false);
}

export function EligibilityCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibilityCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'check_id': json['check_id'],
        'date_executed': (new Date(json['date_executed'])),
        'user_id': json['user_id'],
        'user_name': json['user_name'],
        'lead_id': json['lead_id'],
        'request_object': EligibilityRequestFromJSON(json['request_object']),
        'eligibility_view_model': !exists(json, 'eligibility_view_model') ? undefined : ExacareEligibilityResponseFromJSON(json['eligibility_view_model']),
        'coverage_priority': json['coverage_priority'],
    };
}

export function EligibilityCheckToJSON(value?: EligibilityCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'check_id': value.check_id,
        'date_executed': (value.date_executed.toISOString()),
        'user_id': value.user_id,
        'user_name': value.user_name,
        'lead_id': value.lead_id,
        'request_object': EligibilityRequestToJSON(value.request_object),
        'eligibility_view_model': ExacareEligibilityResponseToJSON(value.eligibility_view_model),
        'coverage_priority': value.coverage_priority,
    };
}

