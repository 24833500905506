import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { fetchCRMCalls } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';

import {
  PhoneBookItemModel,
  PhoneBookItemPage,
  PhoneBookItemPayload,
  PhoneBookItemType
} from '../models/PhoneBookItemModel';

export interface FindAllPhoneBookRequestParams {
  facilityId?: string | null;
  itemType?: PhoneBookItemType;
  phoneNumbers?: string;
  resourceId?: string;
  page?: number;
  limit?: number;
  search?: string;
  options?: UseQueryOptions<any>;
  mustIncludePhone?: boolean;
  mustIncludeEmail?: boolean;
}

export const FIND_ALL_PHONE_BOOK_DEFAULT_LIMIT = 20;

const CRM_PHONE_BOOK_FIND_ALL_QUERY_KEY = 'crmPhoneBookFindAll';
const CRM_PHONE_BOOK_FIND_ALL_INFINITE_QUERY_KEY =
  'crmPhoneBookFindAllInfinite';
export const CRM_PHONE_BOOK_FIND_ONE_QUERY_KEY = 'crmPhoneBookFindOne';

export const invalidatePhoneBookQuery = () => {
  queryClient.invalidateQueries([CRM_PHONE_BOOK_FIND_ALL_QUERY_KEY]);
  queryClient.invalidateQueries([CRM_PHONE_BOOK_FIND_ALL_INFINITE_QUERY_KEY]);
  queryClient.invalidateQueries([CRM_PHONE_BOOK_FIND_ONE_QUERY_KEY]);
};

const getSearchParamsFromFindAllParams = (
  params: FindAllPhoneBookRequestParams
) => {
  return {
    facility_id: !params.facilityId ? null : params.facilityId,
    phone_numbers: params.phoneNumbers,
    resource_id: params.resourceId,
    page: params.page,
    limit: params.limit,
    must_include_phone: params.mustIncludePhone,
    must_include_email: params.mustIncludeEmail,
    ...(params.search &&
      params.search.trim() !== '' && {
        search: params.search
      }),
    ...(params.itemType &&
      params.itemType !== PhoneBookItemType.All && {
        item_type: params.itemType
      })
  };
};

export const useCrmPhoneBookQuery = () => {
  return {
    findAll: (params: FindAllPhoneBookRequestParams) =>
      useQuery<PhoneBookItemModel[]>(
        [CRM_PHONE_BOOK_FIND_ALL_QUERY_KEY, params],
        async () => {
          const payload = await fetchCRMCalls.get<PhoneBookItemPage>(
            `/phone-book`,
            {
              searchParams: getSearchParamsFromFindAllParams(params)
            }
          );
          return payload.data.map((payload) => new PhoneBookItemModel(payload));
        },
        {
          ...params.options
        }
      ),
    findAllInfinite: (params: FindAllPhoneBookRequestParams) =>
      useInfiniteQuery<PhoneBookItemPage>(
        [CRM_PHONE_BOOK_FIND_ALL_INFINITE_QUERY_KEY, params],
        async ({ pageParam }) => {
          const searchParams = getSearchParamsFromFindAllParams(params);
          if (pageParam) {
            searchParams.page = pageParam.page + 1;
          } else {
            searchParams.page = 1;
          }
          const payload = await fetchCRMCalls.get<PhoneBookItemPage>(
            `/phone-book`,
            {
              searchParams
            }
          );
          return {
            ...payload,
            data: payload.data.map((payload) => new PhoneBookItemModel(payload))
          };
        },
        {
          getNextPageParam: (lastPage) => {
            if (!lastPage?.hasNextPage) {
              return false;
            }
            return lastPage;
          },
          ...params.options
        }
      ),
    findOne: ({
      phoneNumber,
      facilityId,
      options
    }: {
      phoneNumber?: string;
      facilityId?: string | null;
      options?: UseQueryOptions<PhoneBookItemModel | null, HTTPError>;
    }) =>
      useQuery<PhoneBookItemModel | null, HTTPError>(
        [CRM_PHONE_BOOK_FIND_ONE_QUERY_KEY, { facilityId, phoneNumber }],
        async () => {
          if (!phoneNumber) {
            return null;
          }

          try {
            const response = await fetchCRMCalls.get<PhoneBookItemPayload>(
              `/phone-book/${phoneNumber}`,
              {
                searchParams: {
                  facility_id: facilityId
                }
              }
            );
            return new PhoneBookItemModel(response);
          } catch (error) {
            if (error instanceof HTTPError && error.response?.status === 404) {
              return null;
            }
            throw error; // Throw any other encountered errors
          }
        },
        {
          enabled: !!phoneNumber,
          ...options
        }
      )
  };
};
