/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SourceType = {
    Hospital: 'Hospital',
    HomeCare: 'Home Care',
    Hospice: 'Hospice',
    AssistedLiving: 'Assisted Living',
    Other: 'Other'
} as const;
export type SourceType = typeof SourceType[keyof typeof SourceType];


export function SourceTypeFromJSON(json: any): SourceType {
    return SourceTypeFromJSONTyped(json, false);
}

export function SourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceType {
    return json as SourceType;
}

export function SourceTypeToJSON(value?: SourceType | null): any {
    return value as any;
}

