/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EligibilityCheck } from './EligibilityCheck';
import {
    EligibilityCheckFromJSON,
    EligibilityCheckFromJSONTyped,
    EligibilityCheckToJSON,
} from './EligibilityCheck';

/**
 * 
 * @export
 * @interface GetEligibilityChecks200Response
 */
export interface GetEligibilityChecks200Response {
    /**
     * 
     * @type {Array<EligibilityCheck>}
     * @memberof GetEligibilityChecks200Response
     */
    data?: Array<EligibilityCheck>;
}

/**
 * Check if a given object implements the GetEligibilityChecks200Response interface.
 */
export function instanceOfGetEligibilityChecks200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetEligibilityChecks200ResponseFromJSON(json: any): GetEligibilityChecks200Response {
    return GetEligibilityChecks200ResponseFromJSONTyped(json, false);
}

export function GetEligibilityChecks200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEligibilityChecks200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(EligibilityCheckFromJSON)),
    };
}

export function GetEligibilityChecks200ResponseToJSON(value?: GetEligibilityChecks200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(EligibilityCheckToJSON)),
    };
}

