import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const SourcesPage = loadable(() => import('@/pages/CRM/Screener/Sources/AllSources')); // prettier-ignore
const SingleSourcePage = loadable(() => import("@/pages/CRM/Screener/Sources/SingleSource")); // prettier-ignore

export enum CRMSourcesRoutes {
  CRMSources = '/crm/sources',
  CRMSourcesSingleSource = '/crm/sources/:source_id'
}

export const getCrmSourcesRoutes = (): RouteObject[] => {
  if (!FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)) {
    return [];
  }

  return [
    {
      path: CRMSourcesRoutes.CRMSources,
      element: <SourcesPage />
    },
    {
      path: CRMSourcesRoutes.CRMSourcesSingleSource,
      element: <SingleSourcePage />
    }
  ];
};
