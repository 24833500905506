/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Lead } from './Lead';
import {
    LeadFromJSON,
    LeadFromJSONTyped,
    LeadToJSON,
} from './Lead';
import type { LeadScreenerLeadTagsInnerScreenerTag } from './LeadScreenerLeadTagsInnerScreenerTag';
import {
    LeadScreenerLeadTagsInnerScreenerTagFromJSON,
    LeadScreenerLeadTagsInnerScreenerTagFromJSONTyped,
    LeadScreenerLeadTagsInnerScreenerTagToJSON,
} from './LeadScreenerLeadTagsInnerScreenerTag';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface LeadScreenerLeadTagsInner
 */
export interface LeadScreenerLeadTagsInner {
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    lead_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    tag_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    created_by_user_id?: string;
    /**
     * 
     * @type {LeadScreenerLeadTagsInnerScreenerTag}
     * @memberof LeadScreenerLeadTagsInner
     */
    screener_tag?: LeadScreenerLeadTagsInnerScreenerTag;
    /**
     * 
     * @type {User}
     * @memberof LeadScreenerLeadTagsInner
     */
    user?: User;
    /**
     * 
     * @type {Lead}
     * @memberof LeadScreenerLeadTagsInner
     */
    lead?: Lead;
    /**
     * 
     * @type {string}
     * @memberof LeadScreenerLeadTagsInner
     */
    created_at?: string;
}

/**
 * Check if a given object implements the LeadScreenerLeadTagsInner interface.
 */
export function instanceOfLeadScreenerLeadTagsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LeadScreenerLeadTagsInnerFromJSON(json: any): LeadScreenerLeadTagsInner {
    return LeadScreenerLeadTagsInnerFromJSONTyped(json, false);
}

export function LeadScreenerLeadTagsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadScreenerLeadTagsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'lead_id': !exists(json, 'lead_id') ? undefined : json['lead_id'],
        'tag_id': !exists(json, 'tag_id') ? undefined : json['tag_id'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'created_by_user_id': !exists(json, 'created_by_user_id') ? undefined : json['created_by_user_id'],
        'screener_tag': !exists(json, 'screener_tag') ? undefined : LeadScreenerLeadTagsInnerScreenerTagFromJSON(json['screener_tag']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'lead': !exists(json, 'lead') ? undefined : LeadFromJSON(json['lead']),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function LeadScreenerLeadTagsInnerToJSON(value?: LeadScreenerLeadTagsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'lead_id': value.lead_id,
        'tag_id': value.tag_id,
        'comment': value.comment,
        'created_by_user_id': value.created_by_user_id,
        'screener_tag': LeadScreenerLeadTagsInnerScreenerTagToJSON(value.screener_tag),
        'user': UserToJSON(value.user),
        'lead': LeadToJSON(value.lead),
        'created_at': value.created_at,
    };
}

