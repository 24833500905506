/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalIdentification
 */
export interface AdditionalIdentification {
    /**
     * 
     * @type {string}
     * @memberof AdditionalIdentification
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalIdentification
     */
    identifier_code?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalIdentification
     */
    identifier_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalIdentification
     */
    description?: string;
}

/**
 * Check if a given object implements the AdditionalIdentification interface.
 */
export function instanceOfAdditionalIdentification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdditionalIdentificationFromJSON(json: any): AdditionalIdentification {
    return AdditionalIdentificationFromJSONTyped(json, false);
}

export function AdditionalIdentificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalIdentification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'identifier_code': !exists(json, 'identifier_code') ? undefined : json['identifier_code'],
        'identifier_code_definition': !exists(json, 'identifier_code_definition') ? undefined : json['identifier_code_definition'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AdditionalIdentificationToJSON(value?: AdditionalIdentification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'identifier_code': value.identifier_code,
        'identifier_code_definition': value.identifier_code_definition,
        'description': value.description,
    };
}

