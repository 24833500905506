/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SOffenderRecordsReport } from './SOffenderRecordsReport';
import {
    SOffenderRecordsReportFromJSON,
    SOffenderRecordsReportFromJSONTyped,
    SOffenderRecordsReportToJSON,
} from './SOffenderRecordsReport';

/**
 * 
 * @export
 * @interface GenerateSOffenderRecordsReport200ResponseData
 */
export interface GenerateSOffenderRecordsReport200ResponseData {
    /**
     * 
     * @type {SOffenderRecordsReport}
     * @memberof GenerateSOffenderRecordsReport200ResponseData
     */
    data?: SOffenderRecordsReport;
}

/**
 * Check if a given object implements the GenerateSOffenderRecordsReport200ResponseData interface.
 */
export function instanceOfGenerateSOffenderRecordsReport200ResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenerateSOffenderRecordsReport200ResponseDataFromJSON(json: any): GenerateSOffenderRecordsReport200ResponseData {
    return GenerateSOffenderRecordsReport200ResponseDataFromJSONTyped(json, false);
}

export function GenerateSOffenderRecordsReport200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateSOffenderRecordsReport200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : SOffenderRecordsReportFromJSON(json['data']),
    };
}

export function GenerateSOffenderRecordsReport200ResponseDataToJSON(value?: GenerateSOffenderRecordsReport200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SOffenderRecordsReportToJSON(value.data),
    };
}

