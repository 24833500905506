/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenericBenefit } from './GenericBenefit';
import {
    GenericBenefitFromJSON,
    GenericBenefitFromJSONTyped,
    GenericBenefitToJSON,
} from './GenericBenefit';

/**
 * 
 * @export
 * @interface ProcedureSection
 */
export interface ProcedureSection {
    /**
     * 
     * @type {string}
     * @memberof ProcedureSection
     */
    procedure_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureSection
     */
    procedure_code_definition?: string;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof ProcedureSection
     */
    deductibles?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof ProcedureSection
     */
    coinsurances?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof ProcedureSection
     */
    benefit_descriptions?: Array<GenericBenefit>;
}

/**
 * Check if a given object implements the ProcedureSection interface.
 */
export function instanceOfProcedureSection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcedureSectionFromJSON(json: any): ProcedureSection {
    return ProcedureSectionFromJSONTyped(json, false);
}

export function ProcedureSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcedureSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'procedure_code': !exists(json, 'procedure_code') ? undefined : json['procedure_code'],
        'procedure_code_definition': !exists(json, 'procedure_code_definition') ? undefined : json['procedure_code_definition'],
        'deductibles': !exists(json, 'deductibles') ? undefined : ((json['deductibles'] as Array<any>).map(GenericBenefitFromJSON)),
        'coinsurances': !exists(json, 'coinsurances') ? undefined : ((json['coinsurances'] as Array<any>).map(GenericBenefitFromJSON)),
        'benefit_descriptions': !exists(json, 'benefit_descriptions') ? undefined : ((json['benefit_descriptions'] as Array<any>).map(GenericBenefitFromJSON)),
    };
}

export function ProcedureSectionToJSON(value?: ProcedureSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'procedure_code': value.procedure_code,
        'procedure_code_definition': value.procedure_code_definition,
        'deductibles': value.deductibles === undefined ? undefined : ((value.deductibles as Array<any>).map(GenericBenefitToJSON)),
        'coinsurances': value.coinsurances === undefined ? undefined : ((value.coinsurances as Array<any>).map(GenericBenefitToJSON)),
        'benefit_descriptions': value.benefit_descriptions === undefined ? undefined : ((value.benefit_descriptions as Array<any>).map(GenericBenefitToJSON)),
    };
}

