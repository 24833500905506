/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccPatientResponse
 */
export interface PccPatientResponse {
    /**
     * 
     * @type {string}
     * @memberof PccPatientResponse
     */
    orgUuid?: string;
    /**
     * 
     * @type {number}
     * @memberof PccPatientResponse
     */
    facId?: number;
    /**
     * 
     * @type {number}
     * @memberof PccPatientResponse
     */
    patientId?: number;
    /**
     * 
     * @type {number}
     * @memberof PccPatientResponse
     */
    patientStatus?: number;
}

/**
 * Check if a given object implements the PccPatientResponse interface.
 */
export function instanceOfPccPatientResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PccPatientResponseFromJSON(json: any): PccPatientResponse {
    return PccPatientResponseFromJSONTyped(json, false);
}

export function PccPatientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgUuid': !exists(json, 'orgUuid') ? undefined : json['orgUuid'],
        'facId': !exists(json, 'facId') ? undefined : json['facId'],
        'patientId': !exists(json, 'patientId') ? undefined : json['patientId'],
        'patientStatus': !exists(json, 'patientStatus') ? undefined : json['patientStatus'],
    };
}

export function PccPatientResponseToJSON(value?: PccPatientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgUuid': value.orgUuid,
        'facId': value.facId,
        'patientId': value.patientId,
        'patientStatus': value.patientStatus,
    };
}

