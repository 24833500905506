/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';
import type { RequestInfoProvider } from './RequestInfoProvider';
import {
    RequestInfoProviderFromJSON,
    RequestInfoProviderFromJSONTyped,
    RequestInfoProviderToJSON,
} from './RequestInfoProvider';
import type { RequestInfoSubscriber } from './RequestInfoSubscriber';
import {
    RequestInfoSubscriberFromJSON,
    RequestInfoSubscriberFromJSONTyped,
    RequestInfoSubscriberToJSON,
} from './RequestInfoSubscriber';

/**
 * 
 * @export
 * @interface RequestInfo
 */
export interface RequestInfo {
    /**
     * 
     * @type {RequestInfoProvider}
     * @memberof RequestInfo
     */
    provider?: RequestInfoProvider;
    /**
     * 
     * @type {DateRange}
     * @memberof RequestInfo
     */
    date_range?: DateRange;
    /**
     * 
     * @type {RequestInfoSubscriber}
     * @memberof RequestInfo
     */
    subscriber?: RequestInfoSubscriber;
}

/**
 * Check if a given object implements the RequestInfo interface.
 */
export function instanceOfRequestInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestInfoFromJSON(json: any): RequestInfo {
    return RequestInfoFromJSONTyped(json, false);
}

export function RequestInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : RequestInfoProviderFromJSON(json['provider']),
        'date_range': !exists(json, 'date_range') ? undefined : DateRangeFromJSON(json['date_range']),
        'subscriber': !exists(json, 'subscriber') ? undefined : RequestInfoSubscriberFromJSON(json['subscriber']),
    };
}

export function RequestInfoToJSON(value?: RequestInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': RequestInfoProviderToJSON(value.provider),
        'date_range': DateRangeToJSON(value.date_range),
        'subscriber': RequestInfoSubscriberToJSON(value.subscriber),
    };
}

