import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
  styled
} from '@mui/material';
import { matchSorter } from 'match-sorter';

import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { MedicationTaskPayload } from '@/hooks/useResidentMedications';

export const StyledAlert = styled(Alert)({
  backgroundColor: '#EEF4FA',
  alignItems: 'flex-start',
  fontSize: '14px',
  color: '#364955',
  '.MuiAlert-icon': {
    padding: '12px 0',
    color: '#1DB8F2 !important'
  },
  '.MuiAlertTitle-root': {
    color: '#F38D2F',
    fontSize: '14px'
  },
  '.MuiFormControlLabel-label': {
    fontSize: '14px',
    marginTop: '8px'
  }
});

interface MedicationMatcherAlertProps {
  medicationFrom: string;
  medicationTo: MedicationsPayload | MedicationTaskPayload[] | [];
  handleCheckBoxChange?: (e: boolean | null) => void;
  dialogFormMethods?: UseFormReturn<
    {
      open: '' | 'create' | 'update';
      resident: string | undefined;
      prescription: string;
      isMedicationTaskMatcherConfirmed: null | boolean;
    },
    any
  >;
}

const keys = [
  'DispensableDrugDesc',
  'DispensableDrugTallManDesc',
  'DispensableGenericDesc',
  'DrugNameDesc',
  'GenericDrugNameCodeDesc',
  'RoutedDoseFormGenericDesc',
  'drug_description'
];

function reduceMedicationToPossibleRows(
  medication: MedicationTaskPayload[] | MedicationsPayload
) {
  if (Array.isArray(medication)) {
    return medication?.map(
      (medication: MedicationTaskPayload) => medication?.fdb_dispensable_drug
    );
  }
  return [medication];
}

const MedicationMatcherAlert: React.FC<MedicationMatcherAlertProps> = ({
  medicationFrom,
  medicationTo,
  dialogFormMethods,
  handleCheckBoxChange
}) => {
  const rows = reduceMedicationToPossibleRows(medicationTo);

  // Split first in case there are Medications with multiple names like "Acetaminophen (Tylenol)"
  const terms = medicationFrom?.split(' ');

  const hasMatcher =
    terms?.reduceRight(
      (results, term) => matchSorter(results, term, { keys }),
      rows
    ).length > 0;

  const contains = rows.some((medicationDetail) => {
    return (
      medicationDetail?.DrugNameDesc?.toLowerCase().includes(
        medicationFrom?.toLowerCase()
      ) ||
      medicationFrom
        ?.toLowerCase()
        .includes(medicationDetail?.DrugNameDesc?.toLowerCase())
    );
  });

  React.useEffect(() => {
    if (!hasMatcher && !contains) {
      dialogFormMethods?.setValue('isMedicationTaskMatcherConfirmed', false);
      handleCheckBoxChange?.(false);
    } else {
      dialogFormMethods?.setValue('isMedicationTaskMatcherConfirmed', null);
      handleCheckBoxChange?.(null);
    }
  }, [hasMatcher, contains]);

  if (
    !medicationFrom ||
    medicationFrom === '' ||
    medicationTo === null ||
    (Array.isArray(medicationTo) && !medicationTo?.length)
  ) {
    return null;
  }

  if (!hasMatcher && !contains) {
    return (
      <StyledAlert
        severity="warning"
        sx={{ alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
        <AlertTitle>
          Warning: Prescription and Medication Name Mismatch
        </AlertTitle>
        <br />
        {
          "We've noticed that the prescription you are trying to link does not match the name of the medication you've selected. This could lead to potential medical discrepancies."
        }
        <br />
        {
          'Please ensure that you are linking the correct prescription with the appropriate medication.'
        }
        <strong> If you are certain about this action:</strong>
        <FormControlLabel
          sx={{ fontSize: '14px', '& span': '14px!important' }}
          label="I understand the risk and confirm that I want to link this prescription with the chosen medication."
          control={
            <Checkbox
              data-testid="unmatched-prescription-confirm-checkbox"
              onChange={(e) => {
                dialogFormMethods?.setValue(
                  'isMedicationTaskMatcherConfirmed',
                  e.target.checked as boolean
                );
                handleCheckBoxChange?.(e.target.checked as boolean);
              }}
              inputProps={{
                'aria-label':
                  'I understand the risk and confirm that I want to link this prescription with the chosen medication.'
              }}
            />
          }
        />
      </StyledAlert>
    );
  }
  return null;
};

export default MedicationMatcherAlert;
