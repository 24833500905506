import { TaskExtensionTag } from '@/types/resident-resources';

// Defining a generic object that has these 2 properties plus any other in order to easily
// handle forms of different objects that have a task extension
export interface ObjectWithTaskExtension {
  extensionEnabled?: boolean;
  task_extension?: TaskExtensionPayload;
  [key: string]: any;
}

export enum TaskExtensionType {
  MEDICATION_TASK = 'MEDICATION_TASK',
  CARE_PLAN_ENTRY = 'CARE_PLAN_ENTRY'
}

export enum TaskExtensionQuestionType {
  FreeText = 'free_text',
  Number = 'number',
  Percentage = 'percentage',
  SingleSelect = 'single_select',
  MultipleSelect = 'multiple_select'
}

export interface TaskExtensionAnswer {
  value: string;
}

export class TaskExtensionPayload {
  id: string;
  extension_type: TaskExtensionType;
  medication_task_id: string | null;
  care_plan_entry_id: string | null;
  title: string;
  description: string | null;
  question_type: TaskExtensionQuestionType;
  default_answer_options: TaskExtensionAnswer[] | null;
  default_answers: TaskExtensionAnswer[] | null;
  tag_id: string | null;
  task_extension_tag?: TaskExtensionTag | null;

  constructor(payload: TaskExtensionPayload) {
    Object.assign(this, payload);
  }
}

export const taskExtensionQuestionTypeOptions: {
  label: string;
  value: TaskExtensionQuestionType;
}[] = [
  {
    label: 'Free-Text',
    value: TaskExtensionQuestionType.FreeText
  },
  {
    label: 'Number',
    value: TaskExtensionQuestionType.Number
  },
  {
    label: 'Percentage',
    value: TaskExtensionQuestionType.Percentage
  },
  {
    label: 'Multiple Choice (select one)',
    value: TaskExtensionQuestionType.SingleSelect
  },
  {
    label: 'Multiple Choice (select many)',
    value: TaskExtensionQuestionType.MultipleSelect
  }
];

export class TaskExtensionModel extends TaskExtensionPayload {
  constructor(taskExtension: TaskExtensionPayload) {
    super(taskExtension);
  }

  // This method handles the special case to select default answers for multiple choice questions.
  // For multi-select we use checkboxes, which select multiple options using booleans, so we need
  // to map the array of booleans to an array of answers using the default_answer_options.
  // For single-select we use a radio button, which selects a single option and the value of this will be at default_answers[0]
  // and it will be the index of the single default answer from default_answer_options, so we need to map the value that
  // default_answer_options has in this index
  public static toTaskExtensionDefaultMultiChoiceAnswersPayload(
    defaultAnswerOptions: TaskExtensionAnswer[],
    defaultAnswers: TaskExtensionAnswer[],
    multiChoiceAnswerType:
      | TaskExtensionQuestionType.SingleSelect
      | TaskExtensionQuestionType.MultipleSelect
  ): TaskExtensionAnswer[] | null {
    const defaultAnswerOptionsLength = defaultAnswerOptions.length;
    if (multiChoiceAnswerType === TaskExtensionQuestionType.SingleSelect) {
      const defaultAnswerIndex = parseInt(defaultAnswers[0].value);
      if (
        isNaN(defaultAnswerIndex) ||
        defaultAnswerIndex > defaultAnswerOptionsLength - 1
      ) {
        return null;
      }
      return [defaultAnswerOptions[defaultAnswerIndex]];
    } else {
      const defaultAnswerIndexes = [];
      for (let i = 0; i < defaultAnswers.length; i++) {
        const defaultAnswer = defaultAnswers[i];
        if ((defaultAnswer.value as any) === true) {
          defaultAnswerIndexes.push(i);
        }
      }
      const defaultAnswersFromOptions: TaskExtensionAnswer[] = [];
      for (const defaultAnswerIndex of defaultAnswerIndexes) {
        if (defaultAnswerIndex <= defaultAnswerOptionsLength - 1) {
          defaultAnswersFromOptions.push(
            defaultAnswerOptions[defaultAnswerIndex]
          );
        }
      }
      return defaultAnswersFromOptions.length
        ? defaultAnswersFromOptions
        : null;
    }
  }

  public static toTaskExtensionDefaultAnswersPayload(
    values: TaskExtensionPayload
  ): TaskExtensionAnswer[] | null {
    const defaultAnswers = values.default_answers;
    const questionType = values.question_type;

    if (!defaultAnswers) {
      return null;
    }
    if (
      questionType === TaskExtensionQuestionType.MultipleSelect ||
      questionType === TaskExtensionQuestionType.SingleSelect
    ) {
      return this.toTaskExtensionDefaultMultiChoiceAnswersPayload(
        values.default_answer_options!,
        values.default_answers!,
        questionType
      );
    } else {
      const defaultAnswer = defaultAnswers.find(
        (item) => item.value && item.value.trim() !== ''
      );
      return defaultAnswer ? [defaultAnswer] : null;
    }
  }

  public static toTaskExtensionPayload(
    values: TaskExtensionPayload
  ): TaskExtensionPayload {
    // Only multi choice questions can have default_answer_options
    const defaultAnswerOptions =
      values.question_type === TaskExtensionQuestionType.MultipleSelect ||
      values.question_type === TaskExtensionQuestionType.SingleSelect
        ? values.default_answer_options
        : null;

    const defaultAnswers = this.toTaskExtensionDefaultAnswersPayload(values);

    return {
      ...values,
      default_answer_options:
        defaultAnswerOptions && defaultAnswerOptions.length
          ? defaultAnswerOptions
          : null,
      default_answers: defaultAnswers,
      tag_id:
        values.tag_id && values.tag_id.trim() !== '' ? values.tag_id : null
    };
  }

  public static toTaskExtensionDefaultAnswersForm(
    defaultAnswerOptions: TaskExtensionAnswer[] | null,
    defaultAnswers: TaskExtensionAnswer[] | null,
    questionType: TaskExtensionQuestionType
  ): TaskExtensionAnswer[] {
    // For multi-choice questions, we need to convert the default answers to objects with a boolean value
    // to handle the multi-select with checkboxes and for single select we need to convert the default answer
    // to an object where the value is the index of the selected question from default_answer_options
    if (defaultAnswerOptions && defaultAnswers) {
      if (questionType === TaskExtensionQuestionType.MultipleSelect) {
        const defaultAnswerStringValues = defaultAnswers.map(
          (item) => item.value
        );
        const defaultAnswersToBooleanArray = [];
        for (let i = 0; i < defaultAnswerOptions.length; i++) {
          const defaultAnswerOption = defaultAnswerOptions[i];
          if (defaultAnswerStringValues.includes(defaultAnswerOption.value)) {
            defaultAnswersToBooleanArray.push({ value: true });
          } else {
            defaultAnswersToBooleanArray.push({ value: false });
          }
        }
        return defaultAnswersToBooleanArray as unknown as TaskExtensionAnswer[];
      } else if (questionType === TaskExtensionQuestionType.SingleSelect) {
        return [
          {
            value: defaultAnswerOptions
              .findIndex((item) => item.value === defaultAnswers?.[0].value)
              .toString()
          }
        ];
      }
    }
    if (defaultAnswerOptions && !defaultAnswers) {
      return defaultAnswerOptions.map(() => ({ value: '' }));
    }
    if (!defaultAnswerOptions && defaultAnswers) {
      return defaultAnswers;
    }
    return [{ value: '' }];
  }

  public static toTaskExtensionForm(
    values?: TaskExtensionPayload
  ): Partial<TaskExtensionPayload> {
    const defaultAnswers = values
      ? this.toTaskExtensionDefaultAnswersForm(
          values?.default_answer_options,
          values?.default_answers,
          values?.question_type
        )
      : [{ value: '' }];

    return {
      ...values,
      ...(values?.default_answer_options == null && {
        default_answer_options: [{ value: '' }]
      }),
      default_answers: defaultAnswers
    };
  }
}
