import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import omitBy from 'lodash/omitBy';

import { fetchCRM, fetchCRMReferrals } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { CRMClient } from '@/adapters/schemaClients';
import { AVAILABLE_ROOMS_QUERY_KEY } from '@/components/AvailableRoomsAutocomplete/useAvailableRooms';
import { invalidateCloseConnectionsQuery } from '@/components/ResidentFacesheet/hooks/useResidentCloseConnectionsQuery';
import { invalidateResidentsQueries } from '@/hooks/useResidentQuery';
import {
  CrmLeadInfoDirection,
  CrmLeadInfoHeardType,
  CrmLeadInfoOriginInboundType,
  CrmLeadInfoOriginOutboundType,
  CrmLeadInfoPayload,
  ProspectResidentModel,
  ProspectResidentPayload,
  ProspectResidentUpdatePayload
} from '@/models/ProspectResidentModel';
import { ResidentCloseConnectionPayload } from '@/models/ResidentCloseConnectionModel';
import { invalidateCallsQueries } from '@/pages/CRM/hooks/useCrmCallsQuery';
import { invalidateConversationQueries } from '@/pages/CRM/hooks/useCrmConversationsQuery';
import { EVENT_LOG_FIND_ALL_QUERY_KEY } from '@/pages/CRM/hooks/useCrmEventLogQuery';
import { invalidatePhoneBookQuery } from '@/pages/CRM/hooks/useCrmPhoneBookQuery';
import { CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY } from '@/pages/CRM/hooks/useCrmTaskInstancesQuery';
import { invalidateEmailAddressBookQuery } from '@/pages/CRM/hooks/useEmailAddressBookQuery';
import { invalidateReferralSourceCompanies } from '@/pages/CRM/hooks/useReferralSourceCompaniesQuery';
import { AddProspectResidentFormData } from '@/pages/CRM/ProspectsPage/ProspectResidents/AddProspectResidentForm';
import { ADD_NEW_OPTION } from '@/pages/CRM/ProspectsPage/ProspectResidents/SelectReferralSourceQuickCreate';
import { invalidate as invalidateLeadOriginHeardSurveys } from '@/pages/CRM/ProspectsPage/ProspectResidents/useLeadOriginHeardSurveys';
import {
  BulkUpdateV2OperationRequest,
  ResidentMoveInOperationRequest,
  ResolveDuplicatesOperationRequest,
  UpdateProspectFacilityOperationRequest
} from '@/types/crm';

import { invalidateResidentsQueriesV2 } from './residentsv2/useResidentsQuery';
import { invalidateReferralSources } from './useReferrals';
import { ResidentPayload } from './useResidentFacesheetsQuery';

export const PROSPECTS_OPTIONS_DEFAULT_LIMIT = 10;

export const PROSPECTS_PAGE_SIZE = 20;

export const PROSPECT_RESIDENTS_FIND_ALL_QUERY_KEY =
  'useProspectResidentsFindAll';
export const PROSPECT_RESIDENTS_FIND_ALL_BY_PAGE_QUERY_KEY =
  'useProspectResidentsFindAllByPage';
export const PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY =
  'useProspectResidentsFindOne';
export const PROSPECT_RESIDENTS_FIND_ALL_INFINITE_QUERY_KEY =
  'useProspectResidentsFindAllInfinite';

export const invalidateProspectResidentsQueries = () => {
  queryClient.invalidateQueries([PROSPECT_RESIDENTS_FIND_ALL_QUERY_KEY]);
  queryClient.invalidateQueries([
    PROSPECT_RESIDENTS_FIND_ALL_BY_PAGE_QUERY_KEY
  ]);
  queryClient.invalidateQueries([
    PROSPECT_RESIDENTS_FIND_ALL_INFINITE_QUERY_KEY
  ]);
};

interface BulkUpdateProspectsParams {
  data: Partial<ProspectResidentUpdatePayload>[];
  facilityId: string;
}

async function serializeCrmLeadInfoPayload(
  form: AddProspectResidentFormData,
  type: 'POST' | 'PUT'
): Promise<Partial<ProspectResidentPayload>> {
  const payload = {
    lead_owner_id: form.lead_owner_id,
    current_address: form.current_address || null,
    main_point_of_contact: form.main_point_of_contact || null,
    new_opportunity_date: form.new_opportunity_date || null,
    target_move_in_date: form.target_move_in_date || null,
    temperature: form.temperature || undefined,
    cohabitant_id: form.cohabitant_id || null,
    cohabitant: form.cohabitant || null,
    // If the payload contains a close connection, that means that the prospective resident was unknown
    // so only the information of the close connection will exist and we will keep it. Names will be null
    // for this type of resident
    ...(type === 'POST' &&
      form.close_connection && {
        close_connection: ResidentCloseConnectionPayload.toApi(
          form.close_connection
        )
      })
  } as ProspectResidentPayload & ResidentPayload;

  const leadInfos = {
    direction: form.crm_lead_info.direction,
    inquiry_type: form.crm_lead_info.inquiry_type || null
  } as CrmLeadInfoPayload;

  if (form.crm_lead_info.direction === CrmLeadInfoDirection.Inbound) {
    (leadInfos as CrmLeadInfoPayload).origin_inbound_type = form.crm_lead_info
      .origin_inbound_type as CrmLeadInfoOriginInboundType;
  } else {
    (leadInfos as CrmLeadInfoPayload).origin_outbound_type = form.crm_lead_info
      .origin_outbound_type as CrmLeadInfoOriginOutboundType;
  }

  if (type === 'POST') {
    (leadInfos as CrmLeadInfoPayload).close_connection = form.crm_lead_info
      .close_connection as ResidentCloseConnectionPayload;
  } else if (
    (type === 'PUT' &&
      form.crm_lead_info.direction === CrmLeadInfoDirection.Inbound &&
      form.crm_lead_info.origin_inbound_type ===
        CrmLeadInfoOriginInboundType.RESIDENT_CLOSE_CONNECTION) ||
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Outbound &&
      form.crm_lead_info.origin_outbound_type ===
        CrmLeadInfoOriginOutboundType.RESIDENT_CLOSE_CONNECTION)
  ) {
    (leadInfos as CrmLeadInfoPayload).origin_close_connection_id =
      form.crm_lead_info.origin_close_connection_id;
  }

  if (
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Inbound &&
      form.crm_lead_info.origin_inbound_type ===
        CrmLeadInfoOriginInboundType.REFERRAL_SOURCE) ||
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Outbound &&
      form.crm_lead_info.origin_outbound_type ===
        CrmLeadInfoOriginOutboundType.REFERRAL_SOURCE)
  ) {
    (leadInfos as CrmLeadInfoPayload).origin_referral_source_id =
      form.crm_lead_info.origin_referral_source_id;
    (leadInfos as CrmLeadInfoPayload).origin_referral_source_company_id = null;
  }

  if (
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Inbound &&
      form.crm_lead_info.origin_inbound_type ===
        CrmLeadInfoOriginInboundType.REFERRAL_SOURCE_COMPANY) ||
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Outbound &&
      form.crm_lead_info.origin_outbound_type ===
        CrmLeadInfoOriginOutboundType.REFERRAL_SOURCE_COMPANY)
  ) {
    (leadInfos as CrmLeadInfoPayload).origin_referral_source_company_id =
      form.crm_lead_info.origin_referral_source_company_id;
    // If they select a referral source after selecting company, the
    // relation becomes a referral source instead
    if (form.crm_lead_info.origin_referral_source_id) {
      form.crm_lead_info.direction === CrmLeadInfoDirection.Inbound
        ? ((leadInfos as CrmLeadInfoPayload).origin_inbound_type =
            CrmLeadInfoOriginInboundType.REFERRAL_SOURCE)
        : ((leadInfos as CrmLeadInfoPayload).origin_outbound_type =
            CrmLeadInfoOriginOutboundType.REFERRAL_SOURCE);
      (leadInfos as CrmLeadInfoPayload).origin_referral_source_id =
        form.crm_lead_info.origin_referral_source_id;
      (leadInfos as CrmLeadInfoPayload).origin_referral_source_company_id =
        null;
    }
  }

  // If the origin in/outbound type is a referral source or referral source
  // company then all origin_heard_* fields are not set.
  if (
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Inbound &&
      form.crm_lead_info.origin_inbound_type !==
        CrmLeadInfoOriginInboundType.REFERRAL_SOURCE &&
      form.crm_lead_info.origin_inbound_type !==
        CrmLeadInfoOriginInboundType.REFERRAL_SOURCE_COMPANY) ||
    (form.crm_lead_info.direction === CrmLeadInfoDirection.Outbound &&
      form.crm_lead_info.origin_outbound_type !==
        CrmLeadInfoOriginOutboundType.REFERRAL_SOURCE &&
      form.crm_lead_info.origin_outbound_type !==
        CrmLeadInfoOriginOutboundType.REFERRAL_SOURCE_COMPANY)
  ) {
    switch (form.crm_lead_info.originHeard) {
      case ADD_NEW_OPTION: {
        const { id } = await fetchCRMReferrals.post(
          '/lead-origin-heard-surveys',
          {
            name: form.crm_lead_info.newSurvey,
            facility_id: form.facility_id
          }
        );
        invalidateLeadOriginHeardSurveys();
        (leadInfos as CrmLeadInfoPayload).origin_heard_type =
          CrmLeadInfoHeardType.SURVEY;
        (leadInfos as CrmLeadInfoPayload).origin_heard_survey_id = id;
        break;
      }

      case CrmLeadInfoHeardType.REFERRAL_SOURCE: {
        (leadInfos as CrmLeadInfoPayload).origin_heard_type =
          CrmLeadInfoHeardType.REFERRAL_SOURCE;
        (leadInfos as CrmLeadInfoPayload).origin_heard_referral_source_id =
          form.crm_lead_info.origin_heard_referral_source_id;
        (
          leadInfos as CrmLeadInfoPayload
        ).origin_heard_referral_source_company_id = null;
        break;
      }

      case CrmLeadInfoHeardType.REFERRAL_SOURCE_COMPANY: {
        (leadInfos as CrmLeadInfoPayload).origin_heard_type =
          CrmLeadInfoHeardType.REFERRAL_SOURCE_COMPANY;
        (
          leadInfos as CrmLeadInfoPayload
        ).origin_heard_referral_source_company_id =
          form.crm_lead_info.origin_heard_referral_source_company_id;
        // If they select a referral source after selecting company, the
        // relation becomes a referral source instead
        if (form.crm_lead_info.origin_heard_referral_source_id) {
          (leadInfos as CrmLeadInfoPayload).origin_heard_type =
            CrmLeadInfoHeardType.REFERRAL_SOURCE;
          (leadInfos as CrmLeadInfoPayload).origin_heard_referral_source_id =
            form.crm_lead_info.origin_heard_referral_source_id;
          (
            leadInfos as CrmLeadInfoPayload
          ).origin_heard_referral_source_company_id = null;
        }
        break;
      }

      case CrmLeadInfoHeardType.VIA_OUTBOUND: {
        (leadInfos as CrmLeadInfoPayload).origin_heard_type =
          CrmLeadInfoHeardType.VIA_OUTBOUND;
        break;
      }

      // If not specified, then the user must have chosen a survey
      default: {
        (leadInfos as CrmLeadInfoPayload).origin_heard_type =
          CrmLeadInfoHeardType.SURVEY;
        (leadInfos as CrmLeadInfoPayload).origin_heard_survey_id = form
          .crm_lead_info.originHeard as string;
        break;
      }
    }
  }

  payload.crm_lead_info = leadInfos;
  payload.is_from_ehr =
    payload.crm_lead_info.direction === CrmLeadInfoDirection.FromEHR;
  if (payload.crm_lead_info.direction === CrmLeadInfoDirection.FromEHR) {
    payload.crm_lead_info = null;
  }
  return payload;
}

interface UseProspectResidentsFindAllParams {
  facilityId?: string | null;
  referralSourceId?: string | null;
  inWaitlist?: boolean;
  options?: UseQueryOptions<ProspectResidentModel[]>;
  search?: string;
  limit?: number;
}

interface UseProspectResidentsFindAllByPageParams {
  facilityId?: string | null;
  inWaitlist?: boolean;
  options?: UseQueryOptions<ProspectResidentPage>;
  page?: number;
  limit?: number;
  stage?: string;
  temperature?: string;
  lostDeal?: boolean;
  search?: string;
  recentlyViewed?: boolean;
}

interface ProspectResidentPage {
  page: number;
  limit: number;
  hasNextPage: boolean;
  data: ProspectResidentModel[];
  total: number;
}

export const useProspectResidents = () => {
  const invalidateOnCreate = () => {
    invalidateProspectResidentsQueries();
    queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
    invalidateReferralSources();
    invalidateReferralSourceCompanies();
    invalidateCallsQueries();
    invalidateConversationQueries();
    invalidatePhoneBookQuery();
    invalidateEmailAddressBookQuery();
    invalidateCloseConnectionsQuery();
  };
  return {
    invalidateOnCreate,
    findAllByPage: ({
      facilityId,
      inWaitlist,
      page,
      limit,
      stage,
      temperature,
      lostDeal,
      search,
      options = {}
    }: UseProspectResidentsFindAllByPageParams) =>
      useQuery<ProspectResidentPage>({
        queryKey: [
          PROSPECT_RESIDENTS_FIND_ALL_BY_PAGE_QUERY_KEY,
          {
            inWaitlist,
            facilityId,
            page,
            limit,
            stage,
            temperature,
            lostDeal,
            search
          }
        ],
        queryFn: async () => {
          const payload = await fetchCRM.get<ProspectResidentPage>(
            '/resident-infos',
            {
              searchParams: {
                facility_id: facilityId,
                in_waitlist: inWaitlist,
                page,
                limit,
                stage,
                temperature,
                lost_deal: lostDeal,
                search
              }
            }
          );
          return {
            ...payload,
            data: payload.data.map(
              (payload) => new ProspectResidentModel(payload)
            )
          };
        },
        ...options
      }),
    findAllInfinite: (
      {
        facilityId,
        inWaitlist,
        search,
        limit,
        lostDeal = false,
        recentlyViewed = false
      }: UseProspectResidentsFindAllByPageParams,
      options: UseInfiniteQueryOptions<ProspectResidentPage> = {}
    ) =>
      useInfiniteQuery<ProspectResidentPage>(
        [
          PROSPECT_RESIDENTS_FIND_ALL_INFINITE_QUERY_KEY,
          { facilityId, inWaitlist, search, limit, recentlyViewed, lostDeal }
        ],
        async ({ pageParam }) => {
          const searchParams = {
            facility_id: facilityId,
            in_waitlist: inWaitlist,
            page: pageParam ? parseInt(pageParam.page) + 1 : 1,
            search,
            limit,
            lost_deal: lostDeal,
            recently_viewed: recentlyViewed
          };
          const payload = await fetchCRM.get<ProspectResidentPage>(
            '/resident-infos',
            {
              searchParams
            }
          );
          return {
            ...payload,
            data: payload.data.map(
              (payload) => new ProspectResidentModel(payload)
            )
          };
        },
        {
          getNextPageParam: (lastPage) => {
            if (!lastPage?.hasNextPage) {
              return false;
            }
            return lastPage;
          },
          ...options
        }
      ),
    findAll: ({
      facilityId,
      inWaitlist = false,
      referralSourceId,
      search,
      limit,
      options = {}
    }: UseProspectResidentsFindAllParams) =>
      useQuery<ProspectResidentModel[]>({
        queryKey: [
          PROSPECT_RESIDENTS_FIND_ALL_QUERY_KEY,
          { facilityId, inWaitlist, referralSourceId, search, limit }
        ],
        queryFn: async () => {
          const payloads = await fetchCRM.get<ProspectResidentPayload[]>(
            '/resident-infos',
            {
              searchParams: {
                facility_id: facilityId,
                in_waitlist: inWaitlist,
                referral_source_id: referralSourceId,
                search,
                limit
              }
            }
          );
          return payloads.map((payload) => new ProspectResidentModel(payload));
        },
        ...options
      }),
    findOne: (
      id?: string,
      options: UseQueryOptions<ProspectResidentModel> = {},
      addToRecentlyViewed?: boolean
    ) =>
      useQuery<ProspectResidentModel>(
        [PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY, id, addToRecentlyViewed],
        async () => {
          const payload = await fetchCRM.get<ProspectResidentPayload>(
            `/resident-infos/${id}`,
            {
              searchParams: {
                ...(addToRecentlyViewed && {
                  add_to_recently_viewed: addToRecentlyViewed
                })
              }
            }
          );
          return new ProspectResidentModel(payload);
        },
        {
          enabled: !!id,
          ...options
        }
      ),
    mutations: {
      create: useMutation(
        async (form: AddProspectResidentFormData) => {
          const payload = {
            facility_id: form.facility_id,
            first_name: form.first_name,
            last_name: form.last_name,
            dob: form.dob ? form.dob : null,
            phone: form.phone || null,
            email: form.email || null,
            gender: form.gender || null,
            marital_status: form.marital_status || null,
            cohabitant_id: form.cohabitant_id || null,
            cohabitant: form.cohabitant || null
          } as ResidentPayload;

          const restOfPayload = await serializeCrmLeadInfoPayload(form, 'POST');
          return await fetchCRM.post<ProspectResidentPayload>(
            '/resident-infos',
            {
              ...payload,
              ...restOfPayload,
              lifecycle_step: 'Opportunity'
            }
          );
        },
        {
          onSuccess: () => invalidateOnCreate()
        }
      ),
      updateCrmLead: useMutation(
        async (
          form: AddProspectResidentFormData & {
            id: string;
            is_from_ehr?: boolean;
          }
        ) => {
          const payload = await serializeCrmLeadInfoPayload(form, 'PUT');
          return fetchCRM.put<ProspectResidentPayload>(
            `/resident-infos/${form.id}`,
            payload
          );
        },
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            // We need to invalidate residents query as well because
            // this mutation can change the resident's data as well.
            invalidateResidentsQueriesV2();
            invalidateResidentsQueries();
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCloseConnectionsQuery();
          }
        }
      ),
      update: useMutation(
        async (data: Partial<ProspectResidentUpdatePayload>) => {
          return fetchCRM.put<ProspectResidentUpdatePayload>(
            `/resident-infos/${data.id}`,
            omitBy(data, 'id')
          );
        },
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            // We need to invalidate residents query as well because
            // this mutation can change the resident's data as well.
            invalidateResidentsQueriesV2();
            invalidateResidentsQueries();
            queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
            queryClient.invalidateQueries([
              CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY
            ]);
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCallsQueries();
            invalidateEmailAddressBookQuery();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
          }
        }
      ),
      bulkUpdate: useMutation(
        async (params: BulkUpdateProspectsParams) =>
          fetchCRM.put<Partial<ProspectResidentUpdatePayload>[]>(
            `/resident-infos/bulk`,
            params.data,
            {
              searchParams: {
                facility_id: params.facilityId
              }
            }
          ),
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            // Only used for waitlist page, so don't worry about invalidating
            // referral sources or companies
          }
        }
      ),
      updateRoom: useMutation(
        async (params: ResidentMoveInOperationRequest) => {
          return CRMClient.residentMoveIn(params);
        },
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            // We need to invalidate residents query as well because
            // this mutation can change the resident's data as well.
            invalidateResidentsQueriesV2();
            invalidateResidentsQueries();
            queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
            queryClient.invalidateQueries([
              CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY
            ]);
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCallsQueries();
            invalidateEmailAddressBookQuery();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
            queryClient.invalidateQueries([AVAILABLE_ROOMS_QUERY_KEY]);
          }
        }
      ),
      resolveDuplicates: useMutation(
        async (params: ResolveDuplicatesOperationRequest) => {
          return CRMClient.resolveDuplicates(params);
        },
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            invalidateResidentsQueriesV2();
            invalidateResidentsQueries();
            queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
            queryClient.invalidateQueries([
              CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY
            ]);
          }
        }
      ),
      bulkEdit: useMutation(
        async (params: BulkUpdateV2OperationRequest) =>
          CRMClient.bulkUpdateV2(params),
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            invalidateResidentsQueriesV2();
            invalidateResidentsQueries();
            queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
            queryClient.invalidateQueries([
              CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY
            ]);
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCallsQueries();
            invalidateEmailAddressBookQuery();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
          }
        }
      ),
      updateProspectFacility: useMutation(
        async (params: UpdateProspectFacilityOperationRequest) =>
          CRMClient.updateProspectFacility(params),
        {
          onSuccess: () => {
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
            invalidateResidentsQueriesV2();
            invalidateResidentsQueries();
          }
        }
      )
    }
  };
};
