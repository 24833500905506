/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacilitySeats
 */
export interface FacilitySeats {
    /**
     * 
     * @type {number}
     * @memberof FacilitySeats
     */
    rooms?: number;
    /**
     * 
     * @type {number}
     * @memberof FacilitySeats
     */
    residents?: number;
}

/**
 * Check if a given object implements the FacilitySeats interface.
 */
export function instanceOfFacilitySeats(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FacilitySeatsFromJSON(json: any): FacilitySeats {
    return FacilitySeatsFromJSONTyped(json, false);
}

export function FacilitySeatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilitySeats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rooms': !exists(json, 'rooms') ? undefined : json['rooms'],
        'residents': !exists(json, 'residents') ? undefined : json['residents'],
    };
}

export function FacilitySeatsToJSON(value?: FacilitySeats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rooms': value.rooms,
        'residents': value.residents,
    };
}

