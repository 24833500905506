import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

// TODO: Discover why this isn't wrapped in a loadable component
import MedicationInventoryHistory from '@/pages/Pharmacy/MedicationInventoryHistoryPage';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ProtectedRoute } from '../ProtectedRoute';

const MedicationStockPage = loadable(() => import("@/pages/Pharmacy/MedicationStock")); // prettier-ignore
const PharmacyMedicationStock = loadable(() => import("@/pages/Pharmacy/MedicationStock")); // prettier-ignore
const PharmacySettings = loadable(() => import("@/pages/Pharmacy/PharmacySettings")); // prettier-ignore

export enum PharmacyRoutes {
  MedicationStock = '/medication-stock',
  PharmacyMedicationStock = '/pharmacy/medication-stock',
  PharmacyMedicationView = '/pharmacy/prescription-view',
  PharmacyMedicationViewV2 = '/pharmacy/view-prescriptions',
  PharmacyPrescriptionOrders = '/pharmacy/prescription-orders',
  PrescriptionDetails = '/pharmacy/messages/prescription-details/:prescription_id',
  Pharmacy = '/pharmacy',
  PharmacyProfile = '/pharmacy/profile',
  PharmacyViewPage = '/pharmacy/pharmacies',
  PharmacyMessages = '/pharmacy/:pharmacy_id/messages',
  PharmacySetting = '/pharmacy/:pharmacy_id/settings',
  PharmacyMedicationInventoryHistory = '/pharmacy/medication-inventory-history'
}

export const getEhrPharmacyRoutes = (): RouteObject[] => {
  return [
    {
      path: PharmacyRoutes.MedicationStock,
      element: <MedicationStockPage />
    },
    {
      path: PharmacyRoutes.PharmacyMedicationInventoryHistory,
      element: <MedicationInventoryHistory />
    },
    ...(FeatureFlagService.isEnabled(ExacareFeature.PHARMACY)
      ? [
          {
            path: PharmacyRoutes.Pharmacy,
            element: <ProtectedRoute component={PharmacySettings} role="L4" />,
            children: [
              {
                path: PharmacyRoutes.PharmacyMedicationStock,
                element: <PharmacyMedicationStock />
              }
            ]
          }
        ]
      : [])
  ];
};
