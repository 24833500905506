/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {Date}
     * @memberof DateRange
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateRange
     */
    end?: Date;
    /**
     * 
     * @type {number}
     * @memberof DateRange
     */
    qualifier_code?: number;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    qualifier_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    calendar_year?: string;
}

/**
 * Check if a given object implements the DateRange interface.
 */
export function instanceOfDateRange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DateRangeFromJSON(json: any): DateRange {
    return DateRangeFromJSONTyped(json, false);
}

export function DateRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'qualifier_code': !exists(json, 'qualifier_code') ? undefined : json['qualifier_code'],
        'qualifier_code_definition': !exists(json, 'qualifier_code_definition') ? undefined : json['qualifier_code_definition'],
        'calendar_year': !exists(json, 'calendar_year') ? undefined : json['calendar_year'],
    };
}

export function DateRangeToJSON(value?: DateRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start === undefined ? undefined : (value.start.toISOString().substring(0,10)),
        'end': value.end === undefined ? undefined : (value.end.toISOString().substring(0,10)),
        'qualifier_code': value.qualifier_code,
        'qualifier_code_definition': value.qualifier_code_definition,
        'calendar_year': value.calendar_year,
    };
}

