/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * 
 * @export
 * @interface RelatedEntity
 */
export interface RelatedEntity {
    /**
     * 
     * @type {string}
     * @memberof RelatedEntity
     */
    type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedEntity
     */
    type_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedEntity
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedEntity
     */
    last_or_org_name?: string;
    /**
     * 
     * @type {Address}
     * @memberof RelatedEntity
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof RelatedEntity
     */
    provider_identifier_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedEntity
     */
    provider_identifier?: string;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof RelatedEntity
     */
    contacts?: Array<Contact>;
}

/**
 * Check if a given object implements the RelatedEntity interface.
 */
export function instanceOfRelatedEntity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RelatedEntityFromJSON(json: any): RelatedEntity {
    return RelatedEntityFromJSONTyped(json, false);
}

export function RelatedEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type_code': !exists(json, 'type_code') ? undefined : json['type_code'],
        'type_code_definition': !exists(json, 'type_code_definition') ? undefined : json['type_code_definition'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_or_org_name': !exists(json, 'last_or_org_name') ? undefined : json['last_or_org_name'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'provider_identifier_code_definition': !exists(json, 'provider_identifier_code_definition') ? undefined : json['provider_identifier_code_definition'],
        'provider_identifier': !exists(json, 'provider_identifier') ? undefined : json['provider_identifier'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(ContactFromJSON)),
    };
}

export function RelatedEntityToJSON(value?: RelatedEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_code': value.type_code,
        'type_code_definition': value.type_code_definition,
        'first_name': value.first_name,
        'last_or_org_name': value.last_or_org_name,
        'address': AddressToJSON(value.address),
        'provider_identifier_code_definition': value.provider_identifier_code_definition,
        'provider_identifier': value.provider_identifier,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(ContactToJSON)),
    };
}

