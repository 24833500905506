/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacilitySettingsSettings
 */
export interface FacilitySettingsSettings {
    /**
     * 
     * @type {boolean}
     * @memberof FacilitySettingsSettings
     */
    is_insurance_eligibility_check_enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacilitySettingsSettings
     */
    pdpm_estimator: FacilitySettingsSettingsPdpmEstimatorEnum;
}


/**
 * @export
 */
export const FacilitySettingsSettingsPdpmEstimatorEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    EnabledAuto: 'enabled_auto'
} as const;
export type FacilitySettingsSettingsPdpmEstimatorEnum = typeof FacilitySettingsSettingsPdpmEstimatorEnum[keyof typeof FacilitySettingsSettingsPdpmEstimatorEnum];


/**
 * Check if a given object implements the FacilitySettingsSettings interface.
 */
export function instanceOfFacilitySettingsSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "is_insurance_eligibility_check_enabled" in value;
    isInstance = isInstance && "pdpm_estimator" in value;

    return isInstance;
}

export function FacilitySettingsSettingsFromJSON(json: any): FacilitySettingsSettings {
    return FacilitySettingsSettingsFromJSONTyped(json, false);
}

export function FacilitySettingsSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilitySettingsSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_insurance_eligibility_check_enabled': json['is_insurance_eligibility_check_enabled'],
        'pdpm_estimator': json['pdpm_estimator'],
    };
}

export function FacilitySettingsSettingsToJSON(value?: FacilitySettingsSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_insurance_eligibility_check_enabled': value.is_insurance_eligibility_check_enabled,
        'pdpm_estimator': value.pdpm_estimator,
    };
}

