import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';

import { AdmissionStatus } from '@/types/crm-screener';

import { LeadModel } from '../Screener/models/LeadModel';

import {
  CrmTaskInstanceModel,
  CrmTaskInstancePayload
} from './CrmTaskInstanceModel';

export type CrmRelationType =
  | 'USER'
  | 'PROSPECT_RESIDENT'
  | 'PROSPECT_STAFF'
  | 'REFERRAL'
  | 'RESIDENT_CLOSE_CONNECTION'
  | 'LEAD'
  | 'SCREENER_SOURCE'
  | 'SCREENER_SOURCE_CONTACT';

export const CrmScreenerRelationTypes: CrmRelationType[] = [
  'LEAD',
  'SCREENER_SOURCE',
  'SCREENER_SOURCE_CONTACT'
];

export type CrmTaskType =
  | 'To Do'
  | 'Email'
  | 'Text'
  | 'Call'
  | 'Tour'
  | 'Other';
export type CrmTaskPriority = 'Low' | 'Medium' | 'High';

export interface CrmTaskAttachment {
  id?: string;
  task_id?: string;
  name: string;
  s3_key: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

/**
 * Commented values are not supported by the API yet.
 * See https://docs.microsoft.com/en-us/graph/api/resources/patternedrecurrence?view=graph-rest-1.0
 */
export interface PatternedRecurrence {
  pattern?: RecurrencePattern;
  range: RecurrenceRange;
}

interface RecurrencePattern {
  type: RecurrencePatternType;
  interval: number;
  dayOfWeek?: DayOfWeek[];
  //dayOfMonth?: number;
  //monthOfYear?: number;
  //weekOfMonth?: number;
  //index?: number;
}

interface RecurrenceRange {
  type: RecurrenceRangeType;
  startDate: string;
  endDate?: string;
  numberOfOccurrences?: number;
}

export enum RecurrencePatternType {
  //Daily = 'daily',
  Weekly = 'weekly'
  //AbsoluteMonthly = 'absoluteMonthly',
  //RelativeMonthly = 'relativeMonthly',
  //AbsoluteYearly = 'absoluteYearly',
  //RelativeYearly = 'relativeYearly',
}

export enum RecurrenceRangeType {
  EndDate = 'endDate',
  NoEnd = 'noEnd',
  Numbered = 'numbered'
}

export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}

export type CrmReminderType = 'Email' | 'In-App';

export interface CrmTaskReminderPayload {
  reminder_type: CrmReminderType;
  reminder_time_minutes_value: number;
  reminder_time_duration?: number;
  reminder_time_unit?: string;
}

export class CrmTaskPayload {
  id: string;
  facility_id: string;
  relation_id: string;
  relation_type: CrmRelationType;
  name: string;
  description: string | null;
  assigned_to: string; // user.id
  task_type: CrmTaskType | null;
  priority: CrmTaskPriority | null;
  patterned_recurrence: PatternedRecurrence;
  attachments: CrmTaskAttachment[];
  instances: CrmTaskInstancePayload[];
  reminders: CrmTaskReminderPayload[] | null;

  get patternedRecurrenceText(): string {
    return getPatterendRecurrenceText(this.patterned_recurrence);
  }

  constructor(payload: CrmTaskPayload) {
    Object.assign(this, payload);
  }
}

export type CrmTaskForm = CrmTaskPayload & {
  occurred_on: Date;
};

export const getPatterendRecurrenceText = (
  patternedRecurrence: PatternedRecurrence
) => {
  const dateFormat = 'dddd, MMMM D YYYY';
  const { dayOfWeek } = patternedRecurrence?.pattern ?? {};
  const { startDate, endDate } = patternedRecurrence.range;
  return `
      Occurs every 
      ${dayOfWeek?.map((day) => capitalize(day)).join(', ')} at 
      ${dayjs(startDate).format('h:mm A')} starting 
      ${dayjs(startDate).format(dateFormat)} until 
      ${dayjs(endDate).format(dateFormat)}
    `;
};

export class CrmTaskModel extends CrmTaskPayload {
  constructor(payload: CrmTaskPayload) {
    super(payload);
  }

  public static toApi(form: CrmTaskForm): Partial<CrmTaskPayload> {
    form.patterned_recurrence.range.startDate = dayjs(
      form.patterned_recurrence.range.startDate
    ).toISOString();

    const updatedReminders = form.reminders?.map((reminder) => ({
      ...reminder,
      reminder_time_minutes_value: handleMinuteCalculations(reminder)
    }));

    const newData = {
      ...form,
      reminders: updatedReminders,
      task_type: form.task_type || null,
      priority: form.priority || null
    };

    return omit(
      newData,
      'occurred_on',
      'reminders.reminder_time_duration',
      'reminders.reminder_time_unit'
    );
  }
}

export const crmTaskTypeOptions: string[] = [
  'To Do',
  'Email',
  'Text',
  'Call',
  'In-Person Meeting',
  'Video Meeting',
  'Sales Planning',
  'Home Visit',
  'Tour',
  'Other'
];

export const crmTaskPriorityOptions: string[] = ['Low', 'Medium', 'High'];

export const crmTaskReminderSourceOptions: string[] = ['Email', 'In-App'];

export const validateTime = (
  formMethods: UseFormReturn,
  unit: string,
  index: number
) => {
  const reminder = formMethods.getValues(`reminders.${index}`);

  const deadline = dayjs(
    formMethods.getValues(`patterned_recurrence.range.startDate`)
  );

  let reminderTime;
  switch (unit) {
    case 'Mins':
      reminderTime = dayjs(deadline).subtract(
        reminder.reminder_time_duration,
        'minute'
      );
      break;
    case 'Hours':
      reminderTime = dayjs(deadline).subtract(
        reminder.reminder_time_duration,
        'hour'
      );
      break;
    case 'Days':
      reminderTime = dayjs(deadline).subtract(
        reminder.reminder_time_duration,
        'day'
      );
      break;
    default:
      reminderTime = dayjs(deadline);
  }

  if (dayjs().isAfter(reminderTime.add(10, 'minutes'))) {
    return 'Reminder time must be at least 10 minutes after the current time.';
  }

  return true;
};

export const defaultReminder: CrmTaskReminderPayload = {
  reminder_type: 'Email',
  reminder_time_minutes_value: 10,
  reminder_time_duration: 10,
  reminder_time_unit: 'Mins'
};

export const crmTaskReminderTimeOptions: { [key: string]: number[] } = {
  Mins: [10, 20, 30, 40, 50],
  Hours: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23
  ],
  Days: [1, 2, 3, 4, 5, 6]
};

export const crmTaskReminderMultiplicationFactor: { [key: string]: number } = {
  Mins: 1,
  Hours: 60,
  Days: 1440
};

export const handleMinuteCalculations = (reminder: CrmTaskReminderPayload) => {
  return (
    reminder.reminder_time_duration! *
    crmTaskReminderMultiplicationFactor[reminder.reminder_time_unit!]
  );
};

/**
 * @param reminder_time_minutes_value
 * This calculation exists because the backend only returns a numerical value representing
 * the number of minutes before the scheduled_date a reminder should be sent. Extracting
 * of specific readable values in needed.
 * Eg. 120 -> 2 Hours
 * @returns the values needed to populate dropdowns
 */
export const calculateTimeDurationFromMinutes = (
  reminder_time_minutes_value: number
) => {
  for (const unit in crmTaskReminderMultiplicationFactor) {
    const factor = crmTaskReminderMultiplicationFactor[unit];
    if (reminder_time_minutes_value % factor === 0) {
      const duration = reminder_time_minutes_value / factor;
      if (crmTaskReminderTimeOptions[unit].includes(duration)) {
        return { reminder_time_duration: duration, reminder_time_unit: unit };
      }
    }
  }
  return {
    reminder_time_duration: reminder_time_minutes_value,
    reminder_time_unit: 'Mins'
  };
};

export const crmTaskReminderDurationUnitOptions: string[] = [
  'Mins',
  'Hours',
  'Days'
];

type EmailThreadUserRole =
  | 'resident'
  | 'close_connection'
  | 'referral'
  | 'unknown';

export const relationTypeToRoleMapping: Record<
  EmailThreadUserRole,
  CrmRelationType
> = {
  resident: 'PROSPECT_RESIDENT',
  close_connection: 'RESIDENT_CLOSE_CONNECTION',
  referral: 'REFERRAL',
  // TODO: Currently task creation is disabled for unknown roles
  unknown: 'USER'
};

const relationTypeToDisplayMapping: Record<CrmRelationType, string> = {
  PROSPECT_RESIDENT: 'Resident',
  PROSPECT_STAFF: 'Staff',
  RESIDENT_CLOSE_CONNECTION: 'Resident Family Or Friend',
  USER: 'User',
  REFERRAL: 'Referral',
  LEAD: 'Resident/Referral',
  SCREENER_SOURCE: 'Source',
  SCREENER_SOURCE_CONTACT: 'Source Contact'
};

export function getCrmRelationTypeDisplay(
  relationType: CrmRelationType,
  crmTaskInstance?: CrmTaskInstanceModel
): string {
  if (crmTaskInstance?.related_to instanceof LeadModel) {
    const admissionStatus = crmTaskInstance.related_to.admission_status;
    if (admissionStatus === AdmissionStatus.MovedIn) {
      return 'Resident';
    }
    return 'Referral';
  }

  return relationTypeToDisplayMapping[relationType];
}
