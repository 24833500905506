import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { FeatureFlagService } from '@/utils/featureFlagService';
import { ExacareFeature } from '@/utils/featureFlagService';

const BedboardPage = loadable(() => import('@/pages/CRM/Bedboard/BedboardPage')); // prettier-ignore

export enum CRMBedboardRoutes {
  CRMBedboard = '/crm/bedboard'
}

export const getCRMBedboardRoutes = (): RouteObject[] => {
  if (!FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)) {
    return [];
  }
  return [
    {
      path: CRMBedboardRoutes.CRMBedboard,
      element: <BedboardPage />
    }
  ];
};
