import React, { useEffect, useRef } from 'react';
import { Grid, styled, SxProps, TextField, Theme } from '@mui/material';

const StyledPinInput = styled(TextField)({
  input: {
    textAlign: 'center',
    fontSize: '24px'
  },
  'input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  '-moz-appearance': 'textfield'
});

interface ControlledPinInputProps {
  handleInput: (values: string) => any;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  hideInput?: boolean;
}

const ControlledPinInput: React.FC<ControlledPinInputProps> = ({
  handleInput,
  autoFocus = true,
  sx = {},
  hideInput = true
}) => {
  const pin: React.MutableRefObject<Map<number, null | string>> = useRef(
    new Map([
      [0, null],
      [1, null],
      [2, null],
      [3, null]
    ])
  );

  const inputRefZero = useRef<HTMLInputElement>();
  const inputRefOne = useRef<HTMLInputElement>();
  const inputRefTwo = useRef<HTMLInputElement>();
  const inputRefThree = useRef<HTMLInputElement>();

  useEffect(() => {
    autoFocus && inputRefZero.current?.focus();
  }, []);

  const setNextInputFieldFocus = (position: number) => {
    switch (position) {
      case 0:
        inputRefOne.current?.focus();
        break;
      case 1:
        inputRefTwo.current?.focus();
        break;
      case 2:
        inputRefThree.current?.focus();
        break;
      default:
        inputRefZero.current?.focus();
        break;
    }
  };

  const handlePinChange = (position: number, value: string) => {
    const { current } = pin;
    current.set(position, value);
    let values = '';
    for (const char of current.values()) {
      values = values + `${char}`;
    }
    handleInput(values);
    setNextInputFieldFocus(position);
  };

  return (
    <Grid container spacing={1} sx={sx}>
      <Grid item xs={3}>
        <StyledPinInput
          data-testid="pin-input"
          style={{ textAlign: 'center' }}
          variant="outlined"
          type={hideInput ? 'password' : 'number'}
          inputProps={{
            maxLength: 1,
            ref: inputRefZero,
            pattern: '[0-9]*',
            inputMode: 'numeric'
          }}
          onChange={(e) => handlePinChange(0, e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledPinInput
          style={{ textAlign: 'center' }}
          variant="outlined"
          type={hideInput ? 'password' : 'number'}
          inputProps={{
            maxLength: 1,
            pattern: '[0-9]*',
            ref: inputRefOne,
            inputMode: 'numeric'
          }}
          onChange={(e) => handlePinChange(1, e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledPinInput
          style={{ textAlign: 'center' }}
          variant="outlined"
          type={hideInput ? 'password' : 'number'}
          inputProps={{
            maxLength: 1,
            ref: inputRefTwo,
            pattern: '[0-9]*',
            inputMode: 'numeric'
          }}
          onChange={(e) => handlePinChange(2, e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledPinInput
          style={{ textAlign: 'center' }}
          variant="outlined"
          type={hideInput ? 'password' : 'number'}
          inputProps={{
            maxLength: 1,
            ref: inputRefThree,
            pattern: '[0-9]*',
            inputMode: 'numeric'
          }}
          onChange={(e) => handlePinChange(3, e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default ControlledPinInput;
