/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PharmacySection } from './PharmacySection';
import {
    PharmacySectionFromJSON,
    PharmacySectionFromJSONTyped,
    PharmacySectionToJSON,
} from './PharmacySection';

/**
 * 
 * @export
 * @interface Pharmacy
 */
export interface Pharmacy {
    /**
     * 
     * @type {Array<PharmacySection>}
     * @memberof Pharmacy
     */
    sections?: Array<PharmacySection>;
}

/**
 * Check if a given object implements the Pharmacy interface.
 */
export function instanceOfPharmacy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PharmacyFromJSON(json: any): Pharmacy {
    return PharmacyFromJSONTyped(json, false);
}

export function PharmacyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pharmacy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(PharmacySectionFromJSON)),
    };
}

export function PharmacyToJSON(value?: Pharmacy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(PharmacySectionToJSON)),
    };
}

