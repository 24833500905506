import React from 'react';
import { Avatar, Chip } from '@mui/material';

import { OVERDUE_CRM_TASKS_COPY } from '@/constants';

interface CrmTaskCountItem {
  relation_id: string;
  counts: {
    Upcoming: number;
    Overdue: number;
    Completed: number;
    Cancelled: number;
  };
  has_instances_in_the_future: boolean;
}

const themeDetails: {
  color: 'warning' | 'info';
  countBackground: string;
  countColor: string;
} = {
  color: 'info',
  countBackground: '#bdcedb',
  countColor: '#0A1E28'
};

export interface CrmTaskCountPayload {
  items: CrmTaskCountItem[];
}

export class CrmTaskCountModel {
  public incompleteTaskCount = 0; // Includes Upcoming & Overdue
  public map: Map<string, CrmTaskCountItem> = new Map();

  constructor(payload: CrmTaskCountPayload) {
    payload.items.forEach((item) => {
      this.map.set(item.relation_id, item);
      this.incompleteTaskCount += item.counts.Upcoming + item.counts.Overdue;
    });
  }

  public renderDominantStatusChip = (relationId: string): React.ReactNode => {
    const counts = this.map.get(relationId)?.counts;
    const hasInstancesInTheFuture =
      this.map.get(relationId)?.has_instances_in_the_future;
    let count = 0;
    let status = '';

    if (!counts || (counts.Overdue === 0 && counts.Upcoming === 0)) {
      if (hasInstancesInTheFuture) {
        return <Chip color="success" label="No Tasks Today" />;
      } else {
        return <Chip color="error" label="No Next Task" />;
      }
    } else if (counts.Overdue > 0) {
      themeDetails.color = 'warning';
      themeDetails.countBackground = '#fea859';
      themeDetails.countColor = '#FCFEFF';
      count = counts.Overdue;
      status = OVERDUE_CRM_TASKS_COPY;
    } else if (counts.Upcoming > 0) {
      themeDetails.color = 'info';
      themeDetails.countBackground = '#bdcedb';
      themeDetails.countColor = '#0A1E28';
      count = counts.Upcoming;
      status = 'Upcoming';
    }

    return (
      <Chip
        avatar={
          <Avatar
            style={{
              borderRadius: '50%',
              width: 24,
              height: 24,
              background: themeDetails.countBackground,
              color: themeDetails.countColor,
              fontSize: '12px',
              marginBottom: '0'
            }}>
            {count}
          </Avatar>
        }
        color={themeDetails.color}
        label={status}
      />
    );
  };
}
