/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScreenerResident } from './ScreenerResident';
import {
    ScreenerResidentFromJSON,
    ScreenerResidentFromJSONTyped,
    ScreenerResidentToJSON,
} from './ScreenerResident';

/**
 * 
 * @export
 * @interface FindAllResidentsResponse
 */
export interface FindAllResidentsResponse {
    /**
     * 
     * @type {Array<ScreenerResident>}
     * @memberof FindAllResidentsResponse
     */
    data: Array<ScreenerResident>;
    /**
     * 
     * @type {number}
     * @memberof FindAllResidentsResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllResidentsResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllResidentsResponse
     */
    limit: number;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllResidentsResponse
     */
    has_next: boolean;
}

/**
 * Check if a given object implements the FindAllResidentsResponse interface.
 */
export function instanceOfFindAllResidentsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "has_next" in value;

    return isInstance;
}

export function FindAllResidentsResponseFromJSON(json: any): FindAllResidentsResponse {
    return FindAllResidentsResponseFromJSONTyped(json, false);
}

export function FindAllResidentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ScreenerResidentFromJSON)),
        'total': json['total'],
        'page': json['page'],
        'limit': json['limit'],
        'has_next': json['has_next'],
    };
}

export function FindAllResidentsResponseToJSON(value?: FindAllResidentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ScreenerResidentToJSON)),
        'total': value.total,
        'page': value.page,
        'limit': value.limit,
        'has_next': value.has_next,
    };
}

