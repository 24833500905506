/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    number1?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    number1_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    number1_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    number2?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    number2_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    number2_code_definition?: string;
}

/**
 * Check if a given object implements the Contact interface.
 */
export function instanceOfContact(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'number1': !exists(json, 'number1') ? undefined : json['number1'],
        'number1_code': !exists(json, 'number1_code') ? undefined : json['number1_code'],
        'number1_code_definition': !exists(json, 'number1_code_definition') ? undefined : json['number1_code_definition'],
        'number2': !exists(json, 'number2') ? undefined : json['number2'],
        'number2_code': !exists(json, 'number2_code') ? undefined : json['number2_code'],
        'number2_code_definition': !exists(json, 'number2_code_definition') ? undefined : json['number2_code_definition'],
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'number1': value.number1,
        'number1_code': value.number1_code,
        'number1_code_definition': value.number1_code_definition,
        'number2': value.number2,
        'number2_code': value.number2_code,
        'number2_code_definition': value.number2_code_definition,
    };
}

