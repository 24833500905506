/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InsurancePayor } from './InsurancePayor';
import {
    InsurancePayorFromJSON,
    InsurancePayorFromJSONTyped,
    InsurancePayorToJSON,
} from './InsurancePayor';

/**
 * 
 * @export
 * @interface GetExaCarePayerList200Response
 */
export interface GetExaCarePayerList200Response {
    /**
     * 
     * @type {Array<InsurancePayor>}
     * @memberof GetExaCarePayerList200Response
     */
    data?: Array<InsurancePayor>;
}

/**
 * Check if a given object implements the GetExaCarePayerList200Response interface.
 */
export function instanceOfGetExaCarePayerList200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetExaCarePayerList200ResponseFromJSON(json: any): GetExaCarePayerList200Response {
    return GetExaCarePayerList200ResponseFromJSONTyped(json, false);
}

export function GetExaCarePayerList200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExaCarePayerList200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InsurancePayorFromJSON)),
    };
}

export function GetExaCarePayerList200ResponseToJSON(value?: GetExaCarePayerList200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InsurancePayorToJSON)),
    };
}

