import React from 'react';
import { Box, styled, SxProps, Theme } from '@mui/material';
import dayjs from 'dayjs';

import { UserClockAction, useTimeClock } from '@/hooks/useTimeClock';

import { hoursAndMinutesFromDate } from './TimeclockSidebarButton';

interface ClockInCaptureFrameProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  action: UserClockAction;
  date: dayjs.Dayjs;
}

export const ClockInDialogCaptureFrame: React.FC<ClockInCaptureFrameProps> = ({
  children = null,
  sx = {},
  action,
  date
}) => {
  const clockStatus = useTimeClock().queryClockStatus().data;
  let auxillaryText;
  if (action === 'out' && clockStatus?.date) {
    const { hours, minutes } = hoursAndMinutesFromDate(clockStatus.date);
    auxillaryText = `(Time logged this shift: ${hours}hrs ${minutes}min)`;
  }

  return (
    <StyledBox sx={sx}>
      {children}
      <div>
        Clock {action} Time <strong>{date.format('h:mma')}</strong>
        {auxillaryText && <span>{auxillaryText}</span>}
      </div>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  '& div': {
    bottom: 0,
    position: 'absolute',
    width: 'inherit',
    height: 39,
    color: '#FCFEFF',
    fontSize: '0.875rem',
    background: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '14px',
    lineHeight: '39px',
    textTransform: 'capitalize'
  },
  '& span': {
    paddingLeft: '10px',
    fontSize: '0.75rem'
  }
});
