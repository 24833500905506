import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { HTTPError } from 'ky';

import fetchExaCare, {
  fetchResidentResources,
  fetchResidents
} from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { BloodPressureType } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { BLOOD_PRESSURE_VITAL_ID, VITAL_ID_TO_UNIT_MAP } from '@/constants';

export interface VitalType {
  country_id: string;
  format?: string;
  id: string;
  name: string;
  options?: string;
}

export class VitalType {
  static units = (vitalType: VitalType) => {
    return vitalType.format?.replaceAll('#', '');
  };

  static filter = (vitalType: VitalType, vitals: ResidentVital[]) => {
    vitals = vitals.filter((v) => v.vital_type_id == vitalType.id);
    vitals.sort((a, b) => {
      return (
        new Date(a.recorded_at).valueOf() - new Date(b.recorded_at).valueOf()
      );
    });
    return vitals;
  };

  static stringify = (vitalType: VitalType, value: string) => {
    if (vitalType.format && value) {
      let format = vitalType.format;
      value.split(' ').forEach((v) => {
        format = format.replace('#', ` ${v} `);
      });
      return format.replace(/\s+/g, ' ').trim();
    }
    return value;
  };

  static stringifyBloodPressure = (
    vitalType: VitalType,
    residentVital: ResidentVital
  ) => {
    const vitalValues = residentVital.value.split(' ');
    if (vitalValues.every((val) => val !== '0')) {
      return this.stringify(vitalType, residentVital.value);
    }
    if (vitalValues.length < 2) {
      return residentVital.value;
    }
    const unit = VITAL_ID_TO_UNIT_MAP[BLOOD_PRESSURE_VITAL_ID];
    return vitalValues[1] === '0'
      ? `${BloodPressureType.Systolic} ${vitalValues[0]} ${unit}`
      : `${BloodPressureType.Diastolic} ${vitalValues[1]} ${unit}`;
  };

  static getFormattedVitalMeasure = (
    vitalType: VitalType,
    residentVital: ResidentVital
  ) => {
    if (residentVital.vital_type_id === BLOOD_PRESSURE_VITAL_ID) {
      return this.stringifyBloodPressure(vitalType, residentVital);
    } else {
      return this.stringify(vitalType, residentVital.value);
    }
  };

  static toDate = (
    date: dayjs.ConfigType,
    { short = false, timeFromNow = true } = {}
  ) => {
    const day = dayjs(date);
    if (day.isBefore(dayjs().subtract(1, 'week')) || !timeFromNow) {
      return day.format(short ? 'MMM D' : 'MMMM D YYYY');
    } else {
      return day.fromNow(short);
    }
  };

  static toYTickFormat = (vitalType: VitalType, y: number) => {
    if (/height/i.test(vitalType.name)) {
      const ft = Number.parseInt((y / 12) as any as string);
      return VitalType.stringify(vitalType, `${ft} ${y - ft * 12}`);
    }
    return y;
  };
}

export function useVitalTypesQuery() {
  const QUERY_KEY = ['useVitalTypesQuery'];
  const invalidate = () => queryClient.invalidateQueries(QUERY_KEY);
  return {
    invalidate,

    findAll: (
      options: UseQueryOptions<VitalType[], HTTPError, VitalType[]> = {}
    ) => {
      return useQuery(
        QUERY_KEY,
        async () => {
          const vitalTypes = await fetchExaCare.get<VitalType[]>(
            '/vital-types'
          );
          return vitalTypes;
        },
        options as any
      );
    },

    mutations: (options: UseMutationOptions = {}) => ({
      create: useMutation(
        (payload: VitalType) => {
          return fetchExaCare.post('/vital-types', payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      )
    })
  };
}

interface UserShortInfo {
  id: number;
  first_name: string;
  last_name: string;
}

export interface ResidentVital {
  created_by_user_id: string;
  id: string;
  notes?: string;
  recorded_at: string;
  resident_id: string;
  updated_by_user_id?: string;
  value: string;
  vital_type: VitalType;
  vital_type_id: string;
  createdByUser?: UserShortInfo;
  updatedByUser?: UserShortInfo;
  //Optional fields for Blood Pressure vital type
  systolic?: string;
  diastolic?: string;
}

export class ResidentVital {
  static create = (
    vitalType: VitalType,
    residentId?: string
  ): ResidentVital => {
    return {
      recorded_at: new Date().toISOString(),
      vital_type: vitalType,
      vital_type_id: vitalType.id,
      value: '',
      ...(residentId && { resident_id: residentId })
    } as ResidentVital;
  };

  static toYValues = (vital: ResidentVital) => {
    if (vital.vital_type.options) {
      return [vital.value];
    }
    if (/height/i.test(vital.vital_type.name)) {
      const [feet, inch] = vital.value.split(' ');
      return [Number(feet) * 12 + Number(inch)];
    }
    if (/blood pressure/i.test(vital.vital_type.name)) {
      return vital.value.split(' ').map((value) => {
        if (value === '0') {
          return undefined;
        }
        return Number(value);
      });
    }
    return [Number(vital.value)];
  };
}

export function getRecordedByFullName(vital: ResidentVital): string | null {
  const author = vital.updatedByUser || vital.createdByUser;
  if (!author) {
    return null;
  }
  return `${author.first_name} ${author.last_name}`;
}

interface ResidentVitalsQueryParams {
  startDate: string;
  endDate: string;
}

export function useResidentVitalsQuery(residentId: string) {
  const QUERY_KEY = ['useResidentVitalsQuery', residentId];
  const invalidate = () => queryClient.invalidateQueries(QUERY_KEY);
  return {
    invalidate,

    findAll: (
      params: ResidentVitalsQueryParams,
      options: UseQueryOptions = {}
    ) => {
      const { startDate, endDate } = params;

      return useQuery(
        [QUERY_KEY, { startDate, endDate }],
        async () => {
          const vitals = await fetchResidents.get<ResidentVital[]>(
            `/residents/${residentId}/resident-vitals?start_date=${startDate}&end_date=${endDate}`
          );
          return vitals;
        },
        options as any
      );
    },

    create: (options: UseMutationOptions = {}) => {
      return useMutation(
        (payload: ResidentVital) => {
          return fetchResidentResources.post('/resident-vitals', payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      );
    },
    update: (options: UseMutationOptions = {}) => {
      return useMutation(
        (payload: ResidentVital) => {
          return fetchResidentResources.put(
            `/resident-vitals/${payload.id}`,
            payload
          );
        },
        { onSuccess: invalidate, ...(options as any) }
      );
    },
    delete: (options: UseMutationOptions = {}) => {
      return useMutation(
        (payload: ResidentVital) => {
          return fetchResidentResources.delete(
            `/resident-vitals/${payload.id}`
          );
        },
        { onSuccess: invalidate, ...(options as any) }
      );
    }
  };
}
