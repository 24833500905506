import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCRM } from '@/adapters/fetchExaCare';

import { CrmEventLog } from '../models/CrmEventLogModel';
import { CrmRelationType } from '../models/CrmTaskModel';

export const EVENT_LOG_FIND_ALL_QUERY_KEY = 'eventLogFindAll';
export type EventLogFilterValues = {
  relation_type: CrmRelationType;
  relation_id?: string;
  facility_id?: string;
  user_id?: string;
  event_type?: string;
  start_date?: string;
  end_date?: string;
};
export const useEventLogQuery = () => {
  return {
    findAll: (
      filterValues: EventLogFilterValues,
      options: UseQueryOptions<CrmEventLog[]> = {}
    ) =>
      useQuery<CrmEventLog[]>(
        [EVENT_LOG_FIND_ALL_QUERY_KEY, filterValues],
        async () => {
          return await fetchCRM.get<CrmEventLog[]>('event-log', {
            searchParams: filterValues
          });
        },
        options
      )
  };
};
