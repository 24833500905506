/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EligibilityRequest } from './EligibilityRequest';
import {
    EligibilityRequestFromJSON,
    EligibilityRequestFromJSONTyped,
    EligibilityRequestToJSON,
} from './EligibilityRequest';

/**
 * 
 * @export
 * @interface PerformEligibilityCheckRequest
 */
export interface PerformEligibilityCheckRequest {
    /**
     * 
     * @type {EligibilityRequest}
     * @memberof PerformEligibilityCheckRequest
     */
    eligibility_request: EligibilityRequest;
    /**
     * 
     * @type {string}
     * @memberof PerformEligibilityCheckRequest
     */
    coverage_priority: string;
}

/**
 * Check if a given object implements the PerformEligibilityCheckRequest interface.
 */
export function instanceOfPerformEligibilityCheckRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eligibility_request" in value;
    isInstance = isInstance && "coverage_priority" in value;

    return isInstance;
}

export function PerformEligibilityCheckRequestFromJSON(json: any): PerformEligibilityCheckRequest {
    return PerformEligibilityCheckRequestFromJSONTyped(json, false);
}

export function PerformEligibilityCheckRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformEligibilityCheckRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eligibility_request': EligibilityRequestFromJSON(json['eligibility_request']),
        'coverage_priority': json['coverage_priority'],
    };
}

export function PerformEligibilityCheckRequestToJSON(value?: PerformEligibilityCheckRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eligibility_request': EligibilityRequestToJSON(value.eligibility_request),
        'coverage_priority': value.coverage_priority,
    };
}

