import {
  CloseConnectionFlag,
  CloseConnectionProspect,
  CloseConnectionRelation,
  CrmCloseConnectionListEntry,
  RelationType
} from '@/types/crm-campaigns';

export interface CrmCloseConnectionsModelsPage {
  close_connections: Array<CrmCloseConnectionListEntryModel>;
  total_count: number;
}

export const CloseConnectionFlagDisplay: Partial<
  Record<CloseConnectionFlag, string>
> = {
  [CloseConnectionFlag.ChiefPoc]: 'Chief POC',
  [CloseConnectionFlag.BillRecipient]: 'Bill Recipient',
  [CloseConnectionFlag.EmergencyContact]: 'Emergency Contact',
  [CloseConnectionFlag.MedicalPoa]: 'Medical POA',
  [CloseConnectionFlag.Poa]: 'POA',
  [CloseConnectionFlag.NewsMailingsRecipient]: 'News/Mailing Recipient'
};

export class CrmCloseConnectionListEntryModel
  implements CrmCloseConnectionListEntry
{
  public readonly id: string;
  public readonly relation_type: RelationType;
  public readonly relation_id: string;
  public readonly flags: CloseConnectionFlag[];
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly prospect?: CloseConnectionProspect | undefined;
  public readonly relation: CloseConnectionRelation;
  public readonly is_added_to_audience?: boolean | undefined;
  public readonly fullName: string;

  constructor(payload: CrmCloseConnectionListEntry) {
    this.id = payload.id;
    this.relation_type = payload.relation_type;
    this.relation_id = payload.relation_id;
    this.first_name = payload.first_name;
    this.last_name = payload.last_name;
    this.flags = payload.flags;
    this.prospect = payload.prospect;
    this.is_added_to_audience = payload.is_added_to_audience;
    this.relation = payload.relation;
    this.fullName =
      payload.relation.first_name || payload.relation.last_name
        ? `${payload.relation.first_name ?? ''} ${
            payload.relation.last_name ?? ''
          }`
        : 'Unknown';
  }

  public getFullName(): string {
    return this.fullName;
  }

  public getRelationType(): string | null {
    if (!this.relation || !this.relation.type) {
      return null;
    }
    return this.relation.type;
  }

  public getFamilyFriendName(): string {
    if (!this.first_name || !this.last_name) {
      return 'Unknown';
    }
    return `${this.first_name} ${this.last_name}`;
  }
}
