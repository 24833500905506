/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * 
 * @export
 * @interface PrimaryCareProvider
 */
export interface PrimaryCareProvider {
    /**
     * Provider's first name
     * @type {string}
     * @memberof PrimaryCareProvider
     */
    first_name?: string;
    /**
     * Provider's last name or organization name
     * @type {string}
     * @memberof PrimaryCareProvider
     */
    last_or_org_name?: string;
    /**
     * 
     * @type {Address}
     * @memberof PrimaryCareProvider
     */
    address?: Address;
    /**
     * National Provider Identifier
     * @type {string}
     * @memberof PrimaryCareProvider
     */
    npi?: string;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof PrimaryCareProvider
     */
    contacts?: Array<Contact>;
}

/**
 * Check if a given object implements the PrimaryCareProvider interface.
 */
export function instanceOfPrimaryCareProvider(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrimaryCareProviderFromJSON(json: any): PrimaryCareProvider {
    return PrimaryCareProviderFromJSONTyped(json, false);
}

export function PrimaryCareProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryCareProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_or_org_name': !exists(json, 'last_or_org_name') ? undefined : json['last_or_org_name'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'npi': !exists(json, 'npi') ? undefined : json['npi'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(ContactFromJSON)),
    };
}

export function PrimaryCareProviderToJSON(value?: PrimaryCareProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_or_org_name': value.last_or_org_name,
        'address': AddressToJSON(value.address),
        'npi': value.npi,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(ContactToJSON)),
    };
}

