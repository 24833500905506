import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Apartment, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  CircularProgress,
  Fade,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import { useAtom } from 'jotai';

import { queryClient } from '@/adapters/query';
import { StyledNavButtonOutlined } from '@/components/Layout/Sidebar';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom,
  useFacilitiesQuery
} from '@/hooks/useFacilitiesQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import useIsAllFacilitiesLeadsSelected from '@/pages/CRM/Screener/Leads/AllLeads/hooks/useIsAllFacilitiesLeadsSelected';

import FacilitySelectorPopover from './FacilitySelectorPopover';
import { getUserFacility } from './getUserFacilityHelper';

const FacilitySelector = ({ expanded }: { expanded: boolean }) => {
  const { showSnackbar } = useSnackbar();

  const isAllFacilitiesLeadsSelected = useIsAllFacilitiesLeadsSelected();
  const [previousAllFacilitiesSelected, setPreviousAllFacilitiesSelected] =
    useState(isAllFacilitiesLeadsSelected);

  const location = useLocation();
  const [isCRM, setIsCRM] = useState(false);
  const [selectedFacilityId, setSelectedFacilityId] = useAtom(
    selectedFacilityIdAtom
  );
  const currentUser = useCurrentUser().data!;
  const { data: facilitiesResponse, isLoading: isLoadingFacilitiesResponse } =
    useFacilitiesQuery().findAllWithCanSelectAllFlag();
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const onSelectFacility = (facilityId: string) => {
    setSelectedFacilityId(facilityId);
    // Reset all queries except for useCurrentUser to prevent stale data instead
    // of having to invalidate every single query indiviudally
    queryClient.getQueriesData([]).forEach(([queryKey]) => {
      if (queryKey[0] !== 'useCurrentUser') {
        queryClient.resetQueries(queryKey);
      }
    });
    setIsOpen(false);
  };

  const userFacility = useMemo(() => {
    return getUserFacility(
      selectedFacilityId,
      facilitiesResponse,
      currentUser,
      setSelectedFacilityId
    );
  }, [selectedFacilityId, facilitiesResponse, currentUser]);

  useEffect(() => {
    if (location.pathname.startsWith('/crm')) {
      setIsCRM(true);
    } else {
      setIsCRM(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state?.isFirstLogin) {
      onSelectFacility(currentUser!.facility_id);
    }
  }, [location.state]);

  // Detect when "all_facilities" param changes to false or not being present to alert the user about this change
  useEffect(() => {
    if (previousAllFacilitiesSelected && !isAllFacilitiesLeadsSelected) {
      showSnackbar({
        AlertProps: {
          icon: (
            <ErrorOutlineIcon
              sx={{
                color: 'warning'
              }}
              className="MuiAlert-icon"
            />
          )
        },
        severity: 'warning',
        message:
          'All Facilities view is not enabled for this page, your last viewed facility has been selected.'
      });
    }
    setPreviousAllFacilitiesSelected(isAllFacilitiesLeadsSelected);
  }, [isAllFacilitiesLeadsSelected, previousAllFacilitiesSelected]);

  if (isLoadingFacilitiesResponse) {
    return (
      <Stack sx={{ width: '100%', paddingY: 1 }} alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <StyledNavButtonOutlined
        data-testid="community-selector"
        ref={ref}
        disabled={!(currentUser.user_facility_accesses!.length > 1)}
        onClick={() =>
          currentUser.user_facility_accesses!.length > 1 && setIsOpen(true)
        }>
        <ListItemIcon>
          <Apartment />
        </ListItemIcon>
        <Fade in={expanded} easing="cubic-bezier(0.95, 0.05, 0.795, 0.035)">
          <ListItemText
            primary={
              selectedFacilityId == ALL_FACILITIES ||
              isAllFacilitiesLeadsSelected
                ? 'All Communities'
                : userFacility?.name
            }
            secondary={
              selectedFacilityId != ALL_FACILITIES &&
              !isAllFacilitiesLeadsSelected &&
              userFacility?.address
            }
          />
        </Fade>
        {expanded && currentUser.user_facility_accesses!.length > 1 && (
          <>{isOpen ? <ArrowDropUp /> : <ArrowDropDown />}</>
        )}
      </StyledNavButtonOutlined>
      <FacilitySelectorPopover
        user={currentUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        anchorEl={ref.current!}
        onSelectFacility={onSelectFacility}
        selectedFacilityId={selectedFacilityId}
        isCRM={isCRM}
        canSelectAllFacilities={
          facilitiesResponse?.can_select_all_facilities || false
        }
      />
    </>
  );
};

export default FacilitySelector;
