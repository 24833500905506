import { copy } from 'copy-anything';
import * as what from 'is-what';
import ky, { Options } from 'ky';
import { mergeAndConcat } from 'merge-anything';

import { newVersionMiddleware } from './newVersionMiddleware';

export interface FetcherInit extends Omit<Options, 'headers' | 'searchParams'> {
  beforeInit?: ((input: string, init: FetcherInit) => Promise<void>)[];
  /** `File` or `Blob` */
  blob?: Blob;
  /** application/x-www-form-urlencoded */
  form?: Record<string, any>;
  headers: Record<string, any>;
  json?: Record<string, any>;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'HEAD' | 'DELETE';
  prefixUrl?: string;
  /** multipart/form-data */
  multipart?: Record<string, any>;
  retry?: number;
  /** Append search query parameters to input URL */
  searchParams: Record<string, any>;
}

export class Fetcher {
  static get defaults(): FetcherInit {
    return {
      headers: {},
      method: 'GET',
      retry: 0,
      searchParams: {},
      hooks: {
        // HACK: https://github.com/microsoft/playwright/issues/6479#issuecomment-1249973137
        // Needed to make Playwright chromimium able to intercept request body data
        beforeRequest: [
          (request: Request) => {
            request.clone = () => request;
            return request;
          }
        ],
        afterResponse: [
          async (_input, _options, response) => newVersionMiddleware(response)
        ]
      }
    };
  }

  static merge(x: Partial<FetcherInit>, y: Partial<FetcherInit>) {
    return copy(mergeAndConcat(x, y) as any) as FetcherInit;
  }

  static toIterable<T extends Headers | FormData | URLSearchParams>(
    iterable: T,
    input: Record<string, any>
  ) {
    for (const [key, value] of Object.entries(input)) {
      if (what.isNullOrUndefined(value) || what.isNaNValue(value)) {
        continue;
      } else if (what.isArray(value)) {
        value.forEach((v) => iterable.append(key, v));
      } else {
        iterable.set(key, value);
      }
    }
    return iterable;
  }

  constructor(public options = {} as Partial<FetcherInit>) {
    this.options = Fetcher.merge(Fetcher.defaults, options);
  }

  async fetch(input: string, options = {} as Partial<FetcherInit>) {
    const init = Fetcher.merge(this.options, options);

    for (const hook of init.beforeInit ?? []) {
      await hook(input, init);
    }

    if (what.isFullString(init.prefixUrl)) {
      if (input.startsWith('/')) {
        input = input.slice(1);
      }
      if (!init.prefixUrl.endsWith('/')) {
        init.prefixUrl = `${init.prefixUrl}/`;
      }
      input = `${init.prefixUrl}${input}`;
      delete init.prefixUrl;
    }
    const url = new URL(input);
    if (what.isFullObject(init.searchParams)) {
      Fetcher.toIterable(url.searchParams, init.searchParams);
    }
    delete (init as any).searchParams;

    init.headers = Fetcher.toIterable(new Headers(), init.headers);

    if (init.blob) {
      init.body = init.blob;
    }
    if (init.form) {
      init.body = Fetcher.toIterable(new URLSearchParams(), init.form);
    }
    if (init.multipart) {
      init.body = Fetcher.toIterable(new FormData(), init.multipart);
    }

    return (await ky(url.toString(), init)) as Response;
  }

  async arrayBuffer(input: string, options = {} as Partial<FetcherInit>) {
    return await (await this.fetch(input, options)).arrayBuffer();
  }

  async blob(input: string, options = {} as Partial<FetcherInit>) {
    return await (await this.fetch(input, options)).blob();
  }

  async formData(input: string, options = {} as Partial<FetcherInit>) {
    return await (await this.fetch(input, options)).formData();
  }

  async json<T = Record<string, any>>(
    input: string,
    options = {} as Partial<FetcherInit>
  ) {
    const response = await this.fetch(input, options);
    try {
      return (await response.json()) as T;
    } catch {
      return {} as T;
    }
  }

  async text(input: string, options = {} as Partial<FetcherInit>) {
    return await (await this.fetch(input, options)).text();
  }
}
