/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacilityTimezone
 */
export interface FacilityTimezone {
    /**
     * 
     * @type {string}
     * @memberof FacilityTimezone
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityTimezone
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityTimezone
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityTimezone
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityTimezone
     */
    deletedAt?: string;
}

/**
 * Check if a given object implements the FacilityTimezone interface.
 */
export function instanceOfFacilityTimezone(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FacilityTimezoneFromJSON(json: any): FacilityTimezone {
    return FacilityTimezoneFromJSONTyped(json, false);
}

export function FacilityTimezoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityTimezone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
    };
}

export function FacilityTimezoneToJSON(value?: FacilityTimezone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'deletedAt': value.deletedAt,
    };
}

