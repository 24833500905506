// Don't export this from index because components that are rendered by react-router may
// also import below. This would result in "cannot access unintialized variable" error

import { CRMAudienceRoutes } from './getCrmRoutes/getCrmAudienceRoutes';
import { CRMBedboardRoutes } from './getCrmRoutes/getCrmBedboardRoutes';
import { CRMCampaignRoutes } from './getCrmRoutes/getCrmCampaignRoutes';
import { CRMInboxRoutes } from './getCrmRoutes/getCrmInboxRoutes';
import { CRMOutboundCenterRoutes } from './getCrmRoutes/getCrmOutboundCenterRoutes';
import { CRMProspectRoutes } from './getCrmRoutes/getCrmProspectRoutes';
import { CRMReferralSourceRoutes } from './getCrmRoutes/getCrmReferralSourcesRoutes';
import { CRMScreenerResidentsRoutes } from './getCrmRoutes/getCrmScreenerResidentsRoutes';
import { CRMScreenerRoutes } from './getCrmRoutes/getCrmScreenerRoutes';
import { CRMSourcesRoutes } from './getCrmRoutes/getCrmSourcesRoutes';
import { ArchivedResidentRoutes } from './getEhrRoutes/getEhrArchivedResidentRoutes';
import { IncidentRoutes } from './getEhrRoutes/getEhrIncidentRoutes';
import { PharmacyRoutes } from './getEhrRoutes/getEhrPharmacyRoutes';
import { ResidentOverviewRoutes } from './getEhrRoutes/getEhrResidentOverviewRoutes';
import { ResidentRoutes } from './getEhrRoutes/getEhrResidentRoutes';
import { AddStaffRoutes } from './getSharedRoutes/getAddStaffRoutes';
import { AdminHubAssessmentRoutes } from './getSharedRoutes/getAdminHubAssessmentRoutes';
import { AdminHubGeneralRoutes } from './getSharedRoutes/getAdminHubGeneralRoutes';
import { AdminHubPricesProductsRoutes } from './getSharedRoutes/getAdminHubPricesProductRoutes';
import { AdminHubProviderRoutes } from './getSharedRoutes/getAdminHubProviderRoutes';
import { AdminHubStaffOverviewRoutes } from './getSharedRoutes/getAdminHubStaffOverviewRoutes';
import { AdminHubStatementBillingCenterRoutes } from './getSharedRoutes/getAdminHubStatementBillingCenterRoutes';
import { DataCenterRoutes } from './getSharedRoutes/getDataCenterRoutes';
import { StaffRoutes } from './getSharedRoutes/getStaffRoutes';

enum ErrorRoutes {
  Error = '/error'
}

export enum TaskRoutes {
  TasksChronological = '/tasks/chronological',
  TasksByResident = '/tasks/by-resident'
}

enum ProfileRoutes {
  EHRProfile = '/profile',
  EHRProfileShifts = '/profile/shifts',
  Profile = '/crm/profile',
  ProfileShifts = '/crm/profile/shifts'
}

enum SecretRoutes {
  SecretFeatureFlagService = '/secret-feature-flag-service'
}

export const RoutePath = {
  ...ErrorRoutes,
  ...ResidentOverviewRoutes,
  ...ResidentRoutes,
  ...CRMScreenerResidentsRoutes,
  ...ArchivedResidentRoutes,
  ...StaffRoutes,
  ...AddStaffRoutes,
  ...TaskRoutes,
  ...ProfileRoutes,
  ...AdminHubGeneralRoutes,
  ...AdminHubAssessmentRoutes,
  ...AdminHubStatementBillingCenterRoutes,
  ...AdminHubPricesProductsRoutes,
  ...AdminHubProviderRoutes,
  ...AdminHubStaffOverviewRoutes,
  ...IncidentRoutes,
  ...DataCenterRoutes,
  ...CRMProspectRoutes,
  ...CRMOutboundCenterRoutes,
  ...CRMCampaignRoutes,
  ...CRMAudienceRoutes,
  ...CRMInboxRoutes,
  ...CRMReferralSourceRoutes,
  ...CRMSourcesRoutes,
  ...CRMScreenerRoutes,
  ...CRMBedboardRoutes,
  ...PharmacyRoutes,
  ...SecretRoutes
} as const;

export type RoutePath = typeof RoutePath[keyof typeof RoutePath];
