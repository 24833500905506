/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScreenerResident } from './ScreenerResident';
import {
    ScreenerResidentFromJSON,
    ScreenerResidentFromJSONTyped,
    ScreenerResidentToJSON,
} from './ScreenerResident';

/**
 * 
 * @export
 * @interface Occupancy
 */
export interface Occupancy {
    /**
     * 
     * @type {string}
     * @memberof Occupancy
     */
    id: string;
    /**
     * 
     * @type {ScreenerResident}
     * @memberof Occupancy
     */
    resident: ScreenerResident;
    /**
     * 
     * @type {string}
     * @memberof Occupancy
     */
    type: OccupancyTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Occupancy
     */
    start_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof Occupancy
     */
    end_date?: Date;
}


/**
 * @export
 */
export const OccupancyTypeEnum = {
    Booked: 'Booked',
    MovedIn: 'Moved In'
} as const;
export type OccupancyTypeEnum = typeof OccupancyTypeEnum[keyof typeof OccupancyTypeEnum];


/**
 * Check if a given object implements the Occupancy interface.
 */
export function instanceOfOccupancy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "start_date" in value;

    return isInstance;
}

export function OccupancyFromJSON(json: any): Occupancy {
    return OccupancyFromJSONTyped(json, false);
}

export function OccupancyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Occupancy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident': ScreenerResidentFromJSON(json['resident']),
        'type': json['type'],
        'start_date': (new Date(json['start_date'])),
        'end_date': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
    };
}

export function OccupancyToJSON(value?: Occupancy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident': ScreenerResidentToJSON(value.resident),
        'type': value.type,
        'start_date': (value.start_date.toISOString().substring(0,10)),
        'end_date': value.end_date === undefined ? undefined : (value.end_date.toISOString().substring(0,10)),
    };
}

