/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface RequestInfoSubscriber
 */
export interface RequestInfoSubscriber {
    /**
     * Subscriber's first name
     * @type {string}
     * @memberof RequestInfoSubscriber
     */
    first_name?: string;
    /**
     * Subscriber's last name
     * @type {string}
     * @memberof RequestInfoSubscriber
     */
    last_name?: string;
    /**
     * Subscriber's member ID
     * @type {string}
     * @memberof RequestInfoSubscriber
     */
    member_id?: string;
    /**
     * Subscriber's date of birth
     * @type {Date}
     * @memberof RequestInfoSubscriber
     */
    date_of_birth?: Date;
    /**
     * 
     * @type {Address}
     * @memberof RequestInfoSubscriber
     */
    address?: Address;
}

/**
 * Check if a given object implements the RequestInfoSubscriber interface.
 */
export function instanceOfRequestInfoSubscriber(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestInfoSubscriberFromJSON(json: any): RequestInfoSubscriber {
    return RequestInfoSubscriberFromJSONTyped(json, false);
}

export function RequestInfoSubscriberFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestInfoSubscriber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'member_id': !exists(json, 'member_id') ? undefined : json['member_id'],
        'date_of_birth': !exists(json, 'date_of_birth') ? undefined : (new Date(json['date_of_birth'])),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
    };
}

export function RequestInfoSubscriberToJSON(value?: RequestInfoSubscriber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'member_id': value.member_id,
        'date_of_birth': value.date_of_birth === undefined ? undefined : (value.date_of_birth.toISOString()),
        'address': AddressToJSON(value.address),
    };
}

