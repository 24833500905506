/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead } from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead';
import {
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadFromJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadFromJSONTyped,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadToJSON,
} from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead';
import type { FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTag } from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTag';
import {
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTagFromJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTagFromJSONTyped,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTagToJSON,
} from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTag';
import type { FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUser } from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUser';
import {
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUserFromJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUserFromJSONTyped,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUserToJSON,
} from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUser';

/**
 * 
 * @export
 * @interface FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
 */
export interface FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner {
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    lead_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    tag_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    created_by_user_id?: string;
    /**
     * 
     * @type {FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTag}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    screener_tag?: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTag;
    /**
     * 
     * @type {FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUser}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    user?: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUser;
    /**
     * 
     * @type {FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    lead?: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLead;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner
     */
    created_at?: string;
}

/**
 * Check if a given object implements the FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner interface.
 */
export function instanceOfFindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSON(json: any): FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner {
    return FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSONTyped(json, false);
}

export function FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'lead_id': !exists(json, 'lead_id') ? undefined : json['lead_id'],
        'tag_id': !exists(json, 'tag_id') ? undefined : json['tag_id'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'created_by_user_id': !exists(json, 'created_by_user_id') ? undefined : json['created_by_user_id'],
        'screener_tag': !exists(json, 'screener_tag') ? undefined : FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTagFromJSON(json['screener_tag']),
        'user': !exists(json, 'user') ? undefined : FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUserFromJSON(json['user']),
        'lead': !exists(json, 'lead') ? undefined : FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadFromJSON(json['lead']),
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON(value?: FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'lead_id': value.lead_id,
        'tag_id': value.tag_id,
        'comment': value.comment,
        'created_by_user_id': value.created_by_user_id,
        'screener_tag': FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerScreenerTagToJSON(value.screener_tag),
        'user': FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerUserToJSON(value.user),
        'lead': FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerLeadToJSON(value.lead),
        'created_at': value.created_at,
    };
}

