/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalIdentification } from './AdditionalIdentification';
import {
    AdditionalIdentificationFromJSON,
    AdditionalIdentificationFromJSONTyped,
    AdditionalIdentificationToJSON,
} from './AdditionalIdentification';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';
import type { DeliveryPattern } from './DeliveryPattern';
import {
    DeliveryPatternFromJSON,
    DeliveryPatternFromJSONTyped,
    DeliveryPatternToJSON,
} from './DeliveryPattern';
import type { RelatedEntity } from './RelatedEntity';
import {
    RelatedEntityFromJSON,
    RelatedEntityFromJSONTyped,
    RelatedEntityToJSON,
} from './RelatedEntity';
import type { TextMessage } from './TextMessage';
import {
    TextMessageFromJSON,
    TextMessageFromJSONTyped,
    TextMessageToJSON,
} from './TextMessage';

/**
 * 
 * @export
 * @interface GenericBenefit
 */
export interface GenericBenefit {
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    auth_required?: string;
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof GenericBenefit
     */
    date_ranges?: Array<DateRange>;
    /**
     * 
     * @type {number}
     * @memberof GenericBenefit
     */
    monetary_amount?: number;
    /**
     * 
     * @type {Array<DeliveryPattern>}
     * @memberof GenericBenefit
     */
    delivery_patterns?: Array<DeliveryPattern>;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    benefit_time_period_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    benefit_time_period_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    quantity?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    quantity_qualifier_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    quantity_qualifier_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    service_type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    procedure_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    procedure_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    procedure_modifier_1?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    procedure_modifier_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    service_type_code_definition?: string;
    /**
     * 
     * @type {Array<TextMessage>}
     * @memberof GenericBenefit
     */
    text_messages?: Array<TextMessage>;
    /**
     * 
     * @type {Array<RelatedEntity>}
     * @memberof GenericBenefit
     */
    related_entities?: Array<RelatedEntity>;
    /**
     * 
     * @type {Array<AdditionalIdentification>}
     * @memberof GenericBenefit
     */
    additional_identifications?: Array<AdditionalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    benefit_info_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    benefit_info_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    coverage_level_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    coverage_level_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    insurance_description?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    insurance_type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    insurance_type_code_definition?: string;
    /**
     * 
     * @type {number}
     * @memberof GenericBenefit
     */
    percent?: number;
    /**
     * 
     * @type {string}
     * @memberof GenericBenefit
     */
    in_network?: GenericBenefitInNetworkEnum;
}


/**
 * @export
 */
export const GenericBenefitInNetworkEnum = {
    N: 'N',
    U: 'U',
    W: 'W',
    Y: 'Y'
} as const;
export type GenericBenefitInNetworkEnum = typeof GenericBenefitInNetworkEnum[keyof typeof GenericBenefitInNetworkEnum];


/**
 * Check if a given object implements the GenericBenefit interface.
 */
export function instanceOfGenericBenefit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenericBenefitFromJSON(json: any): GenericBenefit {
    return GenericBenefitFromJSONTyped(json, false);
}

export function GenericBenefitFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericBenefit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth_required': !exists(json, 'auth_required') ? undefined : json['auth_required'],
        'date_ranges': !exists(json, 'date_ranges') ? undefined : ((json['date_ranges'] as Array<any>).map(DateRangeFromJSON)),
        'monetary_amount': !exists(json, 'monetary_amount') ? undefined : json['monetary_amount'],
        'delivery_patterns': !exists(json, 'delivery_patterns') ? undefined : ((json['delivery_patterns'] as Array<any>).map(DeliveryPatternFromJSON)),
        'benefit_time_period_code': !exists(json, 'benefit_time_period_code') ? undefined : json['benefit_time_period_code'],
        'benefit_time_period_code_definition': !exists(json, 'benefit_time_period_code_definition') ? undefined : json['benefit_time_period_code_definition'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'quantity_qualifier_code': !exists(json, 'quantity_qualifier_code') ? undefined : json['quantity_qualifier_code'],
        'quantity_qualifier_code_definition': !exists(json, 'quantity_qualifier_code_definition') ? undefined : json['quantity_qualifier_code_definition'],
        'service_type_code': !exists(json, 'service_type_code') ? undefined : json['service_type_code'],
        'procedure_code': !exists(json, 'procedure_code') ? undefined : json['procedure_code'],
        'procedure_code_definition': !exists(json, 'procedure_code_definition') ? undefined : json['procedure_code_definition'],
        'procedure_modifier_1': !exists(json, 'procedure_modifier_1') ? undefined : json['procedure_modifier_1'],
        'procedure_modifier_definition': !exists(json, 'procedure_modifier_definition') ? undefined : json['procedure_modifier_definition'],
        'service_type_code_definition': !exists(json, 'service_type_code_definition') ? undefined : json['service_type_code_definition'],
        'text_messages': !exists(json, 'text_messages') ? undefined : ((json['text_messages'] as Array<any>).map(TextMessageFromJSON)),
        'related_entities': !exists(json, 'related_entities') ? undefined : ((json['related_entities'] as Array<any>).map(RelatedEntityFromJSON)),
        'additional_identifications': !exists(json, 'additional_identifications') ? undefined : ((json['additional_identifications'] as Array<any>).map(AdditionalIdentificationFromJSON)),
        'benefit_info_code': !exists(json, 'benefit_info_code') ? undefined : json['benefit_info_code'],
        'benefit_info_code_definition': !exists(json, 'benefit_info_code_definition') ? undefined : json['benefit_info_code_definition'],
        'coverage_level_code': !exists(json, 'coverage_level_code') ? undefined : json['coverage_level_code'],
        'coverage_level_code_definition': !exists(json, 'coverage_level_code_definition') ? undefined : json['coverage_level_code_definition'],
        'insurance_description': !exists(json, 'insurance_description') ? undefined : json['insurance_description'],
        'insurance_type_code': !exists(json, 'insurance_type_code') ? undefined : json['insurance_type_code'],
        'insurance_type_code_definition': !exists(json, 'insurance_type_code_definition') ? undefined : json['insurance_type_code_definition'],
        'percent': !exists(json, 'percent') ? undefined : json['percent'],
        'in_network': !exists(json, 'in_network') ? undefined : json['in_network'],
    };
}

export function GenericBenefitToJSON(value?: GenericBenefit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth_required': value.auth_required,
        'date_ranges': value.date_ranges === undefined ? undefined : ((value.date_ranges as Array<any>).map(DateRangeToJSON)),
        'monetary_amount': value.monetary_amount,
        'delivery_patterns': value.delivery_patterns === undefined ? undefined : ((value.delivery_patterns as Array<any>).map(DeliveryPatternToJSON)),
        'benefit_time_period_code': value.benefit_time_period_code,
        'benefit_time_period_code_definition': value.benefit_time_period_code_definition,
        'quantity': value.quantity,
        'quantity_qualifier_code': value.quantity_qualifier_code,
        'quantity_qualifier_code_definition': value.quantity_qualifier_code_definition,
        'service_type_code': value.service_type_code,
        'procedure_code': value.procedure_code,
        'procedure_code_definition': value.procedure_code_definition,
        'procedure_modifier_1': value.procedure_modifier_1,
        'procedure_modifier_definition': value.procedure_modifier_definition,
        'service_type_code_definition': value.service_type_code_definition,
        'text_messages': value.text_messages === undefined ? undefined : ((value.text_messages as Array<any>).map(TextMessageToJSON)),
        'related_entities': value.related_entities === undefined ? undefined : ((value.related_entities as Array<any>).map(RelatedEntityToJSON)),
        'additional_identifications': value.additional_identifications === undefined ? undefined : ((value.additional_identifications as Array<any>).map(AdditionalIdentificationToJSON)),
        'benefit_info_code': value.benefit_info_code,
        'benefit_info_code_definition': value.benefit_info_code_definition,
        'coverage_level_code': value.coverage_level_code,
        'coverage_level_code_definition': value.coverage_level_code_definition,
        'insurance_description': value.insurance_description,
        'insurance_type_code': value.insurance_type_code,
        'insurance_type_code_definition': value.insurance_type_code_definition,
        'percent': value.percent,
        'in_network': value.in_network,
    };
}

