/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalIdentification } from './AdditionalIdentification';
import {
    AdditionalIdentificationFromJSON,
    AdditionalIdentificationFromJSONTyped,
    AdditionalIdentificationToJSON,
} from './AdditionalIdentification';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';
import type { RelatedEntity } from './RelatedEntity';
import {
    RelatedEntityFromJSON,
    RelatedEntityFromJSONTyped,
    RelatedEntityToJSON,
} from './RelatedEntity';

/**
 * 
 * @export
 * @interface HospitalSnfStay
 */
export interface HospitalSnfStay {
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof HospitalSnfStay
     */
    date_ranges?: Array<DateRange>;
    /**
     * 
     * @type {Array<RelatedEntity>}
     * @memberof HospitalSnfStay
     */
    related_entities?: Array<RelatedEntity>;
    /**
     * 
     * @type {Array<AdditionalIdentification>}
     * @memberof HospitalSnfStay
     */
    additional_identifications?: Array<AdditionalIdentification>;
}

/**
 * Check if a given object implements the HospitalSnfStay interface.
 */
export function instanceOfHospitalSnfStay(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HospitalSnfStayFromJSON(json: any): HospitalSnfStay {
    return HospitalSnfStayFromJSONTyped(json, false);
}

export function HospitalSnfStayFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalSnfStay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_ranges': !exists(json, 'date_ranges') ? undefined : ((json['date_ranges'] as Array<any>).map(DateRangeFromJSON)),
        'related_entities': !exists(json, 'related_entities') ? undefined : ((json['related_entities'] as Array<any>).map(RelatedEntityFromJSON)),
        'additional_identifications': !exists(json, 'additional_identifications') ? undefined : ((json['additional_identifications'] as Array<any>).map(AdditionalIdentificationFromJSON)),
    };
}

export function HospitalSnfStayToJSON(value?: HospitalSnfStay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_ranges': value.date_ranges === undefined ? undefined : ((value.date_ranges as Array<any>).map(DateRangeToJSON)),
        'related_entities': value.related_entities === undefined ? undefined : ((value.related_entities as Array<any>).map(RelatedEntityToJSON)),
        'additional_identifications': value.additional_identifications === undefined ? undefined : ((value.additional_identifications as Array<any>).map(AdditionalIdentificationToJSON)),
    };
}

