/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenericBenefit } from './GenericBenefit';
import {
    GenericBenefitFromJSON,
    GenericBenefitFromJSONTyped,
    GenericBenefitToJSON,
} from './GenericBenefit';
import type { LTR } from './LTR';
import {
    LTRFromJSON,
    LTRFromJSONTyped,
    LTRToJSON,
} from './LTR';

/**
 * 
 * @export
 * @interface InsuranceSection
 */
export interface InsuranceSection {
    /**
     * 
     * @type {string}
     * @memberof InsuranceSection
     */
    coverage_level?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceSection
     */
    insurance_type?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceSection
     */
    insurance_description?: string;
    /**
     * 
     * @type {LTR}
     * @memberof InsuranceSection
     */
    ltr?: LTR;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    limitations?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    exclusions?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    deductibles?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    out_of_pocket?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    benefit_descriptions?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    coinsurances?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    copayments?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    reserves?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    active_coverages?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    inactive_coverages?: Array<GenericBenefit>;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof InsuranceSection
     */
    non_covered_coverages?: Array<GenericBenefit>;
}

/**
 * Check if a given object implements the InsuranceSection interface.
 */
export function instanceOfInsuranceSection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InsuranceSectionFromJSON(json: any): InsuranceSection {
    return InsuranceSectionFromJSONTyped(json, false);
}

export function InsuranceSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coverage_level': !exists(json, 'coverage_level') ? undefined : json['coverage_level'],
        'insurance_type': !exists(json, 'insurance_type') ? undefined : json['insurance_type'],
        'insurance_description': !exists(json, 'insurance_description') ? undefined : json['insurance_description'],
        'ltr': !exists(json, 'ltr') ? undefined : LTRFromJSON(json['ltr']),
        'limitations': !exists(json, 'limitations') ? undefined : ((json['limitations'] as Array<any>).map(GenericBenefitFromJSON)),
        'exclusions': !exists(json, 'exclusions') ? undefined : ((json['exclusions'] as Array<any>).map(GenericBenefitFromJSON)),
        'deductibles': !exists(json, 'deductibles') ? undefined : ((json['deductibles'] as Array<any>).map(GenericBenefitFromJSON)),
        'out_of_pocket': !exists(json, 'out_of_pocket') ? undefined : ((json['out_of_pocket'] as Array<any>).map(GenericBenefitFromJSON)),
        'benefit_descriptions': !exists(json, 'benefit_descriptions') ? undefined : ((json['benefit_descriptions'] as Array<any>).map(GenericBenefitFromJSON)),
        'coinsurances': !exists(json, 'coinsurances') ? undefined : ((json['coinsurances'] as Array<any>).map(GenericBenefitFromJSON)),
        'copayments': !exists(json, 'copayments') ? undefined : ((json['copayments'] as Array<any>).map(GenericBenefitFromJSON)),
        'reserves': !exists(json, 'reserves') ? undefined : ((json['reserves'] as Array<any>).map(GenericBenefitFromJSON)),
        'active_coverages': !exists(json, 'active_coverages') ? undefined : ((json['active_coverages'] as Array<any>).map(GenericBenefitFromJSON)),
        'inactive_coverages': !exists(json, 'inactive_coverages') ? undefined : ((json['inactive_coverages'] as Array<any>).map(GenericBenefitFromJSON)),
        'non_covered_coverages': !exists(json, 'non_covered_coverages') ? undefined : ((json['non_covered_coverages'] as Array<any>).map(GenericBenefitFromJSON)),
    };
}

export function InsuranceSectionToJSON(value?: InsuranceSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coverage_level': value.coverage_level,
        'insurance_type': value.insurance_type,
        'insurance_description': value.insurance_description,
        'ltr': LTRToJSON(value.ltr),
        'limitations': value.limitations === undefined ? undefined : ((value.limitations as Array<any>).map(GenericBenefitToJSON)),
        'exclusions': value.exclusions === undefined ? undefined : ((value.exclusions as Array<any>).map(GenericBenefitToJSON)),
        'deductibles': value.deductibles === undefined ? undefined : ((value.deductibles as Array<any>).map(GenericBenefitToJSON)),
        'out_of_pocket': value.out_of_pocket === undefined ? undefined : ((value.out_of_pocket as Array<any>).map(GenericBenefitToJSON)),
        'benefit_descriptions': value.benefit_descriptions === undefined ? undefined : ((value.benefit_descriptions as Array<any>).map(GenericBenefitToJSON)),
        'coinsurances': value.coinsurances === undefined ? undefined : ((value.coinsurances as Array<any>).map(GenericBenefitToJSON)),
        'copayments': value.copayments === undefined ? undefined : ((value.copayments as Array<any>).map(GenericBenefitToJSON)),
        'reserves': value.reserves === undefined ? undefined : ((value.reserves as Array<any>).map(GenericBenefitToJSON)),
        'active_coverages': value.active_coverages === undefined ? undefined : ((value.active_coverages as Array<any>).map(GenericBenefitToJSON)),
        'inactive_coverages': value.inactive_coverages === undefined ? undefined : ((value.inactive_coverages as Array<any>).map(GenericBenefitToJSON)),
        'non_covered_coverages': value.non_covered_coverages === undefined ? undefined : ((value.non_covered_coverages as Array<any>).map(GenericBenefitToJSON)),
    };
}

