/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllSourceTags200ResponseDataInner } from './FindAllSourceTags200ResponseDataInner';
import {
    FindAllSourceTags200ResponseDataInnerFromJSON,
    FindAllSourceTags200ResponseDataInnerFromJSONTyped,
    FindAllSourceTags200ResponseDataInnerToJSON,
} from './FindAllSourceTags200ResponseDataInner';

/**
 * 
 * @export
 * @interface CreateSourceTag201Response
 */
export interface CreateSourceTag201Response {
    /**
     * 
     * @type {FindAllSourceTags200ResponseDataInner}
     * @memberof CreateSourceTag201Response
     */
    data?: FindAllSourceTags200ResponseDataInner;
}

/**
 * Check if a given object implements the CreateSourceTag201Response interface.
 */
export function instanceOfCreateSourceTag201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSourceTag201ResponseFromJSON(json: any): CreateSourceTag201Response {
    return CreateSourceTag201ResponseFromJSONTyped(json, false);
}

export function CreateSourceTag201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSourceTag201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FindAllSourceTags200ResponseDataInnerFromJSON(json['data']),
    };
}

export function CreateSourceTag201ResponseToJSON(value?: CreateSourceTag201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FindAllSourceTags200ResponseDataInnerToJSON(value.data),
    };
}

