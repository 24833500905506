import {
  AnalysisResult,
  AnalysisResultCriteriaResultEnum,
  CriteriaQualifier,
  VisualReference
} from '@/types/crm-screener';

export enum AIAnalysisCustomStatus {
  Pass = 'Pass',
  Fail = 'Fail',
  Unknown = 'Human Review Recommended',
  FollowUp = 'Follow-Up',
  Informational = 'Informational',
  Other = 'Other'
}

export class AnalysisResultModel implements AnalysisResult {
  id: string;
  lead_id: string;
  query: string;
  criteria_result: AnalysisResultCriteriaResultEnum;
  reasoning: string;
  date: string;
  qualifier: CriteriaQualifier;
  aiAnalysisCustomStatus: AIAnalysisCustomStatus;
  deleted_at?: string | undefined;
  visual_references?: VisualReference[];

  public readonly searchText: string;

  constructor(payload: AnalysisResult) {
    Object.assign(this, payload);
    let customAIAnalysisStatus;

    switch (true) {
      case this.qualifier === 'Informational':
        customAIAnalysisStatus = AIAnalysisCustomStatus.Informational;
        break;
      case this.qualifier === 'Deal Breaker' && this.criteria_result === 'Pass':
        customAIAnalysisStatus = AIAnalysisCustomStatus.Pass;
        break;
      case this.qualifier === 'Deal Breaker' && this.criteria_result === 'Fail':
        customAIAnalysisStatus = AIAnalysisCustomStatus.Fail;
        break;
      case this.qualifier === 'Deal Breaker' &&
        this.criteria_result === 'Unknown':
        customAIAnalysisStatus = AIAnalysisCustomStatus.Unknown;
        break;
      case this.qualifier === 'Warning' && this.criteria_result === 'Pass':
        customAIAnalysisStatus = AIAnalysisCustomStatus.Pass;
        break;
      case this.qualifier === 'Warning' && this.criteria_result === 'Fail':
        customAIAnalysisStatus = AIAnalysisCustomStatus.FollowUp;
        break;
      case this.qualifier === 'Warning' && this.criteria_result === 'Unknown':
        customAIAnalysisStatus = AIAnalysisCustomStatus.Unknown;
        break;
      default:
        customAIAnalysisStatus = AIAnalysisCustomStatus.Other;
        break;
    }
    this.aiAnalysisCustomStatus = customAIAnalysisStatus;
    this.searchText = [this.query, this.aiAnalysisCustomStatus, this.reasoning]
      .join(' ')
      .toLowerCase();
  }

  public getRuleChipLabel(): string {
    if (this.qualifier === 'Deal Breaker') {
      return 'Dealbreaker';
    }
    return 'Maybe, But Follow-Up Needed';
  }

  public isNotInformational(): boolean {
    return this.qualifier !== 'Informational';
  }
}
