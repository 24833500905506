import { Source, SourceTag, SourceType } from '@/types/crm-screener';

import { ScreenerSourceContactModel } from './ScreenerSourceContactModel';

export class ScreenerSourceModel implements Source {
  contacts: ScreenerSourceContactModel[];
  facility_id: string;
  id: string;
  location?: string | null | undefined;
  name: string;
  npi_number?: string | null | undefined;
  phone?: string | null | undefined;
  email?: string | null | undefined;
  fax_number?: string | null | undefined;
  type?: SourceType;
  tag_id?: string | null | undefined;
  tag?: SourceTag;
  constructor(payload: Source) {
    Object.assign(this, payload);
    this.contacts = payload.contacts
      ? payload.contacts.map((item) => new ScreenerSourceContactModel(item))
      : [];
  }

  get fullName(): string {
    return this.name.trim();
  }

  get initials(): string {
    return `${this.name?.[0] ?? ''}`.toUpperCase();
  }
}
