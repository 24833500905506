/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryPattern
 */
export interface DeliveryPattern {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPattern
     */
    frequency_units_code?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPattern
     */
    period?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPattern
     */
    period_units_code?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPattern
     */
    period_units_code_definition?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPattern
     */
    quantity?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPattern
     */
    quantity_units_code?: string;
}

/**
 * Check if a given object implements the DeliveryPattern interface.
 */
export function instanceOfDeliveryPattern(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliveryPatternFromJSON(json: any): DeliveryPattern {
    return DeliveryPatternFromJSONTyped(json, false);
}

export function DeliveryPatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryPattern {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency_units_code': !exists(json, 'frequency_units_code') ? undefined : json['frequency_units_code'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'period_units_code': !exists(json, 'period_units_code') ? undefined : json['period_units_code'],
        'period_units_code_definition': !exists(json, 'period_units_code_definition') ? undefined : json['period_units_code_definition'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'quantity_units_code': !exists(json, 'quantity_units_code') ? undefined : json['quantity_units_code'],
    };
}

export function DeliveryPatternToJSON(value?: DeliveryPattern | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency_units_code': value.frequency_units_code,
        'period': value.period,
        'period_units_code': value.period_units_code,
        'period_units_code_definition': value.period_units_code_definition,
        'quantity': value.quantity,
        'quantity_units_code': value.quantity_units_code,
    };
}

