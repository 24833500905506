/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner } from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner';
import {
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSONTyped,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON,
} from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner';

/**
 * 
 * @export
 * @interface FindAllScreenerTags200ResponseDataInner
 */
export interface FindAllScreenerTags200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInner
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllScreenerTags200ResponseDataInner
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof FindAllScreenerTags200ResponseDataInner
     */
    type?: number;
    /**
     * 
     * @type {Array<FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner>}
     * @memberof FindAllScreenerTags200ResponseDataInner
     */
    screener_lead_tags?: Array<FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner>;
}

/**
 * Check if a given object implements the FindAllScreenerTags200ResponseDataInner interface.
 */
export function instanceOfFindAllScreenerTags200ResponseDataInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllScreenerTags200ResponseDataInnerFromJSON(json: any): FindAllScreenerTags200ResponseDataInner {
    return FindAllScreenerTags200ResponseDataInnerFromJSONTyped(json, false);
}

export function FindAllScreenerTags200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllScreenerTags200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'screener_lead_tags': !exists(json, 'screener_lead_tags') ? undefined : ((json['screener_lead_tags'] as Array<any>).map(FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSON)),
    };
}

export function FindAllScreenerTags200ResponseDataInnerToJSON(value?: FindAllScreenerTags200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'type': value.type,
        'screener_lead_tags': value.screener_lead_tags === undefined ? undefined : ((value.screener_lead_tags as Array<any>).map(FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON)),
    };
}

