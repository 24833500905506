/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdmissionStatus } from './AdmissionStatus';
import {
    AdmissionStatusFromJSON,
    AdmissionStatusFromJSONTyped,
    AdmissionStatusToJSON,
} from './AdmissionStatus';
import type { Bed } from './Bed';
import {
    BedFromJSON,
    BedFromJSONTyped,
    BedToJSON,
} from './Bed';
import type { FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner } from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner';
import {
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSON,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSONTyped,
    FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON,
} from './FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner';
import type { LeadAdmissionStatusInfo } from './LeadAdmissionStatusInfo';
import {
    LeadAdmissionStatusInfoFromJSON,
    LeadAdmissionStatusInfoFromJSONTyped,
    LeadAdmissionStatusInfoToJSON,
} from './LeadAdmissionStatusInfo';
import type { ScreenerInsurance } from './ScreenerInsurance';
import {
    ScreenerInsuranceFromJSON,
    ScreenerInsuranceFromJSONTyped,
    ScreenerInsuranceToJSON,
} from './ScreenerInsurance';

/**
 * 
 * @export
 * @interface ScreenerResident
 */
export interface ScreenerResident {
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    dob: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    gender: string;
    /**
     * 
     * @type {AdmissionStatus}
     * @memberof ScreenerResident
     */
    admission_status: AdmissionStatus;
    /**
     * 
     * @type {LeadAdmissionStatusInfo}
     * @memberof ScreenerResident
     */
    admission_status_info: LeadAdmissionStatusInfo;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    source_id: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    source_name: string;
    /**
     * 
     * @type {Bed}
     * @memberof ScreenerResident
     */
    bed: Bed;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    original_move_in_date: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerResident
     */
    connector: ScreenerResidentConnectorEnum;
    /**
     * 
     * @type {Array<ScreenerInsurance>}
     * @memberof ScreenerResident
     */
    insurances?: Array<ScreenerInsurance>;
    /**
     * 
     * @type {Array<FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner>}
     * @memberof ScreenerResident
     */
    screener_lead_tags?: Array<FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInner>;
}


/**
 * @export
 */
export const ScreenerResidentConnectorEnum = {
    Aida: 'Aida',
    Aidin: 'Aidin',
    Ensocare: 'Ensocare',
    EnsocareVa: 'Ensocare VA',
    EpicCareLink: 'EpicCare Link',
    EpicCareLinkAllinaHealth: 'EpicCare Link Allina Health',
    EpicCareLinkBsaHospital: 'EpicCare Link BSA Hospital',
    EpicCareLinkDenverHealth: 'EpicCare Link Denver Health',
    EpicCareLinkHartfordHealthCare: 'EpicCare Link Hartford HealthCare',
    EpicCareLinkHealthPartners: 'EpicCare Link HealthPartners',
    EpicCareLinkHennepinCountyMedicalCenter: 'EpicCare Link Hennepin County Medical Center',
    EpicCareLinkLegacyHealthyPlanet: 'EpicCare Link Legacy Healthy Planet',
    EpicCareLinkMayoClinic: 'EpicCare Link Mayo Clinic',
    EpicCareLinkMercy: 'EpicCare Link Mercy',
    EpicCareLinkPiedmont: 'EpicCare Link Piedmont',
    EpicCareLinkSsmHealth: 'EpicCare Link SSM Health',
    EpicCareLinkUcHealth: 'EpicCare Link UCHealth',
    EpicCareLinkUnityPointHealth: 'EpicCare Link UnityPoint Health',
    EpicCareLinkUtHealthEastTexas: 'EpicCare Link UT Health East Texas',
    EpicCareLinkWellstar: 'EpicCare Link Wellstar',
    Fax: 'Fax',
    ManualUpload: 'Manual Upload',
    WellSkyIntake: 'WellSky Intake',
    WellSkyReferralManagement: 'WellSky Referral Management',
    WellSkyReferralManagementWebReferral: 'WellSky Referral Management Web Referral'
} as const;
export type ScreenerResidentConnectorEnum = typeof ScreenerResidentConnectorEnum[keyof typeof ScreenerResidentConnectorEnum];


/**
 * Check if a given object implements the ScreenerResident interface.
 */
export function instanceOfScreenerResident(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "dob" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "admission_status" in value;
    isInstance = isInstance && "admission_status_info" in value;
    isInstance = isInstance && "source_id" in value;
    isInstance = isInstance && "source_name" in value;
    isInstance = isInstance && "bed" in value;
    isInstance = isInstance && "original_move_in_date" in value;
    isInstance = isInstance && "connector" in value;

    return isInstance;
}

export function ScreenerResidentFromJSON(json: any): ScreenerResident {
    return ScreenerResidentFromJSONTyped(json, false);
}

export function ScreenerResidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScreenerResident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'dob': json['dob'],
        'gender': json['gender'],
        'admission_status': AdmissionStatusFromJSON(json['admission_status']),
        'admission_status_info': LeadAdmissionStatusInfoFromJSON(json['admission_status_info']),
        'source_id': json['source_id'],
        'source_name': json['source_name'],
        'bed': BedFromJSON(json['bed']),
        'original_move_in_date': json['original_move_in_date'],
        'connector': json['connector'],
        'insurances': !exists(json, 'insurances') ? undefined : ((json['insurances'] as Array<any>).map(ScreenerInsuranceFromJSON)),
        'screener_lead_tags': !exists(json, 'screener_lead_tags') ? undefined : ((json['screener_lead_tags'] as Array<any>).map(FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerFromJSON)),
    };
}

export function ScreenerResidentToJSON(value?: ScreenerResident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'dob': value.dob,
        'gender': value.gender,
        'admission_status': AdmissionStatusToJSON(value.admission_status),
        'admission_status_info': LeadAdmissionStatusInfoToJSON(value.admission_status_info),
        'source_id': value.source_id,
        'source_name': value.source_name,
        'bed': BedToJSON(value.bed),
        'original_move_in_date': value.original_move_in_date,
        'connector': value.connector,
        'insurances': value.insurances === undefined ? undefined : ((value.insurances as Array<any>).map(ScreenerInsuranceToJSON)),
        'screener_lead_tags': value.screener_lead_tags === undefined ? undefined : ((value.screener_lead_tags as Array<any>).map(FindAllScreenerTags200ResponseDataInnerScreenerLeadTagsInnerToJSON)),
    };
}

