/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceDates
 */
export interface ServiceDates {
    /**
     * Start date in YYYY-MM-DD format
     * @type {Date}
     * @memberof ServiceDates
     */
    start: Date;
    /**
     * End date in YYYY-MM-DD format
     * @type {Date}
     * @memberof ServiceDates
     */
    end?: Date;
}

/**
 * Check if a given object implements the ServiceDates interface.
 */
export function instanceOfServiceDates(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "start" in value;

    return isInstance;
}

export function ServiceDatesFromJSON(json: any): ServiceDates {
    return ServiceDatesFromJSONTyped(json, false);
}

export function ServiceDatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceDates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
    };
}

export function ServiceDatesToJSON(value?: ServiceDates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': (value.start.toISOString().substring(0,10)),
        'end': value.end === undefined ? undefined : (value.end.toISOString().substring(0,10)),
    };
}

