/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActiveCoverage } from './ActiveCoverage';
import {
    ActiveCoverageFromJSON,
    ActiveCoverageFromJSONTyped,
    ActiveCoverageToJSON,
} from './ActiveCoverage';
import type { BenefitInformation } from './BenefitInformation';
import {
    BenefitInformationFromJSON,
    BenefitInformationFromJSONTyped,
    BenefitInformationToJSON,
} from './BenefitInformation';
import type { HospitalSnfStays } from './HospitalSnfStays';
import {
    HospitalSnfStaysFromJSON,
    HospitalSnfStaysFromJSONTyped,
    HospitalSnfStaysToJSON,
} from './HospitalSnfStays';
import type { Pharmacy } from './Pharmacy';
import {
    PharmacyFromJSON,
    PharmacyFromJSONTyped,
    PharmacyToJSON,
} from './Pharmacy';
import type { PrimaryCareProvider } from './PrimaryCareProvider';
import {
    PrimaryCareProviderFromJSON,
    PrimaryCareProviderFromJSONTyped,
    PrimaryCareProviderToJSON,
} from './PrimaryCareProvider';
import type { ProcedureSection } from './ProcedureSection';
import {
    ProcedureSectionFromJSON,
    ProcedureSectionFromJSONTyped,
    ProcedureSectionToJSON,
} from './ProcedureSection';
import type { RequestError } from './RequestError';
import {
    RequestErrorFromJSON,
    RequestErrorFromJSONTyped,
    RequestErrorToJSON,
} from './RequestError';
import type { RequestInfo } from './RequestInfo';
import {
    RequestInfoFromJSON,
    RequestInfoFromJSONTyped,
    RequestInfoToJSON,
} from './RequestInfo';
import type { ServiceTypeDetails } from './ServiceTypeDetails';
import {
    ServiceTypeDetailsFromJSON,
    ServiceTypeDetailsFromJSONTyped,
    ServiceTypeDetailsToJSON,
} from './ServiceTypeDetails';

/**
 * 
 * @export
 * @interface ExacareEligibilityResponse
 */
export interface ExacareEligibilityResponse {
    /**
     * 
     * @type {string}
     * @memberof ExacareEligibilityResponse
     */
    check_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExacareEligibilityResponse
     */
    date_executed?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExacareEligibilityResponse
     */
    request_hash?: string;
    /**
     * 
     * @type {object}
     * @memberof ExacareEligibilityResponse
     */
    request_object?: object;
    /**
     * 
     * @type {RequestInfo}
     * @memberof ExacareEligibilityResponse
     */
    request_info?: RequestInfo;
    /**
     * 
     * @type {ActiveCoverage}
     * @memberof ExacareEligibilityResponse
     */
    active_coverage?: ActiveCoverage;
    /**
     * 
     * @type {Pharmacy}
     * @memberof ExacareEligibilityResponse
     */
    pharmacy?: Pharmacy;
    /**
     * 
     * @type {PrimaryCareProvider}
     * @memberof ExacareEligibilityResponse
     */
    primary_care_provider?: PrimaryCareProvider;
    /**
     * 
     * @type {Array<ServiceTypeDetails>}
     * @memberof ExacareEligibilityResponse
     */
    service_type_details?: Array<ServiceTypeDetails>;
    /**
     * 
     * @type {Array<BenefitInformation>}
     * @memberof ExacareEligibilityResponse
     */
    benefit_information?: Array<BenefitInformation>;
    /**
     * 
     * @type {HospitalSnfStays}
     * @memberof ExacareEligibilityResponse
     */
    hospital_snf_stays?: HospitalSnfStays;
    /**
     * 
     * @type {Array<ProcedureSection>}
     * @memberof ExacareEligibilityResponse
     */
    preventative?: Array<ProcedureSection>;
    /**
     * 
     * @type {Array<ProcedureSection>}
     * @memberof ExacareEligibilityResponse
     */
    covid?: Array<ProcedureSection>;
    /**
     * 
     * @type {Array<RequestError>}
     * @memberof ExacareEligibilityResponse
     */
    errors?: Array<RequestError>;
}

/**
 * Check if a given object implements the ExacareEligibilityResponse interface.
 */
export function instanceOfExacareEligibilityResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExacareEligibilityResponseFromJSON(json: any): ExacareEligibilityResponse {
    return ExacareEligibilityResponseFromJSONTyped(json, false);
}

export function ExacareEligibilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExacareEligibilityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'check_id': !exists(json, 'check_id') ? undefined : json['check_id'],
        'date_executed': !exists(json, 'date_executed') ? undefined : (new Date(json['date_executed'])),
        'request_hash': !exists(json, 'request_hash') ? undefined : json['request_hash'],
        'request_object': !exists(json, 'request_object') ? undefined : json['request_object'],
        'request_info': !exists(json, 'request_info') ? undefined : RequestInfoFromJSON(json['request_info']),
        'active_coverage': !exists(json, 'active_coverage') ? undefined : ActiveCoverageFromJSON(json['active_coverage']),
        'pharmacy': !exists(json, 'pharmacy') ? undefined : PharmacyFromJSON(json['pharmacy']),
        'primary_care_provider': !exists(json, 'primary_care_provider') ? undefined : PrimaryCareProviderFromJSON(json['primary_care_provider']),
        'service_type_details': !exists(json, 'service_type_details') ? undefined : ((json['service_type_details'] as Array<any>).map(ServiceTypeDetailsFromJSON)),
        'benefit_information': !exists(json, 'benefit_information') ? undefined : ((json['benefit_information'] as Array<any>).map(BenefitInformationFromJSON)),
        'hospital_snf_stays': !exists(json, 'hospital_snf_stays') ? undefined : HospitalSnfStaysFromJSON(json['hospital_snf_stays']),
        'preventative': !exists(json, 'preventative') ? undefined : ((json['preventative'] as Array<any>).map(ProcedureSectionFromJSON)),
        'covid': !exists(json, 'covid') ? undefined : ((json['covid'] as Array<any>).map(ProcedureSectionFromJSON)),
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(RequestErrorFromJSON)),
    };
}

export function ExacareEligibilityResponseToJSON(value?: ExacareEligibilityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'check_id': value.check_id,
        'date_executed': value.date_executed === undefined ? undefined : (value.date_executed.toISOString().substring(0,10)),
        'request_hash': value.request_hash,
        'request_object': value.request_object,
        'request_info': RequestInfoToJSON(value.request_info),
        'active_coverage': ActiveCoverageToJSON(value.active_coverage),
        'pharmacy': PharmacyToJSON(value.pharmacy),
        'primary_care_provider': PrimaryCareProviderToJSON(value.primary_care_provider),
        'service_type_details': value.service_type_details === undefined ? undefined : ((value.service_type_details as Array<any>).map(ServiceTypeDetailsToJSON)),
        'benefit_information': value.benefit_information === undefined ? undefined : ((value.benefit_information as Array<any>).map(BenefitInformationToJSON)),
        'hospital_snf_stays': HospitalSnfStaysToJSON(value.hospital_snf_stays),
        'preventative': value.preventative === undefined ? undefined : ((value.preventative as Array<any>).map(ProcedureSectionToJSON)),
        'covid': value.covid === undefined ? undefined : ((value.covid as Array<any>).map(ProcedureSectionToJSON)),
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(RequestErrorToJSON)),
    };
}

