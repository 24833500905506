/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdmissionStatus } from './AdmissionStatus';
import {
    AdmissionStatusFromJSON,
    AdmissionStatusFromJSONTyped,
    AdmissionStatusToJSON,
} from './AdmissionStatus';
import type { LeadAdmissionStatusInfo } from './LeadAdmissionStatusInfo';
import {
    LeadAdmissionStatusInfoFromJSON,
    LeadAdmissionStatusInfoFromJSONTyped,
    LeadAdmissionStatusInfoToJSON,
} from './LeadAdmissionStatusInfo';

/**
 * 
 * @export
 * @interface UpdateAdmissionStatusRequest
 */
export interface UpdateAdmissionStatusRequest {
    /**
     * 
     * @type {AdmissionStatus}
     * @memberof UpdateAdmissionStatusRequest
     */
    admission_status?: AdmissionStatus;
    /**
     * 
     * @type {LeadAdmissionStatusInfo}
     * @memberof UpdateAdmissionStatusRequest
     */
    admission_status_info?: LeadAdmissionStatusInfo;
}

/**
 * Check if a given object implements the UpdateAdmissionStatusRequest interface.
 */
export function instanceOfUpdateAdmissionStatusRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAdmissionStatusRequestFromJSON(json: any): UpdateAdmissionStatusRequest {
    return UpdateAdmissionStatusRequestFromJSONTyped(json, false);
}

export function UpdateAdmissionStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAdmissionStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admission_status': !exists(json, 'admission_status') ? undefined : AdmissionStatusFromJSON(json['admission_status']),
        'admission_status_info': !exists(json, 'admission_status_info') ? undefined : LeadAdmissionStatusInfoFromJSON(json['admission_status_info']),
    };
}

export function UpdateAdmissionStatusRequestToJSON(value?: UpdateAdmissionStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admission_status': AdmissionStatusToJSON(value.admission_status),
        'admission_status_info': LeadAdmissionStatusInfoToJSON(value.admission_status_info),
    };
}

