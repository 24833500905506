import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { CRMClient } from '@/adapters/schemaClients';
import { FindAllEmailAddressBookRequest } from '@/types/crm';

import { EmailAddressBookItemModel } from '../models/EmailAddressBookItemModel';

export const FIND_ALL_EMAIL_ADDRESS_BOOK_DEFAULT_LIMIT = 10;

const FIND_ALL_EMAIL_ADDRESS_BOOK_INFINITE_QUERY_KEY =
  'findAllEmailAddressBookInfinite';

interface FindAllEmailAddressBookPage {
  data: Array<EmailAddressBookItemModel>;
  total: number;
  page?: number;
  limit?: number;
  has_next: boolean;
}

export const invalidateEmailAddressBookQuery = () => {
  queryClient.invalidateQueries([FIND_ALL_EMAIL_ADDRESS_BOOK_DEFAULT_LIMIT]);
};

export const useEmailAddressBookQuery = () => {
  return {
    findAllInfinite: (
      params: FindAllEmailAddressBookRequest,
      options: UseInfiniteQueryOptions<
        FindAllEmailAddressBookPage,
        HTTPError
      > = {}
    ) =>
      useInfiniteQuery<FindAllEmailAddressBookPage>(
        [FIND_ALL_EMAIL_ADDRESS_BOOK_INFINITE_QUERY_KEY, params],
        async ({ pageParam }) => {
          if (pageParam) {
            params.page = parseInt(pageParam.page) + 1;
          } else {
            params.page = 1;
          }
          const response = await CRMClient.findAllEmailAddressBook(params);
          const pageModels = response.data!.data.map(
            (item) => new EmailAddressBookItemModel(item)
          );
          return {
            ...response.data!,
            data: pageModels
          };
        },
        {
          getNextPageParam: (lastPage) => {
            if (!lastPage?.has_next) {
              return false;
            }
            return lastPage;
          },
          ...(options as any)
        }
      )
  };
};
