import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import merge from 'lodash/merge';
import omit from 'lodash/omit';

import { fetchMedications, fetchResidents } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { SlidingScaleDoseRange } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { invalidateMARSQuery } from '@/components/MAR/useMARQuery';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { invalidateMedicationStockQuery } from '@/hooks/useMedicationStockQuery';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { TaskExtensionPayload } from '@/models/TaskExtensionModel';

import { invalidateCustomMedications } from './useCustomMedicationsQuery';
import { MedicationTaskInstancePayload } from './useMedicationTaskInstancesQuery';
import { SchedulePayload } from './useResidentCarePlanQuery';
import { invalidateTaskInstancesQuery } from './useTaskInstancesQuery';

export interface MedicationTaskVitalRequirementsPayload {
  id: string;
  vital_type_id: string;
  medication_task_id: string;
  min_value: string | null;
  max_value: string | null;
}

export interface MedicationTaskSchedulePayload extends SchedulePayload {
  medication_task_id: string;
  dose_ranges?: SlidingScaleDoseRange[];
  prn_min_interval_minutes: number | null;
  prn_max_daily_number_of_dose_units: number | null;
  prn_available_dosage: number | null;
  prn_refresh_time: string | null;
  prescription_interval: string | null;
  prn_constraint_reason: 'max_daily_dose' | 'min_interval' | null;
}

interface FindMedicationTasksQueryParams {
  residentId: number | string;
  active?: boolean;
}

export class MedicationTaskPayload {
  id: string;
  resident_id: string;
  fdb_dispensable_drug: MedicationsPayload;
  fdb_dispensable_drug_id: string;
  drug_name_desc: string | null;
  diagnosis: string | null;
  doctor_id: string | null;
  instructions: string | null;
  length_mins: number | null;
  s3_prescription_key: string | null;
  is_deleted: boolean | null;
  as_needed: boolean | null;
  is_editable: boolean;
  prescription_types: string | null;
  createdAt: string;
  updatedAt: string;
  medication_task_schedules: MedicationTaskSchedulePayload[];
  medication_task_instances: MedicationTaskInstancePayload[];
  vital_reqs: MedicationTaskVitalRequirementsPayload[];
  task_extension: TaskExtensionPayload | null;
  equivalent_to: string | null;
  cancellation_reason: string | null;
  is_new: boolean;
  /** @deprecated Use responsible_party_id instead */
  responsible_party: string | null;
  amount_in_stock: number | null;
  doctor_name: string | null;
  acetaminophen_24_hours?: number;
  task_id?: string;
  task_name?: string;
  is_receipt_confirmed?: boolean;
  pharm_prescriptions?: any[];
  pharm_prescription_order_id?: string | null;
  non_community_dose: number | null;
  medication_id: string | null;
  tooltipDateUTC?: string | null;
  created_by_user_id: string;
  created_by_user: {
    id: string;
    first_name: string;
    last_name: string;
  };
  number_of_assistants: number | null;
  is_informational?: boolean;
  doctor: null;
  responsible_party_id: string | null;
  responsible_party_instance: ResponsibleParty | null;
  may_have_max_daily_dosage: boolean | null;

  applySchedulesUpdateImmediately?: boolean; // Used to force update schedules immediately, not returning from actual API

  constructor(payload: MedicationTaskPayload) {
    Object.assign(this, payload);
  }
}

export interface ResidentMedicationsPayload {
  active: MedicationTaskPayload[];
  inactive: MedicationTaskPayload[];
}

const QUERY_KEY_STRING = 'useResidentMedications';
const QUERY_KEY_MEDICATION_TASKS = 'useFindMedicationTaskById';

export const invalidateResidentMedicationsQuery = (residentId?: string) => {
  queryClient.invalidateQueries([QUERY_KEY_STRING, residentId]);
};

export const invalidateMedicationTaskByIdQuery = (
  medicationTaskId?: string
) => {
  queryClient.invalidateQueries([
    QUERY_KEY_MEDICATION_TASKS,
    { medicationTaskId }
  ]);
};

export function useResidentMedications(residentId?: string) {
  const QUERY_KEY = [QUERY_KEY_STRING, residentId];

  const invalidate = async () =>
    await Promise.all([
      queryClient.invalidateQueries(QUERY_KEY),
      invalidateTaskInstancesQuery(residentId),
      invalidateMARSQuery(),
      invalidateMedicationStockQuery(),
      invalidateCustomMedications()
    ]);

  return {
    invalidate,

    query: (options: UseQueryOptions = {}) =>
      useQuery(
        QUERY_KEY,
        async () =>
          fetchResidents.get<ResidentMedicationsPayload>(
            `/residents/${residentId}/medications`
          ),
        {
          enabled: !!residentId,
          ...options
        } as any
      ),

    mutations: (medicationTaskId?: string) => ({
      post: useMutation(
        async (
          payload: Partial<MedicationTaskPayload>
        ): Promise<MedicationTaskPayload> =>
          fetchMedications.post<MedicationTaskPayload>(
            `/medication-tasks`,
            merge({ resident_id: residentId }, payload)
          ),
        {
          onSettled: invalidate
        }
      ),
      put: useMutation(
        (payload: Partial<MedicationTaskPayload>): Promise<any> => {
          const url = payload.applySchedulesUpdateImmediately
            ? `/medication-tasks/${payload.id}?update_immediately=true`
            : `/medication-tasks/${payload.id}`;

          return fetchMedications.put(url, omit(payload, ['id']), {
            timeout: 30000
          });
        },
        {
          onSettled: () => {
            invalidate();
            if (medicationTaskId) {
              invalidateMedicationTaskByIdQuery(medicationTaskId);
            }
          }
        }
      ),
      deactivate: useMutation(
        async (
          payload: Pick<MedicationTaskPayload, 'id' | 'cancellation_reason'>
        ) =>
          fetchMedications.post(`/medication-tasks/${payload.id}/deactivate`, {
            cancellation_reason: payload.cancellation_reason
          }),
        {
          onSettled: invalidate
        }
      ),
      delete: useMutation(
        async (payload: Pick<MedicationTaskPayload, 'id'>) =>
          fetchMedications.delete(`/medication-tasks/${payload.id}`),
        {
          onSettled: invalidate
        }
      )
    })
  };
}

export function useFindMedicationTaskById() {
  const QUERY_KEY = [QUERY_KEY_MEDICATION_TASKS];
  return {
    query: (medicationTaskId: string, options: UseQueryOptions = {}) => {
      return useQuery(
        [...QUERY_KEY, { medicationTaskId }],
        async () =>
          fetchMedications.get<MedicationTaskPayload>(
            `/medication-tasks/${medicationTaskId}`
          ),
        {
          ...options
        } as any
      );
    }
  };
}

export function useFindMedicationTasks() {
  const QUERY_KEY = [QUERY_KEY_STRING];

  return {
    query: (
      params: FindMedicationTasksQueryParams,
      options: UseQueryOptions = {}
    ) => {
      return useQuery(
        [...QUERY_KEY, params],
        async () =>
          fetchMedications.get<MedicationTaskPayload[]>(`/medication-tasks`, {
            searchParams: {
              unlinked: true,
              ...params
            }
          }),
        {
          select: (payload: any) =>
            payload.map((payload: any) => ({
              task_id: payload.id,
              task_name: payload.fdb_dispensable_drug.DrugNameDesc ?? '',
              ...payload
            })),
          ...options
        } as any
      );
    }
  };
}
