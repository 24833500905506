import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import {
  Client,
  Conversation,
  Paginator,
  Participant
} from '@twilio/conversations';

import { fetchCRMCalls, fetchCRMTexts } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { useSelectedFacilityId } from '@/hooks/useFacilitiesQuery';
import { UserModel } from '@/models/UserModel';
import { formatPhoneNumber } from '@/utils/phoneNumber';

import { ConversationCardItem } from '../InboxPage/TextInbox/components/ConversationCard';
import {
  CrmConversationModel,
  CrmConversationPayload
} from '../models/CrmConversationModel';
import {
  PhoneBookItemModel,
  PhoneBookItemPage
} from '../models/PhoneBookItemModel';

export const TWILIO_CONVERSATION_ERRORS_MAPPING: {
  [key: number]: string;
} = {
  50407: 'Phone number is invalid. Please enter a valid phone number',
  50409:
    'You cannot start a conversation with the same phone number you have assigned',
  50416: 'A conversation with this phone number already exists'
};

const CRM_CONVERSATIONS_FIND_ALL_QUERY_KEY = 'crmConversationsFindAll';
const CRM_CONVERSATIONS_FIND_ONE_QUERY_KEY = 'crmConversationsFindOne';
const CRM_CONVERSATIONS_FIND_PARTICIPANTS_QUERY_KEY =
  'crmConversationsFindParticipants';
const CONVERSATIONS_FIND_ALL_BY_FRIENDLY_NAME_KEY =
  'crmConversationsFindAllByFriendlyName';

const CRM_CONVERSATIONS_FIND_COUNT = 'crmConversationsFindCount';

export const invalidateConversationQueries = () => {
  queryClient.invalidateQueries([CRM_CONVERSATIONS_FIND_ALL_QUERY_KEY]);
  queryClient.invalidateQueries([CRM_CONVERSATIONS_FIND_ONE_QUERY_KEY]);
  queryClient.invalidateQueries([
    CRM_CONVERSATIONS_FIND_PARTICIPANTS_QUERY_KEY
  ]);
  queryClient.invalidateQueries([CONVERSATIONS_FIND_ALL_BY_FRIENDLY_NAME_KEY]);
  queryClient.invalidateQueries([CRM_CONVERSATIONS_FIND_COUNT]);
};

export const conversationsCardsSorter = (
  a: ConversationCardItem,
  b: ConversationCardItem
) => (b.time?.getTime() ?? 0) - (a.time?.getTime() ?? 0);

interface FindAllInfiniteResponse {
  conversationCards: ConversationCardItem[];
  nextPage: (() => Promise<Paginator<Conversation>>) | boolean;
}

export const useCrmConversationsQuery = () => {
  const facilityId = useSelectedFacilityId();

  return {
    invalidateConversationQueries,
    findAllByFriendlyName: (
      currentUser: UserModel,
      startDate: string,
      endDate: string,
      friendlyName?: string,
      phoneBookItem?: PhoneBookItemModel,
      options: UseQueryOptions<ConversationCardItem[]> = {}
    ) =>
      useQuery<ConversationCardItem[]>(
        [
          CONVERSATIONS_FIND_ALL_BY_FRIENDLY_NAME_KEY,
          { friendlyName, startDate, endDate }
        ],
        async () => {
          const conversations = await fetchCRMTexts.get<
            CrmConversationPayload[]
          >('/conversations', {
            searchParams: {
              friendly_name: friendlyName,
              start_date: startDate,
              end_date: endDate
            }
          });
          const conversationModels = conversations.map(
            (conversation) => new CrmConversationModel(conversation)
          );
          return getCardsFromConversationInstances(
            conversationModels,
            currentUser,
            phoneBookItem
          );
        },
        {
          enabled: !!friendlyName,
          refetchInterval: 20 * 1000, // Need to poll for new conversations
          ...options
        }
      ),
    findAllInfinite: (
      currentUser: UserModel,
      client?: Client,
      options: UseInfiniteQueryOptions<FindAllInfiniteResponse | null> = {}
    ) =>
      useInfiniteQuery<FindAllInfiniteResponse | null>(
        [CRM_CONVERSATIONS_FIND_ALL_QUERY_KEY],
        async ({ pageParam }) => {
          if (!client) {
            return null;
          }
          const paginator: Paginator<Conversation> = pageParam
            ? await pageParam()
            : await client.getSubscribedConversations();
          const conversations = paginator.items;
          // Each conversation will have as name the normalized phone number of the participant that is not a user
          const conversationsNames = conversations
            .map((conversation) => conversation.friendlyName)
            .join(',');
          let phoneBookItems;
          try {
            phoneBookItems = (
              await fetchCRMCalls.get<PhoneBookItemPage>(`/phone-book`, {
                searchParams: {
                  facility_id: facilityId,
                  phone_numbers: conversationsNames
                }
              })
            ).data.map((item) => new PhoneBookItemModel(item));
          } catch (error) {
            // Phone book numbers don't exist yet
          }
          const cards = await getConversationCards(
            conversations,
            currentUser,
            phoneBookItems
          );
          return {
            conversationCards: cards.sort(conversationsCardsSorter),
            nextPage: paginator.hasNextPage ? paginator.nextPage : false
          };
        },
        {
          getNextPageParam: (lastPage) => {
            if (!lastPage?.nextPage) {
              return false;
            }
            return lastPage.nextPage;
          },
          enabled: !!client,
          ...options
        }
      ),
    findOne: (
      currentUser: UserModel,
      client?: Client,
      uniqueName?: string,
      sid?: string,
      options: UseQueryOptions<ConversationCardItem | null> = {}
    ) =>
      useQuery<ConversationCardItem | null>(
        [CRM_CONVERSATIONS_FIND_ONE_QUERY_KEY, { sid, uniqueName }],
        async () => {
          if (!client) {
            return null;
          }
          let conversation;
          try {
            if (sid) {
              conversation = await client.getConversationBySid(sid);
            }
            if (uniqueName) {
              conversation = await client.getConversationByUniqueName(
                uniqueName
              );
            }
          } catch (error: any) {
            if (error.status === 404) {
              return null;
            }
            throw error;
          }
          if (!conversation) {
            return null;
          }
          const phoneBookItems = (
            await fetchCRMCalls.get<PhoneBookItemPage>(`/phone-book`, {
              searchParams: {
                facility_id: facilityId,
                phone_numbers: conversation.friendlyName
              }
            })
          ).data.map((item) => new PhoneBookItemModel(item));
          const transformedConversation = await getConversationCards(
            [conversation],
            currentUser,
            phoneBookItems
          );
          return transformedConversation.length
            ? transformedConversation[0]
            : null;
        },
        {
          enabled: (!!client && !!sid) || (!!client && !!uniqueName),
          ...options
        }
      ),
    findParticipants: (
      sid?: string,
      client?: Client,
      options: UseQueryOptions<Participant[] | null> = {}
    ) =>
      useQuery<Participant[] | null>(
        [CRM_CONVERSATIONS_FIND_PARTICIPANTS_QUERY_KEY, { sid }],
        async () => {
          if (!client || !sid) {
            return null;
          }
          const conversation = await client.getConversationBySid(sid);
          const participants = await conversation.getParticipants();
          return participants;
        },
        {
          enabled: !!client && !!sid,
          ...options
        }
      ),
    count: (options: UseQueryOptions<number> = {}) =>
      useQuery<number>(
        [CRM_CONVERSATIONS_FIND_COUNT],
        async () => {
          const count = await fetchCRMTexts.get<number>('/conversations/count');
          return count;
        },
        {
          ...options
        }
      ),
    create: (client: Client, options: UseMutationOptions = {}) =>
      useMutation(
        async ({
          uniqueName,
          friendlyName
        }: {
          uniqueName: string;
          friendlyName: string;
        }) => {
          // A conversation unique name has the form '<USER_ID>:<RESIDENT_PHONE>'
          return await client.createConversation({
            uniqueName: uniqueName,
            friendlyName: friendlyName
          });
        },
        {
          onSuccess: invalidateConversationQueries,
          ...(options as any)
        }
      ),
    delete: (options: UseMutationOptions = {}) =>
      useMutation(
        (sid: string) => fetchCRMTexts.delete(`/conversations/${sid}`),
        {
          onSuccess: invalidateConversationQueries,
          ...(options as any)
        }
      ),
    deleteLast: (limit: number, options: UseMutationOptions = {}) =>
      useMutation(() => fetchCRMTexts.delete(`/conversations?limit=${limit}`), {
        onSuccess: invalidateConversationQueries,
        ...(options as any)
      })
  };
};

export const getConversationCards = async (
  conversations: Conversation[],
  currentUser: UserModel,
  phoneBookItems?: PhoneBookItemModel[]
): Promise<ConversationCardItem[]> => {
  const newConversationCards: ConversationCardItem[] = [];
  for (const conversation of conversations) {
    try {
      const conversationMessages = await conversation.getMessages();
      let lastMessage;
      if (conversationMessages.items.length) {
        lastMessage =
          conversationMessages.items[conversationMessages.items.length - 1];
      }
      const matchingPhoneBookItem = phoneBookItems?.find(
        (item) => item.phone === conversation.friendlyName
      );
      const conversationMessagesCount = await conversation.getMessagesCount();
      const unreadMessagesCount = await conversation.getUnreadMessagesCount();
      newConversationCards.push({
        lastMessageAuthor:
          lastMessage?.author === currentUser.id
            ? currentUser.fullName
            : matchingPhoneBookItem?.fullName ||
              formatPhoneNumber(lastMessage?.author || ''),
        lastMessageBody: lastMessage?.body,
        name:
          matchingPhoneBookItem?.fullName ||
          formatPhoneNumber(conversation.friendlyName || ''),
        sid: conversation.sid,
        time: lastMessage ? lastMessage.dateCreated : conversation.dateCreated,
        phoneBookItem: matchingPhoneBookItem,
        lastMessageType: lastMessage?.type,
        unreadMessagesCount: unreadMessagesCount,
        messagesCount: conversationMessagesCount,
        lastMessageAuthorUser:
          lastMessage?.author === currentUser.id ? currentUser : undefined,
        uniqueName: conversation.uniqueName
      });
    } catch (error) {}
  }
  return newConversationCards;
};

const getCardsFromConversationInstances = (
  conversations: CrmConversationModel[],
  currentUser: UserModel,
  phoneBookItem?: PhoneBookItemModel
) => {
  const newConversationCards: ConversationCardItem[] = [];
  for (const conversation of conversations) {
    newConversationCards.push({
      lastMessageAuthor:
        conversation.lastMessage?.author === currentUser.id
          ? currentUser.fullName
          : conversation.lastMessage?.author === phoneBookItem?.phone
          ? phoneBookItem?.fullName
          : formatPhoneNumber(conversation.lastMessage?.author || ''),
      lastMessageBody: conversation.lastMessage?.body,
      name:
        phoneBookItem?.fullName ||
        formatPhoneNumber(conversation.friendlyName || ''),
      sid: conversation.sid,
      time: conversation.lastMessage
        ? conversation.lastMessage.dateCreated
        : conversation.dateCreated,
      phoneBookItem: phoneBookItem,
      lastMessageType: conversation.lastMessage?.media ? 'media' : 'text',
      lastMessageAuthorUser: conversation.lastMessageAuthorUser,
      uniqueName: conversation.uniqueName
    });
  }
  return newConversationCards;
};
