import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { Group } from '@/pages/CRM/Screener/Leads/AllLeads/components/AllLeadsDataGrid/AllLeadsDataGrid';
import {
  AdmissionStatus,
  Document,
  FacilityFacilityTypeEnum,
  Lead,
  LeadAdmissionStatusInfo,
  LeadConnectorEnum,
  LeadMedicareEstimatesStatusEnum,
  LeadScreenerLeadTagsInner,
  LeadStatus,
  PccPatient,
  ReferralBookInfo,
  ScreenerSpecialCriteria,
  ScreenerSpecialCriteriaIdentifierEnum,
  ScreenerSuggestion,
  SourceContact,
  User
} from '@/types/crm-screener';
import { Occupancy } from '@/types/crm-screener-api';

import { AnalysisResultModel } from './AnalysisResultModel';
import { LeadInfoModel } from './LeadInfoModel';
import { ScreenerInsuranceModel } from './ScreenerInsuranceModel';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'just now',
    m: '1 min',
    mm: '%d mins',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
});

export enum LeadModelStatusName {
  New = 'New',
  ReferralReceived = 'Referral Received',
  InterestedNeedMoreInfo = 'Need More Info',
  YesAccept = 'Accept',
  NoDecline = 'Decline'
}

export const LeadModelStatusShortMapping = {
  'Yes, Accept': LeadModelStatusName.YesAccept,
  'No, Decline': LeadModelStatusName.NoDecline,
  'Need More Info': LeadModelStatusName.InterestedNeedMoreInfo,
  'Interested, Need More Info': LeadModelStatusName.InterestedNeedMoreInfo,
  'Referral Received': LeadModelStatusName.ReferralReceived,
  New: LeadModelStatusName.New
};

export const SUPPORTED_CONTENT_TYPES: Record<string, string[]> = {
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx'
  ],
  'application/msword': ['.doc'],
  'text/plain': ['.txt'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg']
};

const combinableConnectors: LeadConnectorEnum[] = [
  LeadConnectorEnum.ManualUpload,
  LeadConnectorEnum.Fax
];

export class LeadModel implements Lead {
  id: string;
  organization_id: string;
  source_id: string;
  facility_id: string;
  first_name: string;
  source_name?: string;
  last_name: string;
  lead_received_date: string;
  created_at: string;
  screener_suggestion?: ScreenerSuggestion;
  status_id: string;
  status?: LeadStatus;
  documents: Array<Document>;
  analysis_results?: Array<AnalysisResultModel>;
  report_s3_key?: string;
  report_s3_access_url?: string;
  special_criteria?: Array<ScreenerSpecialCriteria>;
  pcc_patient_id?: string | undefined;
  pcc_pending_patient_id?: string | undefined;
  source_comments_received_at?: string | undefined;
  comments_summary?: string | undefined;
  pcc_clinic_package_pushed?: boolean | undefined;
  original_move_in_date?: string;
  pcc_push_by_user?: User | undefined;
  pcc_push_date?: string | undefined;
  connector?: LeadConnectorEnum | undefined;
  connector_referral_id?: string | undefined;
  pcc_screener_results_pushed?: boolean | undefined;
  pcc_summary_report_pushed?: boolean | undefined;
  ssn?: string;
  dob?: string;
  medicaid_number?: string;
  medicare_number?: string;
  gender?: string;
  source_contact?: SourceContact | undefined;
  source_contact_id?: string | null | undefined;
  is_visual_references_ready?: boolean | undefined;
  medicare_estimates_visual_refs_ready?: boolean | undefined;
  is_cancelled: boolean;
  duplicate_group_id?: string;
  claimed_by_facility?: {
    id: string;
    name: string;
  };
  facility?: {
    id: string;
    name: string;
    facility_type: FacilityFacilityTypeEnum;
  };
  is_large_document_ai_used?: boolean | undefined;
  medications_extraction_complete?: boolean | undefined;
  s_offender_records_stored?: boolean | undefined;
  are_all_documents_analyzed?: boolean | undefined;
  has_insufficient_document_context?: boolean | undefined;
  medicare_estimates_status?: LeadMedicareEstimatesStatusEnum;
  source_comments?: string | null;
  declined_reason?: string | undefined;
  denial_reason?: string | undefined;
  pcc_data_pulls_report_pushed?: boolean | undefined;
  pcc_medication_list_report_pushed?: boolean | undefined;
  pcc_s_offender_report_pushed?: boolean | undefined;
  pcc_patient?: PccPatient | undefined;
  referral_book_info?: ReferralBookInfo;
  admission_status?: AdmissionStatus;
  admission_status_info?: LeadAdmissionStatusInfo;
  has_uploaded_pcc_initial_details: boolean;
  occupancies?: Array<Occupancy>;
  insurances?: Array<ScreenerInsuranceModel>;
  screener_lead_info?: LeadInfoModel | undefined;
  screener_lead_tags?: LeadScreenerLeadTagsInner[] | undefined;

  constructor(payload: Lead) {
    Object.assign(this, payload);
    this.analysis_results = payload.analysis_results?.map(
      (item) => new AnalysisResultModel(item)
    );
    this.has_uploaded_pcc_initial_details =
      payload.pcc_pending_patient_id != null;
    this.insurances =
      payload.insurances?.map((item) => new ScreenerInsuranceModel(item)) ?? [];
    if (payload.screener_lead_info) {
      this.screener_lead_info = new LeadInfoModel(payload.screener_lead_info);
    }
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`.trim();
  }

  get initials(): string {
    return `${this.first_name?.[0] ?? ''}${
      this.last_name?.[0] ?? ''
    }`.toUpperCase();
  }

  public static sortLeadsByFacility(
    currentFacilityId: string | null
  ): (a: LeadModel, b: LeadModel) => number {
    return (a: LeadModel, b: LeadModel) => {
      if (
        a.facility_id === currentFacilityId &&
        b.facility_id !== currentFacilityId
      ) {
        return -1;
      }
      if (
        a.facility_id !== currentFacilityId &&
        b.facility_id === currentFacilityId
      ) {
        return 1;
      }
      return 0;
    };
  }

  public shouldDisplayForCurrentFacility(
    currentFacilityId: string | null,
    displayAllLeads: boolean
  ): boolean {
    return (
      this.claimed_by_facility?.id === currentFacilityId &&
      this.facility_id !== currentFacilityId &&
      !displayAllLeads
    );
  }

  public shouldHideActions(
    currentFacilityId: string | null,
    isAllFacilitiesLeadsSelected: boolean
  ): boolean {
    if (isAllFacilitiesLeadsSelected) {
      return false;
    }
    return this.facility_id !== currentFacilityId;
  }

  public hasDuplicateActions(duplicateGroupIdCount: number): boolean {
    return (
      duplicateGroupIdCount > 1 &&
      !this.claimed_by_facility &&
      !this.is_cancelled
    );
  }

  public hasClaimAction(groupDefinition: Group): boolean {
    const duplicateGroupIdCount = groupDefinition?.count || 0;
    const fromDifferentFacilities = groupDefinition?.leads
      ?.filter((lead) => !lead.is_cancelled)
      ?.some((lead) => lead.facility_id !== this.facility_id);
    return (
      fromDifferentFacilities &&
      duplicateGroupIdCount > 1 &&
      !this.claimed_by_facility &&
      !this.is_cancelled
    );
  }

  public getTimeSinceReceived(): string {
    return dayjs(this.lead_received_date).fromNow();
  }

  public getPccPushByFullName(): string {
    return `${this.pcc_push_by_user?.first_name} ${this.pcc_push_by_user?.last_name}`;
  }

  public medicareVisualReferencesReady(): boolean {
    return (
      !!this.medicare_estimates_visual_refs_ready &&
      (this.medicare_estimates_status ===
        LeadMedicareEstimatesStatusEnum.Completed ||
        this.medicare_estimates_status ===
          LeadMedicareEstimatesStatusEnum.NotEnoughData)
    );
  }

  public visualReferencesReady(): boolean {
    return (
      !!this.is_visual_references_ready ||
      !!this.analysis_results?.some((item) => item.visual_references?.length) // for backward compatibility (the flag was added later)
    );
  }

  public isReviewStageClickable(): boolean {
    return (
      !(
        this.admission_status === AdmissionStatus.Booked ||
        this.admission_status === AdmissionStatus.MovedIn
      ) || this.status?.name !== LeadModelStatusName.YesAccept
    );
  }

  public canBeCombined(currentFacilityId: string): boolean {
    if (
      this.is_cancelled ||
      (this.claimed_by_facility?.id &&
        this.claimed_by_facility?.id !== currentFacilityId)
    ) {
      return false;
    }
    return (
      currentFacilityId === this.facility_id &&
      combinableConnectors.includes(this.connector as LeadConnectorEnum)
    );
  }

  public hasSpecialCriteria(): boolean {
    return (this.special_criteria ?? []).length > 0;
  }

  public hasMedicationPricingSpecialCriteria(): boolean {
    return (
      this.special_criteria?.find(
        (item) =>
          item.identifier ===
          ScreenerSpecialCriteriaIdentifierEnum.MedicationPricing
      ) !== undefined
    );
  }
  public hasSexOffenderSpecialCriteria(): boolean {
    return (
      this.special_criteria?.find(
        (item) =>
          item.identifier ===
          ScreenerSpecialCriteriaIdentifierEnum.SexOffenderCheck
      ) !== undefined
    );
  }

  public getPrimaryInsurance(): ScreenerInsuranceModel | undefined {
    return this.insurances?.find((item) => item.isPrimary());
  }

  public getAgeInYears(): number | null {
    if (!this.dob) {
      return null;
    }
    return dayjs().diff(dayjs(this.dob), 'years');
  }
}
