/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PccPatientResponse } from './PccPatientResponse';
import {
    PccPatientResponseFromJSON,
    PccPatientResponseFromJSONTyped,
    PccPatientResponseToJSON,
} from './PccPatientResponse';

/**
 * 
 * @export
 * @interface FindOnePccPatient200Response
 */
export interface FindOnePccPatient200Response {
    /**
     * 
     * @type {PccPatientResponse}
     * @memberof FindOnePccPatient200Response
     */
    data?: PccPatientResponse;
}

/**
 * Check if a given object implements the FindOnePccPatient200Response interface.
 */
export function instanceOfFindOnePccPatient200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindOnePccPatient200ResponseFromJSON(json: any): FindOnePccPatient200Response {
    return FindOnePccPatient200ResponseFromJSONTyped(json, false);
}

export function FindOnePccPatient200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOnePccPatient200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : PccPatientResponseFromJSON(json['data']),
    };
}

export function FindOnePccPatient200ResponseToJSON(value?: FindOnePccPatient200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PccPatientResponseToJSON(value.data),
    };
}

