/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriberInfo
 */
export interface SubscriberInfo {
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfo
     */
    memberIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfo
     */
    lastName: string;
    /**
     * Date in YYYY-MM-DD format
     * @type {Date}
     * @memberof SubscriberInfo
     */
    dateOfBirth: Date;
}

/**
 * Check if a given object implements the SubscriberInfo interface.
 */
export function instanceOfSubscriberInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "memberIdentifier" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "dateOfBirth" in value;

    return isInstance;
}

export function SubscriberInfoFromJSON(json: any): SubscriberInfo {
    return SubscriberInfoFromJSONTyped(json, false);
}

export function SubscriberInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriberInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberIdentifier': json['memberIdentifier'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dateOfBirth': (new Date(json['dateOfBirth'])),
    };
}

export function SubscriberInfoToJSON(value?: SubscriberInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberIdentifier': value.memberIdentifier,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': (value.dateOfBirth.toISOString().substring(0,10)),
    };
}

