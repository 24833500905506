/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EligibilityCheck } from './EligibilityCheck';
import {
    EligibilityCheckFromJSON,
    EligibilityCheckFromJSONTyped,
    EligibilityCheckToJSON,
} from './EligibilityCheck';

/**
 * 
 * @export
 * @interface PerformEligibilityCheck200Response
 */
export interface PerformEligibilityCheck200Response {
    /**
     * 
     * @type {EligibilityCheck}
     * @memberof PerformEligibilityCheck200Response
     */
    data?: EligibilityCheck;
}

/**
 * Check if a given object implements the PerformEligibilityCheck200Response interface.
 */
export function instanceOfPerformEligibilityCheck200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PerformEligibilityCheck200ResponseFromJSON(json: any): PerformEligibilityCheck200Response {
    return PerformEligibilityCheck200ResponseFromJSONTyped(json, false);
}

export function PerformEligibilityCheck200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformEligibilityCheck200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : EligibilityCheckFromJSON(json['data']),
    };
}

export function PerformEligibilityCheck200ResponseToJSON(value?: PerformEligibilityCheck200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': EligibilityCheckToJSON(value.data),
    };
}

