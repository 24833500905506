/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * 
 * @export
 * @interface FindAllSourcesResponse
 */
export interface FindAllSourcesResponse {
    /**
     * 
     * @type {Array<Source>}
     * @memberof FindAllSourcesResponse
     */
    data: Array<Source>;
    /**
     * 
     * @type {number}
     * @memberof FindAllSourcesResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllSourcesResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllSourcesResponse
     */
    limit: number;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllSourcesResponse
     */
    has_next: boolean;
}

/**
 * Check if a given object implements the FindAllSourcesResponse interface.
 */
export function instanceOfFindAllSourcesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "has_next" in value;

    return isInstance;
}

export function FindAllSourcesResponseFromJSON(json: any): FindAllSourcesResponse {
    return FindAllSourcesResponseFromJSONTyped(json, false);
}

export function FindAllSourcesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllSourcesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(SourceFromJSON)),
        'total': json['total'],
        'page': json['page'],
        'limit': json['limit'],
        'has_next': json['has_next'],
    };
}

export function FindAllSourcesResponseToJSON(value?: FindAllSourcesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(SourceToJSON)),
        'total': value.total,
        'page': value.page,
        'limit': value.limit,
        'has_next': value.has_next,
    };
}

