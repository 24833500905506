import { Source, SourceContact, SourceContactTag } from '@/types/crm-screener';

export class ScreenerSourceContactModel implements SourceContact {
  email?: string | undefined;
  id: string;
  name: string;
  phone_number?: string | null | undefined;
  position?: string | undefined;
  primary_contact: boolean;
  source_id: string;
  tag?: SourceContactTag | undefined;
  tag_id?: string | null | undefined;
  source?: Source | undefined;

  constructor(payload: SourceContact) {
    Object.assign(this, payload);
  }

  get fullName(): string {
    return this.name.trim();
  }

  get initials(): string {
    return `${this.name?.[0] ?? ''}`.toUpperCase();
  }
}
