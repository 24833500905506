import React, { ReactNode } from 'react';
import {
  useController,
  UseControllerProps,
  UseFormReturn
} from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';

interface ControlledAutoCompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<
    AutocompleteProps<T | any, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  renderInput?: AutocompleteProps<
    T | string,
    Multiple,
    DisableClearable,
    FreeSolo
  >['renderInput'];
  label?: ReactNode;
  name: string;
  control: UseFormReturn<any>['control'];
  rules?: UseControllerProps['rules'];
  helperText?: string | React.ReactNode;
}

/**
 *
 * @deprecated Use ControlledAutocompleteV2 component instead
 */
export default function ControlledAutoComplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  name, //Important: name is the field to be retrieved from options, not the name in useForm<T>
  label,
  control,
  rules,
  helperText,
  loading,
  ...props
}: ControlledAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control, rules });
  const renderLabel = () => {
    if (loading) {
      return <CircularProgress size={20} />;
    }
    if (rules?.required && label) {
      return (
        <Typography component="span" variant="inherit">
          {label}
          <Typography
            component="span"
            variant="inherit"
            style={{ color: '#DD3730' }}>
            *
          </Typography>
        </Typography>
      );
    }
    if (label) {
      return label;
    }
    return null;
  };
  return (
    <Autocomplete
      {...field}
      value={field.value || (props.multiple ? [] : null)}
      onChange={(_, value) => field.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={error ? error.message : helperText}
          error={!!error}
          label={renderLabel()}
        />
      )}
      {...props}
    />
  );
}
