import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const CRMResidents = loadable(() => import('@/pages/CRM/ScreenerResidents/AllScreenerResidents')); // prettier-ignore
const CrmResidentAllResidents = loadable(() => import('@/pages/CRM/ScreenerResidents/AllScreenerResidents/AllScreenerResidents')); // prettier-ignore
const CrmResidentTargetMoveIns = loadable(() => import('@/pages/CRM/ScreenerResidents/ScheduledOccupancies/AllScreenerResidentTargetMoveIns')); // prettier-ignore
const CrmResidentScheduledMoveOuts = loadable(() => import('@/pages/CRM/ScreenerResidents/ScheduledOccupancies/AllScreenerResidentScheduledMoveOuts')); // prettier-ignore
const OccupancyHistory = loadable(() => import('@/pages/CRM/ScreenerResidents/OccupancyHistory')); // prettier-ignore

export enum CRMScreenerResidentsRoutes {
  CrmScreenerResidents = '/crm/screener-residents',
  CrmScreenerResidentsAllResidents = '/crm/screener-residents/residents',
  CrmScreenerResidentsOccupancyHistory = '/crm/screener-residents/occupancy-history',
  CrmScreenerResidentsTargetMoveIns = '/crm/screener-residents/target-move-ins',
  CrmScreenerResidentsScheduledMoveOuts = '/crm/screener-residents/scheduled-move-outs'
}

export const getCrmScreenerResidentsRoutes = (): RouteObject[] => {
  if (!FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)) {
    return [];
  }

  return [
    {
      path: CRMScreenerResidentsRoutes.CrmScreenerResidents,
      element: <CRMResidents />,
      children: [
        {
          path: CRMScreenerResidentsRoutes.CrmScreenerResidentsAllResidents,
          element: <CrmResidentAllResidents />
        },
        {
          path: CRMScreenerResidentsRoutes.CrmScreenerResidentsOccupancyHistory,
          element: <OccupancyHistory />
        },
        {
          path: CRMScreenerResidentsRoutes.CrmScreenerResidentsTargetMoveIns,
          element: <CrmResidentTargetMoveIns />
        },
        {
          path: CRMScreenerResidentsRoutes.CrmScreenerResidentsScheduledMoveOuts,
          element: <CrmResidentScheduledMoveOuts />
        }
      ]
    }
  ];
};
