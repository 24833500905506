/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BedOccupancyStatsResponseGenderDistribution
 */
export interface BedOccupancyStatsResponseGenderDistribution {
    /**
     * Number of male leads
     * @type {number}
     * @memberof BedOccupancyStatsResponseGenderDistribution
     */
    male_count: number;
    /**
     * Number of female leads
     * @type {number}
     * @memberof BedOccupancyStatsResponseGenderDistribution
     */
    female_count: number;
    /**
     * Number of leads with other gender
     * @type {number}
     * @memberof BedOccupancyStatsResponseGenderDistribution
     */
    other_count: number;
}

/**
 * Check if a given object implements the BedOccupancyStatsResponseGenderDistribution interface.
 */
export function instanceOfBedOccupancyStatsResponseGenderDistribution(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "male_count" in value;
    isInstance = isInstance && "female_count" in value;
    isInstance = isInstance && "other_count" in value;

    return isInstance;
}

export function BedOccupancyStatsResponseGenderDistributionFromJSON(json: any): BedOccupancyStatsResponseGenderDistribution {
    return BedOccupancyStatsResponseGenderDistributionFromJSONTyped(json, false);
}

export function BedOccupancyStatsResponseGenderDistributionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BedOccupancyStatsResponseGenderDistribution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'male_count': json['male_count'],
        'female_count': json['female_count'],
        'other_count': json['other_count'],
    };
}

export function BedOccupancyStatsResponseGenderDistributionToJSON(value?: BedOccupancyStatsResponseGenderDistribution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'male_count': value.male_count,
        'female_count': value.female_count,
        'other_count': value.other_count,
    };
}

