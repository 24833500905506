import {
  InsurancePayor,
  ScreenerInsurance,
  ScreenerInsuranceCoveragePriorityEnum,
  ScreenerInsuranceInovalonPayer,
  ScreenerInsuranceTypeEnum
} from '@/types/crm-screener';

const ABBREVIATED_PAYER_TYPES = {
  [ScreenerInsuranceTypeEnum.Medicare]: 'Medicare',
  [ScreenerInsuranceTypeEnum.Medicaid]: 'Medicaid',
  [ScreenerInsuranceTypeEnum.Commercial]: 'Commercial',
  [ScreenerInsuranceTypeEnum.ManagedMedicare]: 'Mng. Medicare',
  [ScreenerInsuranceTypeEnum.ManagedMedicaid]: 'Mng. Medicaid',
  [ScreenerInsuranceTypeEnum.Other]: 'Other'
};

const PayersRequiringName = new Set<ScreenerInsuranceTypeEnum>([
  ScreenerInsuranceTypeEnum.Commercial,
  ScreenerInsuranceTypeEnum.ManagedMedicare,
  ScreenerInsuranceTypeEnum.ManagedMedicaid,
  ScreenerInsuranceTypeEnum.Other
]);
export class ScreenerInsuranceModel implements ScreenerInsurance {
  id?: string;
  lead_id: string;
  payor: InsurancePayor;
  type?: ScreenerInsuranceTypeEnum;
  state?: string;
  coverage_priority: ScreenerInsuranceCoveragePriorityEnum;
  plan_name?: string;
  medicare_number?: string;
  medicaid_number?: string;
  plan_number?: string;
  group_number?: string;
  is_overridden_by_user: boolean;
  inovalon_payer?: ScreenerInsuranceInovalonPayer;
  constructor(payload: ScreenerInsurance) {
    Object.assign(this, payload);
  }

  public isPrimary(): boolean {
    return (
      this.coverage_priority === ScreenerInsuranceCoveragePriorityEnum.Primary
    );
  }

  public getAbbreviatedPayerType(): string {
    if (!this.type) {
      return '';
    }
    return ABBREVIATED_PAYER_TYPES[this.type];
  }

  public shouldShowPayerName(): boolean {
    if (!this.type) {
      return false;
    }
    return PayersRequiringName.has(this.type) && Boolean(this.payor?.name);
  }
}
