import {
  LeadScreenerLeadInfo,
  LeadScreenerLeadInfoContactMethodPreferenceEnum,
  LeadScreenerLeadInfoMaritalStatusEnum
} from '@/types/crm-screener/models/LeadScreenerLeadInfo';

export class LeadInfoModel implements LeadScreenerLeadInfo {
  contact_method_preference?:
    | LeadScreenerLeadInfoContactMethodPreferenceEnum
    | undefined;
  current_address?: string | undefined;
  email?: string | undefined;
  id?: string | undefined;
  marital_status?: LeadScreenerLeadInfoMaritalStatusEnum | undefined;
  phone_number?: string | undefined;
  photo_url?: string | undefined;
  primary_language?: string | undefined;
  s3_photo_key?: string | undefined;
  constructor(payload: LeadScreenerLeadInfo) {
    Object.assign(this, payload);
  }
}
