import React, { ReactNode } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme
} from '@mui/material';

interface FieldProps {
  label: string | ReactNode;
  value: boolean | string;
  isValueNumber?: boolean;
}

export function ControlledRadioGroup<TFields extends FieldValues>({
  control,
  onChange,
  fields,
  label,
  name,
  rules,
  sx = {},
  disabled,
  row = true,
  isValueNumber = false
}: React.PropsWithChildren<
  Pick<UseControllerProps<TFields>, 'control' | 'rules' | 'name'> & {
    fields: FieldProps[];
    onChange?: (arg: any) => void;
    label?: string;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    row?: boolean;
    isValueNumber?: boolean;
  }
>) {
  const {
    field: { onChange: onChangeField, value: fieldValue },
    fieldState: { error }
  } = useController({
    control,
    name,
    rules
  });

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={!!error}
      sx={sx}>
      {label && (
        <FormLabel
          component="legend"
          color="secondary"
          sx={{ color: '#0A1E28', fontWeight: 500, fontSize: '14px' }}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        onChange={(e) => {
          const { value } = e.target;
          if (isValueNumber) {
            onChangeField(Number(value));
          } else {
            onChangeField(value.toLowerCase());
          }
          onChange?.(value);
        }}
        row={row}>
        {fields.map(({ value, label }, i) => {
          return (
            <FormControlLabel
              id={`mui-radio-input-${name}-${value}`}
              key={i}
              control={<Radio />}
              label={label}
              value={value}
              disabled={disabled}
              checked={String(value) == String(fieldValue)}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
