/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalIdentification } from './AdditionalIdentification';
import {
    AdditionalIdentificationFromJSON,
    AdditionalIdentificationFromJSONTyped,
    AdditionalIdentificationToJSON,
} from './AdditionalIdentification';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';

/**
 * 
 * @export
 * @interface SubscriberCoverageInfo
 */
export interface SubscriberCoverageInfo {
    /**
     * 
     * @type {DateRange}
     * @memberof SubscriberCoverageInfo
     */
    date_range?: DateRange;
    /**
     * 
     * @type {Array<AdditionalIdentification>}
     * @memberof SubscriberCoverageInfo
     */
    additional_identifications?: Array<AdditionalIdentification>;
}

/**
 * Check if a given object implements the SubscriberCoverageInfo interface.
 */
export function instanceOfSubscriberCoverageInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubscriberCoverageInfoFromJSON(json: any): SubscriberCoverageInfo {
    return SubscriberCoverageInfoFromJSONTyped(json, false);
}

export function SubscriberCoverageInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriberCoverageInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_range': !exists(json, 'date_range') ? undefined : DateRangeFromJSON(json['date_range']),
        'additional_identifications': !exists(json, 'additional_identifications') ? undefined : ((json['additional_identifications'] as Array<any>).map(AdditionalIdentificationFromJSON)),
    };
}

export function SubscriberCoverageInfoToJSON(value?: SubscriberCoverageInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_range': DateRangeToJSON(value.date_range),
        'additional_identifications': value.additional_identifications === undefined ? undefined : ((value.additional_identifications as Array<any>).map(AdditionalIdentificationToJSON)),
    };
}

