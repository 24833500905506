import React from 'react';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import {
  Chip,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material';
import dayjs from 'dayjs';
import { RRule } from 'rrule';

import { numberOfAssisantsOptions } from '@/components/CarePlanDrawer/CarePlanDrawer';
import CarePlanIndicators from '@/components/CarePlanIndicators/CarePlanIndicators';
import { generateScheduleFormDataDefaultValues } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { FrequencyTimeFormData } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  AFTERNOON_END_TIME,
  AFTERNOON_START_TIME,
  customFrequencyOption,
  EVENING_END_TIME,
  EVENING_START_TIME,
  informationalFrequencyOption,
  MORNING_END_TIME,
  MORNING_START_TIME,
  NIGHT_END,
  NIGHT_END_TIME,
  NIGHT_START,
  NIGHT_START_TIME
} from '@/components/FrequencyTimeForm/options';
import {
  carePlanDurationOptions,
  DurationOption,
  fiveTimesADayFrequencyOption,
  fourTimesADayFrequencyOption,
  threeTimesADayFrequencyOption,
  twiceADayFrequencyOption
} from '@/components/FrequencyTimeForm/options';
import { CareCategory } from '@/hooks/useCareCategoriesQuery';
import { CarePlanAssistanceLevel } from '@/hooks/useCarePlanAssistanceLevelsQuery';
import { CarePlanTaskLastCompletedPayload } from '@/hooks/useCarePlanTaskInstancesQuery';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ResidentCarePlanPayload } from '@/hooks/useResidentCarePlanQuery';
import { ResidentModel } from '@/models/ResidentModel';
import { AddResidentCarePlan } from '@/stores/residentCarePlanAtom';
import { getExecutionWindowTimeValue } from '@/utils/getExecutionWindowTimeValue';
import { transformPatternedRecurrenceToHumanText } from '@/utils/patternedRecurrenceFormatters';
import { transformRRuleStringToHumanText } from '@/utils/transformRRuleStringToHumanText';
import { transformSchedulePayloadToFrequencyTimeFormData } from '@/utils/transformSchedulePayloadToFrequencyTimeFormData';

const SmartTaskInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#1DB8F2'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1DB8F2'
  }
}));

export class ResidentCarePlanModel extends ResidentCarePlanPayload {
  resident: ResidentModel;
  careCategory: CareCategory;
  careLevel: CarePlanAssistanceLevel;
  duration: DurationOption;
  prnLastCompleted: CarePlanTaskLastCompletedPayload | null;
  responsibleParty: string | null;
  category: string | null;
  isGeneratedByAssessment: boolean | null;

  constructor(
    carePlan: ResidentCarePlanPayload,
    resident: ResidentModel,
    careCategory: CareCategory,
    careLevel: CarePlanAssistanceLevel,
    duration: DurationOption,
    prnLastCompleted: CarePlanTaskLastCompletedPayload | null
  ) {
    super(carePlan);
    this.resident = resident;
    this.careCategory = careCategory;
    this.careLevel = careLevel;
    this.duration = duration;
    this.prnLastCompleted = prnLastCompleted;
    this.responsibleParty = this.responsible_party;
    this.category = this.careCategory.title;
    this.isGeneratedByAssessment = this.is_generated_by_assessment;
  }

  public static initializeModels = (
    resident: ResidentModel,
    carePlans: ResidentCarePlanPayload[],
    prnLastCompleted: CarePlanTaskLastCompletedPayload[],
    careCategories: CareCategory[],
    careLevels: CarePlanAssistanceLevel[]
  ): ResidentCarePlanModel[] => {
    return carePlans.map((carePlan) => {
      const careCategory = careCategories.find(
        ({ id }) => id == carePlan.care_category_id
      )!;
      const careLevel = careLevels.find(
        ({ id }) => id == carePlan.care_plan_assistance_level_id
      )!;
      const prn =
        prnLastCompleted.find((v) => v?.care_plan_entry_id === carePlan.id) ??
        null;
      const duration = carePlan.is_informational
        ? {
            value: 0,
            label: 'N/A'
          }
        : carePlanDurationOptions.find(
            ({ value }) => value == carePlan.length_mins
          ) ?? carePlanDurationOptions[0];
      return new ResidentCarePlanModel(
        carePlan,
        resident,
        careCategory,
        careLevel,
        duration,
        prn
      );
    });
  };

  public isInformational = () => !this.care_plan_schedules.length;

  public getResponsibleParty = () => this.responsibleParty ?? 'N/A';

  public renderTitle = () => {
    const { care_plan_schedules, title } = this;
    const hasCarePlanSchedules = care_plan_schedules.length > 0;

    if (hasCarePlanSchedules) {
      const instanceGenerationStartDate =
        care_plan_schedules[0].instance_generation_start_date;
      const isFirstTaskInstanceGeneratedMoreThanADayAfterCurrent =
        this.isFirstTaskInstanceGeneratedDateMoreThanADayAfterCurrent(
          instanceGenerationStartDate
        );

      return this.renderCarePlanCellWithToolTip(
        title,
        isFirstTaskInstanceGeneratedMoreThanADayAfterCurrent,
        isFirstTaskInstanceGeneratedMoreThanADayAfterCurrent
          ? dayjs(instanceGenerationStartDate).format('MMM DD, YYYY')
          : undefined
      );
    }
    return this.renderCarePlanCellWithToolTip(title, false);
  };

  public renderCarePlanCellWithToolTip = (
    cell: string,
    renderChip: boolean,
    chipDate?: string
  ): React.ReactNode => {
    const isMobileViewport = useIsMobileViewport();
    return (
      <>
        {this.isGeneratedByAssessment && (
          <SmartTaskInfoTooltip
            title="Smart Task: Generated from Assessment"
            placement="top-start">
            <LightbulbIcon
              style={{
                color: '#1DB8F2',
                marginRight: '12px',
                cursor: 'pointer'
              }}
            />
          </SmartTaskInfoTooltip>
        )}
        <Stack direction="row" flexWrap="wrap" gap={1}>
          <span id={this.id}>{cell}</span>
          <CarePlanIndicators model={this} />
        </Stack>
        {renderChip && chipDate && (
          <Chip
            size={isMobileViewport ? 'small' : 'medium'}
            label={`Starts: ${chipDate}`}
            color="primary"
            sx={{ marginLeft: { xs: 0, md: '8px' } }}
          />
        )}
      </>
    );
  };

  public areSchedulesCustom = () =>
    this.care_plan_schedules.every((schedule) => {
      if (!schedule.rrule) {
        return false;
      }
      const rruleOptions = RRule.parseString(schedule.rrule);
      return rruleOptions.byweekday;
    }) && this.care_plan_schedules.length > 1;

  public areSchedulesMultipleTimesADay = () =>
    this.care_plan_schedules.every((schedule) => {
      if (!schedule.rrule) {
        return false;
      }
      const rruleOptions = RRule.parseString(schedule.rrule);
      // Custom schedules can only have a byweekday rrule option
      return !rruleOptions.byweekday && rruleOptions.interval === 1;
    }) && this.care_plan_schedules.length > 1;

  public isFirstTaskInstanceGeneratedDateMoreThanADayAfterCurrent = (
    instanceGenerationStartDate: string | undefined
  ): boolean => {
    if (!instanceGenerationStartDate) {
      return false;
    }

    const firstTaskInstanceGeneratedDate = dayjs(instanceGenerationStartDate);
    const diffInDays = firstTaskInstanceGeneratedDate.diff(dayjs(), 'day');
    return diffInDays >= 1;
  };

  private areSchedulesTwiceADay = () =>
    this.areSchedulesMultipleTimesADay() &&
    this.care_plan_schedules.length === 2;

  private areSchedulesThreeTimesADay = () =>
    this.areSchedulesMultipleTimesADay() &&
    this.care_plan_schedules.length === 3;

  private areSchedulesFourTimesADay = () =>
    this.areSchedulesMultipleTimesADay() &&
    this.care_plan_schedules.length === 4;

  private areSchedulesFiveTimesADay = () =>
    this.areSchedulesMultipleTimesADay() &&
    this.care_plan_schedules.length === 5;

  public getFrequencyValue = (): string => {
    if (!this.care_plan_schedules.length) {
      return 'Informational';
    }

    if (this.areSchedulesTwiceADay()) {
      return 'Twice a day';
    }

    if (this.areSchedulesThreeTimesADay()) {
      return 'Three times a day';
    }

    if (this.areSchedulesFourTimesADay()) {
      return 'Four times a day';
    }

    if (this.areSchedulesFiveTimesADay()) {
      return 'Five times a day';
    }

    if (this.care_plan_schedules.length > 1) {
      return 'Custom';
    }

    if (this.care_plan_schedules?.[0]?.rrule) {
      return transformRRuleStringToHumanText(
        this.care_plan_schedules?.[0]?.rrule
      );
    } else if (this.care_plan_schedules?.[0]?.patterned_recurrence) {
      return transformPatternedRecurrenceToHumanText(
        this.care_plan_schedules?.[0]?.patterned_recurrence
      );
    }
    return '';
  };

  public getTimeValue = () => this.getTime().value;

  public renderTimeCell = () => this.getTime().display;

  private getTime = (): { value: number; display: string } => {
    if (this.care_plan_schedules.length > 1 || this.is_informational) {
      return {
        value: 0,
        display: this.is_informational ? 'N/A' : 'Custom'
      };
    }
    return getExecutionWindowTimeValue({
      format: 'LLLL',
      date_completed: this.prnLastCompleted?.date_completed,
      execution_window_start_time:
        this.care_plan_schedules[0]?.execution_window_start_time,
      execution_window_end_time:
        this.care_plan_schedules[0]?.execution_window_end_time
    });
  };

  public getTimeOfTheDay = () => {
    const unixTime = this.getTime().value;
    if (this.isPrn()) {
      return 'PRN';
    } else if (
      unixTime >= MORNING_START_TIME.unixTime &&
      unixTime < MORNING_END_TIME.unixTime
    ) {
      return 'Morning';
    } else if (
      unixTime >= AFTERNOON_START_TIME.unixTime &&
      unixTime < AFTERNOON_END_TIME.unixTime
    ) {
      return 'Afternoon';
    } else if (
      unixTime >= EVENING_START_TIME.unixTime &&
      unixTime < EVENING_END_TIME.unixTime
    ) {
      return 'Evening';
    } else if (
      unixTime >= NIGHT_START.unixTime &&
      unixTime < NIGHT_END.unixTime
    ) {
      return 'Night';
    } else if (
      unixTime >= NIGHT_START_TIME.unixTime &&
      unixTime < NIGHT_END_TIME.unixTime
    ) {
      return 'Night';
    }
    return '';
  };

  public isPrn = () => {
    try {
      if (!this.care_plan_schedules[0].rrule) {
        return false;
      }
      const { count, interval } = RRule.parseString(
        this.care_plan_schedules[0].rrule
      );
      return count === 0 && interval === 0;
    } catch (e) {
      return false;
    }
  };

  public transformToFormData = (): AddResidentCarePlan => {
    let frequency = {} as FrequencyTimeFormData;
    const scheduleForm = generateScheduleFormDataDefaultValues();

    if (this.care_plan_schedules.length > 1) {
      frequency.freq = this.areSchedulesTwiceADay()
        ? twiceADayFrequencyOption.value
        : this.areSchedulesThreeTimesADay()
        ? threeTimesADayFrequencyOption.value
        : this.areSchedulesFourTimesADay()
        ? fourTimesADayFrequencyOption.value
        : this.areSchedulesFiveTimesADay()
        ? fiveTimesADayFrequencyOption.value
        : customFrequencyOption.value;
      this.care_plan_schedules.forEach((schedule) => {
        const { dtstart, until } =
          transformSchedulePayloadToFrequencyTimeFormData(
            schedule,
            scheduleForm
          );
        // HACK: Set the top level frequency's dtstart and until to one of the schedule rrule's dtstart and until.
        // Each schedule should have the same dtstart and until, so it doesn't matter which one we pick.
        frequency.dtstart = dtstart;
        frequency.until = until;
      });
    } else if (this.care_plan_schedules.length === 1) {
      frequency = transformSchedulePayloadToFrequencyTimeFormData(
        this.care_plan_schedules[0]
      );
    } else if (this.care_plan_schedules.length === 0) {
      frequency.freq = informationalFrequencyOption.value;
    }

    return {
      id: this.id,
      careCategory: this.careCategory,
      careLevel: this.careLevel,
      title: this.title,
      instructions: this.instructions,
      preferences: this.preferences,
      frequency,
      duration: this.duration,
      schedule: scheduleForm,
      responsible_party: this.responsible_party_id || this.responsible_party,
      numAssistants: numberOfAssisantsOptions.find(
        ({ value }) => value.toString() == this.number_of_assistants
      )!,
      responsible_party_id: this.responsible_party_id,
      extensionEnabled: this.task_extension != null,
      task_extension: this.task_extension || undefined
    };
  };
}
