/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenericBenefit } from './GenericBenefit';
import {
    GenericBenefitFromJSON,
    GenericBenefitFromJSONTyped,
    GenericBenefitToJSON,
} from './GenericBenefit';

/**
 * 
 * @export
 * @interface ActiveCoverageOtherPayers
 */
export interface ActiveCoverageOtherPayers {
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof ActiveCoverageOtherPayers
     */
    sections?: Array<GenericBenefit>;
}

/**
 * Check if a given object implements the ActiveCoverageOtherPayers interface.
 */
export function instanceOfActiveCoverageOtherPayers(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActiveCoverageOtherPayersFromJSON(json: any): ActiveCoverageOtherPayers {
    return ActiveCoverageOtherPayersFromJSONTyped(json, false);
}

export function ActiveCoverageOtherPayersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveCoverageOtherPayers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(GenericBenefitFromJSON)),
    };
}

export function ActiveCoverageOtherPayersToJSON(value?: ActiveCoverageOtherPayers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(GenericBenefitToJSON)),
    };
}

