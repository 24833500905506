/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateLeadInfoRequest } from './CreateLeadInfoRequest';
import {
    CreateLeadInfoRequestFromJSON,
    CreateLeadInfoRequestFromJSONTyped,
    CreateLeadInfoRequestToJSON,
} from './CreateLeadInfoRequest';

/**
 * 
 * @export
 * @interface CreateLeadInfo201Response
 */
export interface CreateLeadInfo201Response {
    /**
     * 
     * @type {CreateLeadInfoRequest}
     * @memberof CreateLeadInfo201Response
     */
    data?: CreateLeadInfoRequest;
}

/**
 * Check if a given object implements the CreateLeadInfo201Response interface.
 */
export function instanceOfCreateLeadInfo201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateLeadInfo201ResponseFromJSON(json: any): CreateLeadInfo201Response {
    return CreateLeadInfo201ResponseFromJSONTyped(json, false);
}

export function CreateLeadInfo201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeadInfo201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CreateLeadInfoRequestFromJSON(json['data']),
    };
}

export function CreateLeadInfo201ResponseToJSON(value?: CreateLeadInfo201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateLeadInfoRequestToJSON(value.data),
    };
}

