/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActiveCoverageOtherPayers } from './ActiveCoverageOtherPayers';
import {
    ActiveCoverageOtherPayersFromJSON,
    ActiveCoverageOtherPayersFromJSONTyped,
    ActiveCoverageOtherPayersToJSON,
} from './ActiveCoverageOtherPayers';
import type { GenericBenefit } from './GenericBenefit';
import {
    GenericBenefitFromJSON,
    GenericBenefitFromJSONTyped,
    GenericBenefitToJSON,
} from './GenericBenefit';
import type { SubscriberCoverageInfo } from './SubscriberCoverageInfo';
import {
    SubscriberCoverageInfoFromJSON,
    SubscriberCoverageInfoFromJSONTyped,
    SubscriberCoverageInfoToJSON,
} from './SubscriberCoverageInfo';

/**
 * 
 * @export
 * @interface ActiveCoverage
 */
export interface ActiveCoverage {
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof ActiveCoverage
     */
    sections: Array<GenericBenefit>;
    /**
     * 
     * @type {SubscriberCoverageInfo}
     * @memberof ActiveCoverage
     */
    subscriber_coverage_info?: SubscriberCoverageInfo;
    /**
     * 
     * @type {ActiveCoverageOtherPayers}
     * @memberof ActiveCoverage
     */
    other_payers?: ActiveCoverageOtherPayers;
    /**
     * 
     * @type {Array<GenericBenefit>}
     * @memberof ActiveCoverage
     */
    qmb?: Array<GenericBenefit>;
}

/**
 * Check if a given object implements the ActiveCoverage interface.
 */
export function instanceOfActiveCoverage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sections" in value;

    return isInstance;
}

export function ActiveCoverageFromJSON(json: any): ActiveCoverage {
    return ActiveCoverageFromJSONTyped(json, false);
}

export function ActiveCoverageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveCoverage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': ((json['sections'] as Array<any>).map(GenericBenefitFromJSON)),
        'subscriber_coverage_info': !exists(json, 'subscriber_coverage_info') ? undefined : SubscriberCoverageInfoFromJSON(json['subscriber_coverage_info']),
        'other_payers': !exists(json, 'other_payers') ? undefined : ActiveCoverageOtherPayersFromJSON(json['other_payers']),
        'qmb': !exists(json, 'qmb') ? undefined : ((json['qmb'] as Array<any>).map(GenericBenefitFromJSON)),
    };
}

export function ActiveCoverageToJSON(value?: ActiveCoverage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': ((value.sections as Array<any>).map(GenericBenefitToJSON)),
        'subscriber_coverage_info': SubscriberCoverageInfoToJSON(value.subscriber_coverage_info),
        'other_payers': ActiveCoverageOtherPayersToJSON(value.other_payers),
        'qmb': value.qmb === undefined ? undefined : ((value.qmb as Array<any>).map(GenericBenefitToJSON)),
    };
}

