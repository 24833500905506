/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ServiceTypeDetailItem } from './ServiceTypeDetailItem';
import {
    ServiceTypeDetailItemFromJSON,
    ServiceTypeDetailItemFromJSONTyped,
    ServiceTypeDetailItemToJSON,
} from './ServiceTypeDetailItem';

/**
 * 
 * @export
 * @interface ServiceTypeDetails
 */
export interface ServiceTypeDetails {
    /**
     * 
     * @type {string}
     * @memberof ServiceTypeDetails
     */
    service_type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTypeDetails
     */
    service_type_code_definition?: string;
    /**
     * 
     * @type {ServiceTypeDetailItem}
     * @memberof ServiceTypeDetails
     */
    details?: ServiceTypeDetailItem;
}

/**
 * Check if a given object implements the ServiceTypeDetails interface.
 */
export function instanceOfServiceTypeDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceTypeDetailsFromJSON(json: any): ServiceTypeDetails {
    return ServiceTypeDetailsFromJSONTyped(json, false);
}

export function ServiceTypeDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceTypeDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service_type_code': !exists(json, 'service_type_code') ? undefined : json['service_type_code'],
        'service_type_code_definition': !exists(json, 'service_type_code_definition') ? undefined : json['service_type_code_definition'],
        'details': !exists(json, 'details') ? undefined : ServiceTypeDetailItemFromJSON(json['details']),
    };
}

export function ServiceTypeDetailsToJSON(value?: ServiceTypeDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service_type_code': value.service_type_code,
        'service_type_code_definition': value.service_type_code_definition,
        'details': ServiceTypeDetailItemToJSON(value.details),
    };
}

