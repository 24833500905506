/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestError
 */
export interface RequestError {
    /**
     * 
     * @type {string}
     * @memberof RequestError
     */
    follow_up_action_code?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestError
     */
    follow_up_action_code_definition?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestError
     */
    reason_code?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestError
     */
    reason_code_definition?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestError
     */
    valid_request?: boolean;
}

/**
 * Check if a given object implements the RequestError interface.
 */
export function instanceOfRequestError(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestErrorFromJSON(json: any): RequestError {
    return RequestErrorFromJSONTyped(json, false);
}

export function RequestErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'follow_up_action_code': !exists(json, 'follow_up_action_code') ? undefined : json['follow_up_action_code'],
        'follow_up_action_code_definition': !exists(json, 'follow_up_action_code_definition') ? undefined : json['follow_up_action_code_definition'],
        'reason_code': !exists(json, 'reason_code') ? undefined : json['reason_code'],
        'reason_code_definition': !exists(json, 'reason_code_definition') ? undefined : json['reason_code_definition'],
        'valid_request': !exists(json, 'valid_request') ? undefined : json['valid_request'],
    };
}

export function RequestErrorToJSON(value?: RequestError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'follow_up_action_code': value.follow_up_action_code,
        'follow_up_action_code_definition': value.follow_up_action_code_definition,
        'reason_code': value.reason_code,
        'reason_code_definition': value.reason_code_definition,
        'valid_request': value.valid_request,
    };
}

