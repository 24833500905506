import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import omitBy from 'lodash/omitBy';

import { fetchMedications } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import {
  CustomMedicationModel,
  CustomMedicationPayload
} from '@/pages/CRM/models/CustomMedicationModel';

const USE_CUSTOM_MEDICATION_FIND_ONE_QUERY_KEY = [
  'useCustomMedicationsFindOne'
];
const CUSTOM_MEDICATION_FIND_ALL_QUERY_KEY = ['customMedicationsFindAllByPage'];
const CUSTOM_MEDICATION_FIND_ALL_INFINITE_QUERY_KEY = [
  'customMedicationsFindAllInfinite'
];

export const invalidateCustomMedications = () => {
  queryClient.invalidateQueries(USE_CUSTOM_MEDICATION_FIND_ONE_QUERY_KEY);
  queryClient.invalidateQueries(CUSTOM_MEDICATION_FIND_ALL_QUERY_KEY);
  queryClient.invalidateQueries(CUSTOM_MEDICATION_FIND_ALL_INFINITE_QUERY_KEY);
};

interface CustomMedicationsFindAllByPageParams {
  options?: UseQueryOptions<any>;
  page?: number;
  limit?: number;
  search?: string;
  facility_id?: string;
  residents?: boolean;
}

interface CustomMedicationsPage {
  page: number;
  limit: number;
  hasNextPage: boolean;
  data: CustomMedicationModel[];
  total: number;
}

export const FIND_ALL_CUSTOM_MEDICATIONS_DEFAULT_LIMIT = 20;

export const useCustomMedicationsQuery = () => {
  return {
    findAll: ({
      page,
      limit,
      search,
      facility_id,
      residents = false,
      options = {}
    }: CustomMedicationsFindAllByPageParams) =>
      useQuery<CustomMedicationsPage>({
        queryKey: [
          ...CUSTOM_MEDICATION_FIND_ALL_QUERY_KEY,
          {
            facility_id,
            page,
            limit,
            search,
            residents
          }
        ],
        queryFn: async () => {
          const medications = await fetchMedications.get<CustomMedicationsPage>(
            '/custom-medications',
            {
              searchParams: {
                facility_id,
                page,
                limit,
                search,
                residents
              }
            }
          );
          return {
            ...medications,
            data: medications.data.map(
              (payload) => new CustomMedicationModel(payload)
            )
          };
        },
        enabled: !!facility_id,
        ...options
      }),
    findAllInfinite: (params: CustomMedicationsFindAllByPageParams) =>
      useInfiniteQuery<CustomMedicationsPage>(
        [...CUSTOM_MEDICATION_FIND_ALL_INFINITE_QUERY_KEY, params],
        async ({ pageParam }) => {
          const searchParams = {
            facility_id: params.facility_id,
            page: params.page,
            limit: params.limit,
            ...(params.search &&
              params.search.trim() !== '' && {
                search: params.search
              }),
            ...(params.residents && {
              residents: true
            })
          };
          if (pageParam) {
            searchParams.page = pageParam.page + 1;
          } else {
            searchParams.page = 1;
          }
          const medications = await fetchMedications.get<CustomMedicationsPage>(
            '/custom-medications',
            {
              searchParams
            }
          );
          return {
            ...medications,
            data: medications.data.map(
              (payload) => new CustomMedicationModel(payload)
            )
          };
        },
        {
          getNextPageParam: (lastPage) => {
            if (!lastPage?.hasNextPage) {
              return false;
            }
            return lastPage;
          },
          enabled: !!params.facility_id,
          ...params.options
        }
      ),

    findOne: (
      id?: string | null,
      options: UseQueryOptions<CustomMedicationModel> = {}
    ) =>
      useQuery(
        [...USE_CUSTOM_MEDICATION_FIND_ONE_QUERY_KEY, id],
        async () => {
          return new CustomMedicationModel(
            await fetchMedications.get<CustomMedicationPayload>(
              `/custom-medications/${id}`
            )
          );
        },
        {
          enabled: !!id,
          ...(options as any)
        }
      ),

    mutations: () => ({
      create: useMutation(
        (payload: Partial<CustomMedicationPayload>) => {
          return fetchMedications.post<CustomMedicationPayload>(
            '/custom-medications',
            {
              ...payload,
              dosage:
                payload.dosage && !isNaN(parseInt(payload.dosage.toString()))
                  ? payload.dosage
                  : null,
              dosage_unit:
                payload.dosage_unit && payload.dosage_unit.trim().length > 0
                  ? payload.dosage_unit
                  : null
            }
          );
        },
        {
          onSuccess: () => {
            invalidateCustomMedications();
          }
        }
      ),

      update: useMutation(
        (payload: Partial<CustomMedicationPayload>) => {
          return fetchMedications.put<CustomMedicationPayload>(
            `/custom-medications/${payload.id}`,
            omitBy(payload, 'id')
          );
        },
        {
          onSuccess: () => {
            invalidateCustomMedications();
          }
        }
      ),

      delete: useMutation(
        (id: string) => {
          return fetchMedications.delete(`/custom-medications/${id}`);
        },
        {
          onSuccess: () => {
            invalidateCustomMedications();
          }
        }
      )
    })
  };
};
