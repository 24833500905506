/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestInfoProvider
 */
export interface RequestInfoProvider {
    /**
     * Provider identifier
     * @type {number}
     * @memberof RequestInfoProvider
     */
    provider_id?: number;
    /**
     * Provider name
     * @type {string}
     * @memberof RequestInfoProvider
     */
    provider_name?: string;
}

/**
 * Check if a given object implements the RequestInfoProvider interface.
 */
export function instanceOfRequestInfoProvider(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestInfoProviderFromJSON(json: any): RequestInfoProvider {
    return RequestInfoProviderFromJSONTyped(json, false);
}

export function RequestInfoProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestInfoProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider_id': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'provider_name': !exists(json, 'provider_name') ? undefined : json['provider_name'],
    };
}

export function RequestInfoProviderToJSON(value?: RequestInfoProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider_id': value.provider_id,
        'provider_name': value.provider_name,
    };
}

