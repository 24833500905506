import React from 'react';
import { WorkOff } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  Chip,
  createFilterOptions,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import {
  A_PLACE_FOR_MOM_INTEGRATION_NAME,
  A_PLACE_FOR_MOM_LOGO_URL
} from '@/components/ResidentFacesheet/APlaceForMom/EventLogMappings';
import { ProspectResidentAvatarTemperature } from '@/components/ui/ProspectResidentAvatarTemperature/ProspectResidentAvatarTemperature';
import {
  CohabitantPayload,
  ResidentPayload
} from '@/hooks/useResidentFacesheetsQuery';
import { UserPayload } from '@/hooks/useUserQuery';
import { getCohabitantText, ResidentModel } from '@/models/ResidentModel';
import { UserModel } from '@/models/UserModel';
import {
  ReferralSourceCompanyModel,
  ReferralSourceCompanyPayload
} from '@/pages/CRM/models/ReferralSourceCompanyModel';
import { AddProspectResidentFormData } from '@/pages/CRM/ProspectsPage/ProspectResidents/AddProspectResidentForm';
import theme from '@/theme';
import { DealTemperature } from '@/types/crm-campaigns';

import { ReferralModel, ReferralPayload } from './ReferralModel';
import {
  ResidentCloseConnectionModel,
  ResidentCloseConnectionPayload
} from './ResidentCloseConnectionModel';

export type LifecycleStep =
  | 'Opportunity'
  | 'Connected'
  | 'Pre-Tour'
  | 'Post-Tour'
  | 'Deposit'
  | 'Pre-Arrival'
  | 'Move In';

export type ProspectStage =
  | 'Opportunity'
  | 'Connected'
  | 'Pre-Tour'
  | 'Post-Tour'
  | 'Deposit'
  | 'Pre-Arrival'
  | 'Move In'
  | 'Resident'
  | 'Archived Deal';

export const prospectStageOptions: ProspectStage[] = [
  'Opportunity',
  'Connected',
  'Pre-Tour',
  'Post-Tour',
  'Deposit',
  'Pre-Arrival'
];

export const originInboundToDisplayMapping: Record<
  CrmLeadInfoOriginInboundType,
  string
> = {
  PROSPECTIVE_RESIDENT: 'Prospective Resident',
  RESIDENT_CLOSE_CONNECTION: 'Resident Family Or Friend',
  REFERRAL_SOURCE: 'Referral Source',
  REFERRAL_SOURCE_COMPANY: 'Referral Source Company'
};

export const originOutboundToDisplayMapping: Record<
  CrmLeadInfoOriginOutboundType,
  string
> = {
  PROSPECTIVE_RESIDENT: 'Prospective Resident',
  RESIDENT_CLOSE_CONNECTION: 'Resident Family Or Friend',
  REFERRAL_SOURCE: 'Referral Source',
  REFERRAL_SOURCE_COMPANY: 'Referral Source Company'
};

export enum CrmLeadInfoDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  FromEHR = 'From EHR'
}

export enum CrmLeadInfoOriginInboundType {
  PROSPECTIVE_RESIDENT = 'PROSPECTIVE_RESIDENT',
  RESIDENT_CLOSE_CONNECTION = 'RESIDENT_CLOSE_CONNECTION',
  REFERRAL_SOURCE = 'REFERRAL_SOURCE',
  REFERRAL_SOURCE_COMPANY = 'REFERRAL_SOURCE_COMPANY'
}

export enum CrmLeadInfoOriginOutboundType {
  PROSPECTIVE_RESIDENT = 'PROSPECTIVE_RESIDENT',
  RESIDENT_CLOSE_CONNECTION = 'RESIDENT_CLOSE_CONNECTION',
  REFERRAL_SOURCE = 'REFERRAL_SOURCE',
  REFERRAL_SOURCE_COMPANY = 'REFERRAL_SOURCE_COMPANY'
}

export enum CrmLeadInfoHeardType {
  REFERRAL_SOURCE = 'REFERRAL_SOURCE',
  REFERRAL_SOURCE_COMPANY = 'REFERRAL_SOURCE_COMPANY',
  VIA_OUTBOUND = 'VIA_OUTBOUND',
  SURVEY = 'SURVEY'
}

enum CrmLeadInfoInquiryType {
  AssistedLiving = 'Assisted Living',
  IndependentLiving = 'Independent Living',
  HomeCare = 'Home Care',
  SkilledNursing = 'Skilled Nursing',
  MemoryCare = 'Memory Care',
  Medicaid = 'Medicaid',
  Unknown = 'Unknown'
}

type CrmLeadInfoInquiryTypeKeys = Lowercase<CrmLeadInfoInquiryType>;

type CrmLeadInfoInquiryTypeAbrvType = {
  [key in CrmLeadInfoInquiryTypeKeys]: string;
};

const CrmLeadInfoInquiryTypeAbrv: CrmLeadInfoInquiryTypeAbrvType = {
  'assisted living': 'AL',
  'independent living': 'IL',
  'home care': 'HC',
  'skilled nursing': 'SN',
  'memory care': 'MC',
  medicaid: 'MEDCD',
  unknown: 'UNKN'
};

export const inquiryTypeSelectOptions = Object.values(CrmLeadInfoInquiryType);

interface CrmLeadOriginHeardSurvey {
  id: string;
  organization_id: string;
  facility_id: string;
  name: string;
  createdAt: string;
  updatedAt: string | null;
}

export const prospectsAutocompleteFilterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: ProspectResidentModel) =>
    `${option.fullName}${option.resident.email}`
});

export class CrmLeadInfoPayload {
  id: string;
  direction: CrmLeadInfoDirection;
  origin_outbound_type: CrmLeadInfoOriginOutboundType | null;
  origin_inbound_type: CrmLeadInfoOriginInboundType | null;
  origin_close_connection_id: string | null;
  origin_referral_source_id: string | null;
  origin_referral_source_company_id: string | null;
  origin_heard_type: CrmLeadInfoHeardType | null;
  origin_heard_referral_source_id: string | null;
  origin_heard_referral_source_company_id: string | null;
  origin_heard_survey_id: string | null;
  inquiry_type: CrmLeadInfoInquiryType;
  createdAt: string;
  updatedAt: string | null;
  crm_lead_origin_heard_survey?: CrmLeadOriginHeardSurvey | null;
  origin_close_connection?: ResidentCloseConnectionPayload | null;
  close_connection: ResidentCloseConnectionPayload;
  origin_referral_source?: ReferralPayload | null;
  origin_referral_source_company?: ReferralSourceCompanyPayload | null;
  origin_heard_referral_source?: ReferralPayload | null;
  origin_heard_referral_source_company?: ReferralSourceCompanyPayload | null;
  integration?: string;

  constructor(payload: CrmLeadInfoPayload) {
    Object.assign(this, payload);
  }
}

export class CrmLeadInfoModel extends CrmLeadInfoPayload {
  declare origin_close_connection: ResidentCloseConnectionModel;
  declare origin_referral_source: ReferralModel;
  declare origin_referral_source_company: ReferralSourceCompanyModel;
  declare origin_heard_referral_source: ReferralModel;
  declare origin_heard_referral_source_company: ReferralSourceCompanyModel;

  constructor(payload: CrmLeadInfoPayload) {
    super(payload);
    if (this.origin_referral_source) {
      this.origin_referral_source = new ReferralModel(
        payload.origin_referral_source!
      );
    }
    if (this.origin_referral_source_company) {
      this.origin_referral_source_company = new ReferralSourceCompanyModel(
        payload.origin_referral_source_company!
      );
    }
    if (this.origin_close_connection) {
      this.origin_close_connection = new ResidentCloseConnectionModel(
        payload.origin_close_connection!
      );
    }
    if (this.origin_heard_referral_source) {
      this.origin_heard_referral_source = new ReferralModel(
        payload.origin_heard_referral_source!
      );
    }
    if (this.origin_heard_referral_source_company) {
      this.origin_heard_referral_source_company =
        new ReferralSourceCompanyModel(
          payload.origin_heard_referral_source_company!
        );
    }
  }
}

export enum LostDealReason {
  CostAndAffordability = 'Cost and Affordability',
  Location = 'Location',
  NeedsExceedOurServices = 'Needs Exceeded Our Services',
  LackOfAvailability = 'Lack of Availability',
  CulturalOrReligiousReasons = 'Cultural or Religious Reasons',
  Unresponsive = 'Unresponsive',
  Unknown = 'Unknown',
  Deceased = 'Deceased',
  Duplicate = 'Duplicate',
  Other = 'Other'
}

export class ProspectResidentPayload {
  id: string;
  first_name: string;
  last_name: string;
  lead_owner_id: string;
  current_address: string;
  main_point_of_contact:
    | 'Resident'
    | 'Resident Family Member'
    | 'Resident Friend';
  stage: ProspectStage;
  lifecycle_step: LifecycleStep;
  lost_deal: boolean;
  createdAt: string;
  updatedAt: string;
  resident: ResidentPayload;
  lead_owner: UserPayload;
  crm_lead_info: CrmLeadInfoPayload | null;
  lead_info_id: string;
  new_opportunity_date: string;
  target_move_in_date: string;
  in_waitlist: boolean | null;
  waitlist_rank: number | null;
  lost_deal_reason: LostDealReason | null;
  lost_deal_reason_info: string | null;
  temperature: DealTemperature;
  next_resident_id?: string | null;
  previous_resident_id?: string | null;
  close_connection?: ResidentCloseConnectionPayload;
  is_from_ehr?: boolean;
  is_duplicate?: boolean;

  constructor(payload: ProspectResidentPayload) {
    Object.assign(this, payload);
  }

  get fullName(): string {
    if (!this.resident.first_name) {
      return 'Unknown';
    }
    return `${this.resident.first_name} ${this.resident.last_name}`;
  }

  get initials(): string {
    if (!this.resident.first_name) {
      return '';
    }
    return `${this.resident.first_name?.charAt(
      0
    )}${this.resident.last_name?.charAt(0)}`;
  }
}

export class ProspectResidentUpdatePayload extends ProspectResidentPayload {
  dob?: string;
  service_start_date?: string;
  tour_date?: string | null;
  ignore_duplicate?: boolean;

  constructor(payload: ProspectResidentUpdatePayload) {
    super(payload);
    Object.assign(this, payload);
  }
}

interface RenderListItemProps {
  ListItemProps?: ListItemProps;
  ListItemTextProps?: ListItemTextProps;
  isUnknown?: boolean;
  isMobile?: boolean;
}

export class ProspectResidentModel extends ProspectResidentPayload {
  declare resident: ResidentModel;
  declare lead_owner: UserModel;
  declare cohabitant: ResidentModel;
  declare crm_lead_info: CrmLeadInfoModel | null;
  declare integration: string;

  constructor(payload: ProspectResidentPayload) {
    super(payload);
    this.resident = new ResidentModel(payload.resident);
    this.lead_owner = new UserModel(payload.lead_owner);
    if (payload.crm_lead_info) {
      this.crm_lead_info = new CrmLeadInfoModel(payload.crm_lead_info);
    }
    if (this.resident.cohabitant) {
      this.cohabitant = new ResidentModel(
        payload.resident.cohabitant! as ResidentPayload
      );
    }
  }

  private renderInquiryTypeChip = (isMobile: boolean) => {
    if (!this.crm_lead_info?.inquiry_type) {
      return null;
    }
    return (
      <Chip
        size="small"
        label={
          isMobile
            ? CrmLeadInfoInquiryTypeAbrv[
                this.crm_lead_info?.inquiry_type.toLowerCase() as keyof typeof CrmLeadInfoInquiryTypeAbrv
              ]
            : this.crm_lead_info?.inquiry_type
        }
        sx={{ background: '#E7EEF4', width: 'max-content' }}
      />
    );
  };

  public isAPFM = (): boolean => {
    return this.integration === A_PLACE_FOR_MOM_INTEGRATION_NAME;
  };

  public getReferralSource = () => {
    const { crm_lead_info } = this;
    switch (crm_lead_info?.origin_inbound_type) {
      case CrmLeadInfoOriginInboundType.RESIDENT_CLOSE_CONNECTION:
        return {
          name: `${crm_lead_info?.origin_close_connection?.first_name} ${crm_lead_info?.origin_close_connection?.last_name}`,
          relationship: 'Family/Friend'
        };
      case CrmLeadInfoOriginInboundType.PROSPECTIVE_RESIDENT:
        return {
          name: 'Prospect',
          relationship: ''
        };
      case CrmLeadInfoOriginInboundType.REFERRAL_SOURCE:
        return {
          name: `${crm_lead_info?.origin_referral_source?.first_name} ${crm_lead_info?.origin_referral_source?.last_name}`,
          relationship: 'Referral Source'
        };
      case CrmLeadInfoOriginInboundType.REFERRAL_SOURCE_COMPANY:
        return {
          name: crm_lead_info?.origin_referral_source_company.name,
          relationship: 'Referral Source Co'
        };
      default:
        return {
          name: '',
          relationship: ''
        };
    }
  };

  public toEditFormData = (): Partial<AddProspectResidentFormData> => {
    let originHeard = '';

    if (
      this.crm_lead_info?.origin_heard_type === CrmLeadInfoHeardType.SURVEY &&
      this.crm_lead_info?.origin_heard_survey_id
    ) {
      originHeard = this.crm_lead_info.origin_heard_survey_id;
    } else if (
      this.crm_lead_info?.origin_heard_type ===
      CrmLeadInfoHeardType.VIA_OUTBOUND
    ) {
      originHeard = CrmLeadInfoHeardType.VIA_OUTBOUND;
    } else if (
      this.crm_lead_info?.origin_heard_type ===
        CrmLeadInfoHeardType.REFERRAL_SOURCE &&
      this.crm_lead_info?.origin_heard_referral_source_id
    ) {
      originHeard = CrmLeadInfoHeardType.REFERRAL_SOURCE;
    } else if (
      this.crm_lead_info?.origin_heard_type ===
        CrmLeadInfoHeardType.REFERRAL_SOURCE_COMPANY &&
      this.crm_lead_info?.origin_heard_referral_source_company_id
    ) {
      originHeard = CrmLeadInfoHeardType.REFERRAL_SOURCE_COMPANY;
    }

    return {
      facility_id: this.resident.facility_id || '',
      lead_owner_id: this.lead_owner_id || '',
      current_address: this.current_address || '',
      main_point_of_contact: this.main_point_of_contact || '',
      new_opportunity_date: this.new_opportunity_date || '',
      target_move_in_date: this.target_move_in_date || '',
      temperature: this.temperature || '',
      crm_lead_info: {
        close_connection: this.crm_lead_info?.origin_close_connection ?? {
          first_name: '',
          last_name: '',
          relationship: ''
        },
        originHeard,
        newSurvey: '',
        direction: this.crm_lead_info?.direction ?? '',
        origin_inbound_type: this.crm_lead_info?.origin_inbound_type,
        origin_outbound_type: this.crm_lead_info?.origin_outbound_type,
        origin_close_connection_id:
          this.crm_lead_info?.origin_close_connection_id ?? '',
        origin_referral_source_id:
          this.crm_lead_info?.origin_referral_source_id ?? '',
        origin_referral_source_company_id:
          this.crm_lead_info?.origin_referral_source_company_id ?? '',
        origin_heard_type: this.crm_lead_info?.origin_heard_type ?? '',
        origin_heard_referral_source_id:
          this.crm_lead_info?.origin_heard_referral_source_id ?? '',
        origin_heard_referral_source_company_id:
          this.crm_lead_info?.origin_heard_referral_source_company_id ?? '',
        origin_heard_survey_id:
          this.crm_lead_info?.origin_heard_survey_id ?? '',
        inquiry_type: this.crm_lead_info?.inquiry_type ?? ''
      },
      cohabitant_id: this.resident.cohabitant_id
    };
  };

  public renderListItem = ({
    ListItemProps = {},
    ListItemTextProps = {},
    isUnknown = false,
    isMobile = false
  }: RenderListItemProps = {}): JSX.Element => (
    <ListItem dense disableGutters disablePadding {...ListItemProps}>
      <ListItemAvatar
        sx={{
          marginBottom: this.crm_lead_info?.inquiry_type ? '20px' : '0'
        }}>
        <ProspectResidentAvatarTemperature
          residentId={this.resident.id}
          temperature={this.temperature}
          s3PhotoKey={this.resident.s3_photo_key}
          initials={this.initials}
          disabled
        />
      </ListItemAvatar>
      <Box display="flex" alignItems="normal" flexDirection="column">
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <ListItemText
            {...ListItemTextProps}
            sx={{ flex: 'unset !important' }}
          />
          {this.cohabitant &&
            !(this.cohabitant as CohabitantPayload).crm_resident_info
              ?.lost_deal && (
              <Tooltip
                placement="top"
                title={
                  <Box>
                    {getCohabitantText(this.resident)}{' '}
                    {this.cohabitant.fullName}
                  </Box>
                }>
                <IconButton size="small">
                  <PeopleIcon sx={{ color: '#9AAEBB' }} />
                </IconButton>
              </Tooltip>
            )}
          {isUnknown && (
            <Tooltip
              placement="top"
              title="No name has been recorded for this prospect">
              <IconButton size="small">
                <ErrorOutlineIcon sx={{ color: '#9AAEBB' }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Stack>{this.renderInquiryTypeChip(isMobile)}</Stack>
      </Box>
    </ListItem>
  );

  public renderSourceCell = () => {
    if (this?.integration === A_PLACE_FOR_MOM_INTEGRATION_NAME) {
      return (
        <img
          src={A_PLACE_FOR_MOM_LOGO_URL}
          alt={A_PLACE_FOR_MOM_INTEGRATION_NAME}
          style={{
            width: '94px',
            height: 'auto'
          }}
        />
      );
    } else {
      return this.getReferralSource();
    }
  };

  public renderDetailsCell = (showNoEmailError = false): JSX.Element => (
    <Box display="flex" alignItems="center" gap="8px">
      <Box>
        <ProspectResidentAvatarTemperature
          residentId={this.resident.id}
          temperature={this.temperature}
          s3PhotoKey={this.resident.s3_photo_key}
          initials={this.initials}
          disabled
        />
      </Box>
      <Box display="column">
        <Box display="flex" alignItems="center" gap="8px">
          {this.fullName}
          {!this.resident.first_name && (
            <Tooltip
              placement="top"
              title="No name has been recorded for this prospect">
              <IconButton size="small">
                <ErrorOutlineIcon sx={{ color: '#9AAEBB' }} />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant="body2">Resident</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color={
              showNoEmailError && !this.resident.email
                ? theme.palette.error.main
                : theme.palette.primary.main
            }>
            {showNoEmailError && !this.resident.email
              ? 'No Email'
              : this.resident.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  public renderStage = (): React.ReactNode => {
    if (this.lost_deal) {
      return (
        <Stack alignItems="center" gap={0.5} direction="row">
          <WorkOff color="error" fontSize="small" />
          <Typography
            fontSize="14px"
            fontWeight={500}
            color="error"
            data-testid="archived-deal-warning">
            Archived Deal
          </Typography>
        </Stack>
      );
    }

    return (
      <Chip
        data-testid="prospect-resident-stage"
        label={this.stage === 'Move In' ? 'Resident' : this.stage}
        sx={{ width: 'max-content', background: '#E7EEF4' }}
      />
    );
  };

  public canEditLifecycleStage = (): boolean =>
    !this.lost_deal && this.lifecycle_step !== 'Move In';
}
