/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadAdmissionStatusInfo
 */
export interface LeadAdmissionStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    booked_by?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    booked_at?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    target_move_in_date?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    scheduled_move_out_date?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    move_in_date?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    move_out_date?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    bed_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    date_lost?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    info_last_modified?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    moved_out_by?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    moved_in_by?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    stay_type?: LeadAdmissionStatusInfoStayTypeEnum;
}


/**
 * @export
 */
export const LeadAdmissionStatusInfoStayTypeEnum = {
    LongTerm: 'Long-Term',
    ShortTerm: 'Short-Term'
} as const;
export type LeadAdmissionStatusInfoStayTypeEnum = typeof LeadAdmissionStatusInfoStayTypeEnum[keyof typeof LeadAdmissionStatusInfoStayTypeEnum];


/**
 * Check if a given object implements the LeadAdmissionStatusInfo interface.
 */
export function instanceOfLeadAdmissionStatusInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LeadAdmissionStatusInfoFromJSON(json: any): LeadAdmissionStatusInfo {
    return LeadAdmissionStatusInfoFromJSONTyped(json, false);
}

export function LeadAdmissionStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadAdmissionStatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'booked_by': !exists(json, 'booked_by') ? undefined : json['booked_by'],
        'booked_at': !exists(json, 'booked_at') ? undefined : json['booked_at'],
        'target_move_in_date': !exists(json, 'target_move_in_date') ? undefined : json['target_move_in_date'],
        'scheduled_move_out_date': !exists(json, 'scheduled_move_out_date') ? undefined : json['scheduled_move_out_date'],
        'move_in_date': !exists(json, 'move_in_date') ? undefined : json['move_in_date'],
        'move_out_date': !exists(json, 'move_out_date') ? undefined : json['move_out_date'],
        'bed_id': !exists(json, 'bed_id') ? undefined : json['bed_id'],
        'date_lost': !exists(json, 'date_lost') ? undefined : json['date_lost'],
        'info_last_modified': !exists(json, 'info_last_modified') ? undefined : json['info_last_modified'],
        'moved_out_by': !exists(json, 'moved_out_by') ? undefined : json['moved_out_by'],
        'moved_in_by': !exists(json, 'moved_in_by') ? undefined : json['moved_in_by'],
        'stay_type': !exists(json, 'stay_type') ? undefined : json['stay_type'],
    };
}

export function LeadAdmissionStatusInfoToJSON(value?: LeadAdmissionStatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'comment': value.comment,
        'booked_by': value.booked_by,
        'booked_at': value.booked_at,
        'target_move_in_date': value.target_move_in_date,
        'scheduled_move_out_date': value.scheduled_move_out_date,
        'move_in_date': value.move_in_date,
        'move_out_date': value.move_out_date,
        'bed_id': value.bed_id,
        'date_lost': value.date_lost,
        'info_last_modified': value.info_last_modified,
        'moved_out_by': value.moved_out_by,
        'moved_in_by': value.moved_in_by,
        'stay_type': value.stay_type,
    };
}

